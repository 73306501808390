import React from "react";
import { Paper, Switch } from "@material-ui/core";
import { Modal, Button } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { InputGroup, FormControl } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import axios from "axios";
import dayjs from "dayjs";
import '../style.css'
import IconDropdown from '../../../images/icons8-down-96.png'
import DatePicker from "react-datepicker";

const CreateModal = ({
  state,
  handleCloseCreate,
  handleSaveCreateTenantUtilityPayment,
  formikTenantUtilityPaymentCreate,
  loading,
  validationPaymentCreate
}) => {
  const [date, setDate] = React.useState('2021-06')
  const [datePaymentDeadline, setDatePaymentDeadline] = React.useState('2021-06')
  
  const handleDateTime = (date) => {
    formikTenantUtilityPaymentCreate.setFieldValue("periode", date.target.value)
    setDate(date.target.value)
  }

  const handlePaymentDeadline = (date) => {
    formikTenantUtilityPaymentCreate.setFieldValue("payment_due_date", date.target.value)
    setDatePaymentDeadline(date.target.value)
  }

  return (
    <Modal
      show={state}
      onHide={() => {
        handleCloseCreate();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Create Revenue Sharing: </Modal.Title>
      </Modal.Header>
      <div className="p-4 text-danger">{alert}</div>
      <div className="p-4">
        <Row>
          <Col>
            <Form.Group style={{ width: "100%" }}>
              <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                Electricity Cost:
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Electricity Cost . . ."
                className={validationPaymentCreate("electricity_cost")}
                {...formikTenantUtilityPaymentCreate.getFieldProps("electricity_cost")}
              />
              {formikTenantUtilityPaymentCreate.touched.electricity_cost &&
              formikTenantUtilityPaymentCreate.errors.electricity_cost ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formikTenantUtilityPaymentCreate.errors.electricity_cost}
                  </div>
                </div>
              ) : null}
            </Form.Group>
            <Form.Group style={{ width: "100%" }}>
              <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                Water Cost:
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Water Cost . . ."
                className={validationPaymentCreate("water_cost")}
                {...formikTenantUtilityPaymentCreate.getFieldProps("water_cost")}
              />
              {formikTenantUtilityPaymentCreate.touched.water_cost &&
              formikTenantUtilityPaymentCreate.errors.water_cost ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formikTenantUtilityPaymentCreate.errors.water_cost}
                  </div>
                </div>
              ) : null}
            </Form.Group>
            <Form.Group style={{ width: "100%" }}>
              <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                Gas Cost:
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Gas Cost . . ."
                className={validationPaymentCreate("gas_cost")}
                {...formikTenantUtilityPaymentCreate.getFieldProps("gas_cost")}
              />
              {formikTenantUtilityPaymentCreate.touched.gas_cost &&
              formikTenantUtilityPaymentCreate.errors.gas_cost ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formikTenantUtilityPaymentCreate.errors.gas_cost}
                  </div>
                </div>
              ) : null}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group style={{ width: "100%" }}>
              <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
              Electricity Subscription Cost:
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Electricity Subscription Cost . . ."
                className={validationPaymentCreate("electricity_subscription_cost")}
                {...formikTenantUtilityPaymentCreate.getFieldProps("electricity_subscription_cost")}
              />
              {formikTenantUtilityPaymentCreate.touched.electricity_subscription_cost &&
              formikTenantUtilityPaymentCreate.errors.electricity_subscription_cost ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formikTenantUtilityPaymentCreate.errors.electricity_subscription_cost}
                  </div>
                </div>
              ) : null}
            </Form.Group>
            <Form.Group style={{ width: "100%" }}>
              <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
              Periode:
              </Form.Label>
              <Form.Control
                onChange={(e) => handleDateTime(e)}
                type="month" 
                min="2021-06" 
                value={date}
              />
            </Form.Group>
            <Form.Group style={{ width: "100%" }}>
              <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
              Payment Due Date:
              </Form.Label>
              <Form.Control
                onChange={(e) => handlePaymentDeadline(e)}
                type="date" 
                min="2021-06" 
                value={datePaymentDeadline}
              />
            </Form.Group>
          </Col>
        </Row>
        <div style={{ width: "fit-content", right: 0, marginLeft: "auto" }}>
          <Button
            variant="outline-secondary"
            onClick={() => {
              handleCloseCreate();
            }}
            style={{ width: 100 }}
            className="ml-3 mr-3"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            style={{ width: 100 }}
            onClick={handleSaveCreateTenantUtilityPayment}
            className="ml-3 mr-3"
            disabled={loading}
          >
            <span>Save</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateModal;
