import React from "react";
import { Paper } from "@material-ui/core";
import { Row, Col, Form, ListGroup, InputGroup } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Search } from "@material-ui/icons";
import axios from "axios";
import dayjs from "dayjs";
import useDebounce from "../../../hooks/useDebounce";
import ExportExcel from "react-html-table-to-excel";
import { Dropdown } from "react-bootstrap";
import { MoreHoriz } from "@material-ui/icons";
import { useFormik } from "formik";
import * as Yup from "yup";

const TenantList = () => {

  const columns = [
    {
      name: "Name",
      selector: "name",
      sortable: true
    },
    {
      name: "Start Date",
      selector: "start_date",
      sortable: true
    },
    {
      name: "End Date",
      selector: "end_date",
      sortable: true
    },
    {
      name: "Payment",
      selector: "payment",
      sortable: true
    },
    {
      name: "Type",
      selector: "type",
      sortable: true
    },
    {
      name: "Action",
      selector: "business_id",
      key: "business_id",
      cell: (val) => {
        return (
          <Dropdown>
            <Dropdown.Toggle variant="light">
              <MoreHoriz />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                as="button"
              >
                Update Subscription Details
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      }
    }
  ];

  const ExpandableComponent = ({ data }) => {
    let keys = [
      {
        id: 1,
        value: {
          paymentName: "Rent",
          startDate: "01/01/2021",
          endDate: "01/01/2023",
          dueDate: "01/12/2023",
          price: "50.000.000",
          status: "active"
        }
      },
      {
        id: 2,
        value: {
          paymentName: "Water",
          startDate: "01/01/2021",
          endDate: "01/01/2022",
          dueDate: "01/12/2022",
          price: "1.000.000",
          status: "active"
        }
      }
    ]

    return (
      <>
        <ListGroup style={{ padding: "1rem", marginLeft: "1rem" }}>
          <ListGroup.Item key="head">
            <Row className="lineBottom">
              <Col md={2} style={{ fontWeight: "700" }}>
                Payment Name
              </Col>
              <Col md={2} style={{ fontWeight: "700" }}>
                Start Date
              </Col>
              <Col md={2} style={{ fontWeight: "700" }}>
                End Date
              </Col>
              <Col md={2} style={{ fontWeight: "700" }}>
                Due Date
              </Col>
              <Col md={2} style={{ fontWeight: "700" }}>
                Price
              </Col>
              <Col md={2} style={{ fontWeight: "700" }}>
                Status
              </Col>
            </Row>
          </ListGroup.Item>
          {keys.map((val, index) => {
            return (
              <ListGroup.Item key={index}>
                <Row>
                  <Col md={2}>{val.value.paymentName}</Col>
                  <Col md={2}>{val.value.startDate}</Col>
                  <Col md={2}>{val.value.endDate}</Col>
                  <Col md={2}>{val.value.dueDate}</Col>
                  <Col md={2}>{val.value.price}</Col>
                  <Col md={2}>{val.value.status}</Col>
                </Row>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </>
    );
  };

  const dataUser = [
    {
      name: "BeetPOS",
      start_date: "01/01/2021",
      end_date: "01/01/2024",
      payment: "Rent, Water",
      type: "Complete"
    },
    {
      name: "BeetPOS",
      start_date: "01/01/2021",
      end_date: "01/01/2024",
      payment: "Rent, Water",
      type: "Standard"
    },
    {
      name: "BeetPOS",
      start_date: "01/01/2021",
      end_date: "01/01/2024",
      payment: "Rent, Water",
      type: "Basic"
    }
  ]
  return (
    <div>
      <Row>
        <Col md={12}>
          <Paper elevation={2} style={{ padding: "1rem", height: "100%" }}>
            <div
              className="headerPage lineBottom d-flex"
              style={{ marginBottom: "1rem" }}
            >
              <div className="headerStart ">
                <h3 className="p-3" style={{ width: "fit-content" }}>
                  Payment
                </h3>
              </div>
              <div
                className="headerEnd"
                style={{ right: 0, marginLeft: "auto" }}
              >
                <ExportExcel
                  className="btn btn-primary font-weight-bolder px-5"
                  table="table-business"
                  sheet="Business-List"
                  buttonText="Export"
                />
                <div className="btn btn-outline-primary ml-4">Add Tenant</div>
              </div>
            </div>

            <div className="filterSection lineBottom mt-3">
              <Row>
                <Col>
                  <InputGroup className="pb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text style={{ background: "transparent" }}>
                        <Search />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      placeholder="Search..."
                    />
                  </InputGroup>
                </Col>

                <Col>
                  <Form.Group as={Row}>
                    <Form.Label
                      style={{ alignSelf: "center", marginBottom: "0" }}
                    >
                      Status:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        as="select"
                        name="status"
                      >
                        <option>Active</option>
                        <option>Inactive</option>
                        <option>None</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label
                      style={{ alignSelf: "center", marginBottom: "0" }}
                    >
                      Type:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        as="select"
                        name="type"
                      >
                        <option>Basic</option>
                        <option>Standard</option>
                        <option>Complete</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <DataTable
              noHeader
              pagination
              columns={columns}
              data={dataUser}
              expandableRows
              expandableRowsComponent={<ExpandableComponent />}
              style={{ minHeight: "fit-content" }}
            />
          </Paper>
        </Col>
      </Row>
    </div>
  );
}

export default TenantList;
