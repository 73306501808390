import React from "react";

import {
  Button, 
  Modal, 
  Form, 
  Alert, 
  Row,
  Col,
  Dropdown,
  ListGroup } from "react-bootstrap";
import Select from "react-select";
import {
} from "react-bootstrap";

const AddPayment = ({
  stateModal,
  cancelModal,
  title,
  formik
}) => {
  const optionPeriod = [
    {
      value: 1,
      label: "Weekly"
    },
    {
      value: 2,
      label: "Monthly"
    },
    {
      value: 3,
      label: "Annualy"
    }
  ]
  return (
    <Modal show={stateModal} onHide={cancelModal} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Form>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Payment Name</Form.Label>
                <Form.Control
                  type="text"
                  name="payment_name"
                  {...formik.getFieldProps("payment_name")}
                  required
                />
                {formik.touched.payment_name && formik.errors.payment_name ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.payment_name}
                    </div>
                  </div>
                ) : null}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Payment Period</Form.Label>
                <Select
                  options={optionPeriod}
                  name="payment_period"
                  className="basic-single"
                  classNamePrefix="select"
                  onChange={(value) =>
                    formik.setFieldValue(
                      "payment_period",
                      value.value
                    )
                  }
                />
                {formik.touched.payment_period &&
                formik.errors.payment_period ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.payment_period}
                    </div>
                  </div>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Payment Amount</Form.Label>
                <Form.Control
                  type="text"
                  name="payment_amount"
                  {...formik.getFieldProps("payment_amount")}
                  required
                />
                {formik.touched.payment_amount && formik.errors.payment_amount ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.payment_amount}
                    </div>
                  </div>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelModal}>
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            Save Changes
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddPayment;
