/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { connect } from "react-redux";
import {
  DashboardOutlined,
  WorkOutlined,
  PeopleOutlined,
  ReceiptOutlined,
  SpeedOutlined,
  ListOutlined,
  LocalOfferOutlined,
} from "@material-ui/icons";
import PaymentIcon from '@material-ui/icons/Payment';
import ViewListOutlinedIcon from "@material-ui/icons/ViewListOutlined";
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import AssessmentIcon from "@material-ui/icons/Assessment";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import SettingsIcon from '@material-ui/icons/Settings';

// TODO: ambil privileges dari store
const select = (appState) => {
  return {
    results: appState.results,
    query: appState.query
  };
};

function AsideMenuList(props) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <>
      <ul
        className={`menu-nav ${props.layoutProps.ulClasses}`}
        style={{ padding: 0 }}
      >
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <DashboardOutlined />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        <li className="menu-section" style={{ margin: "0" }}>
          <h4 className="menu-text">Product</h4>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/user", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/user">
            <span className="svg-icon menu-icon">
              <WorkOutlined />
            </span>
            <span className="menu-text">PPOB</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/TopUpPPOB", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/TopUpPPOB">
            <span className="svg-icon menu-icon">
              <WorkOutlined />
            </span>
            <span className="menu-text">Top Up</span>
          </NavLink>
        </li>

        <li className="menu-section" style={{ margin: "0" }}>
          <h4 className="menu-text">Customer</h4>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/user", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/user">
            <span className="svg-icon menu-icon">
              <WorkOutlined />
            </span>
            <span className="menu-text">Users</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive(
            "/revenue-sharing",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/revenue-sharing">
            <span className="svg-icon menu-icon">
              <WorkOutlined />
            </span>
            <span className="menu-text">Revenue Sharing</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive(
            "/integrate-online-shop",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/integrate-online-shop">
            <span className="svg-icon menu-icon">
              <SettingsIcon />
            </span>
            <span className="menu-text">Integration Settings</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/transaction", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/transaction">
            <span className="svg-icon menu-icon">
              <ReceiptOutlined />
            </span>
            <span className="menu-text">Transaction</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/limit-setting", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/limit-setting">
            <span className="svg-icon menu-icon">
              <SpeedOutlined />
            </span>
            <span className="menu-text">Limit Setting</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/payment", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/payment">
            <span className="svg-icon menu-icon">
              <PaymentIcon />
            </span>
            <span className="menu-text">Payment</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/gateway", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/gateway">
            <span className="svg-icon menu-icon">
              <FormatListBulletedIcon />
            </span>
            <span className="menu-text">Payment Gateway</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/currency", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/currency">
            <span className="svg-icon menu-icon">
              <AttachMoneyIcon />
            </span>
            <span className="menu-text">Currency</span>
          </NavLink>
        </li>
        {/*begin::Menu item*/}
        <li className={`menu-item ${getMenuItemActive("/cashlezTransaction")}`}>
          <NavLink className="menu-link" to="/cashlezTransaction">
            <span className="svg-icon menu-icon">
              <ViewListOutlinedIcon />
            </span>
            <span className="menu-text">Cashlez Transactions</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/cashlezTransactionReport"
          )}`}
        >
          <NavLink className="menu-link" to="/cashlezTransactionReport">
            <span className="svg-icon menu-icon">
              <AssessmentIcon />
            </span>
            <span className="menu-text">Cashlez Transactions Report</span>
          </NavLink>
        </li>
         <li
          className={`menu-item ${getMenuItemActive(
            "/PPOBTransactionsReport"
          )}`}
        >
          <NavLink className="menu-link" to="/PPOBTransactionsReport">
            <span className="svg-icon menu-icon">
              <AssessmentIcon />
            </span>
            <span className="menu-text">PPOB Transactions Report</span>
          </NavLink>
        </li>
        {/*end::Menu item*/}
      </ul>
    </>
  );
}

const ourWrapperFunction = connect(select);

export default ourWrapperFunction(AsideMenuList);
