import React from 'react'
import {
  Row,
  Col,
  Modal,
  Form,
  Button
} from 'react-bootstrap'

export default function GofoodModal({
  state,
  alert,
  close,
  formikGofood,
  setAlert,
  handleSaveGofood
}) {
  return (
    <div>
      <Modal
        show={state}
        onHide={() => {
          close();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Gofood Modal: </Modal.Title>
        </Modal.Header>
        <div className="p-4 text-danger">{alert}</div>
        <div className="p-4">
          <Row>
            <Col>
              <Form.Group style={{ width: "100%" }}>
                <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                  Store Name GoFood 
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Store Name GoFood "
                  {...formikGofood.getFieldProps("store_name_gofood")}
                />
              </Form.Group>
              <Form.Group style={{ width: "100%" }}>
                <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                  GoFood Id
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="GoFood Id"
                  {...formikGofood.getFieldProps("gofood_id")}
                />
              </Form.Group>
              {/* <Form.Group style={{ width: "100%" }}>
                <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                  Activation Date Blibli
                </Form.Label>
                <Form.Control
                  type="date"
                  placeholder="DOKU Client Secret"
                  {...formikGofood.getFieldProps("activation_date_blibli")}
                />
              </Form.Group>
              <Form.Group style={{ width: "100%" }}>
                <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                  Update Date Blibli
                </Form.Label>
                <Form.Control
                  type="date"
                  placeholder="DOKU Client Secret"
                  {...formikGofood.getFieldProps("update_date_blibli")}
                />
              </Form.Group> */}
              <Form.Group style={{ width: "35%" }}>
                <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                Status Integrate GoFood
                </Form.Label>
                <Form.Check
                  type="checkbox"
                  label="Active"
                  checked={formikGofood.getFieldProps("status_integrate_gofood").value}
                  {...formikGofood.getFieldProps("status_integrate_gofood")}
                />
              </Form.Group>
              <div style={{ width: "fit-content", right: 0, marginLeft: "auto" }}>
                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    close();
                    setAlert(null);
                  }}
                  style={{ width: 100 }}
                  className="ml-3 mr-3"
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  style={{ width: 100 }}
                  onClick={handleSaveGofood}
                  className="ml-3 mr-3"
                >
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  )
}
