import React from "react";
import { Paper } from "@material-ui/core";
import { Row, Col, Form, ListGroup, InputGroup, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Search } from "@material-ui/icons";
import axios from "axios";
import dayjs from "dayjs";
import useDebounce from "../../hooks/useDebounce";
import ExportExcel from "react-html-table-to-excel";
import { Dropdown } from "react-bootstrap";
import { MoreHoriz } from "@material-ui/icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import CreateSubscriptionModal from "./CreateSubscriptionModal";
import UpdateSubscriptionModal from "./UpdateSubscriptionModal";
import RevenueSharing from './RevenueSharingModal'
import ConfirmModal from '../../components/ConfirmModal'
import '../style.css'
import { Link } from "react-router-dom";

import BlibliModal from "./AllEcommerce/BlibliModal"
import ShopeeModal from "./AllEcommerce/ShopeeModal"
import BeetstoreModal from "./AllEcommerce/BeetstoreModal"
import ModalAllRequest from './ModalAllRequest'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure()
export function RequestIntegration() {
  const [search, setSearch] = React.useState("");
  const debouncedSearch = useDebounce(search, 1000);
  const [filter, setFilter] = React.useState({
    status: "all",
    type: "all"
  });
  const [request, setRequest] = React.useState([]);
  const [availableFilter, setAvailableFilter] = React.useState({
    status: ["All"],
    type: ["All"]
  });

  const [selectedOnlineShopName, setSelectedOnlineShopName] = React.useState("all")

  const onlineShopName = [
    {
      key: 'all',
      label: "All"
    },
    {
      key: 'beetstore',
      label: "Beetstore"
    },
    {
      key: 'shopee',
      label: "Shopee"
    },
    {
      key: 'blibli',
      label: "Blibli"
    }
  ]

  const [refresh, setRefresh] = React.useState(0);
  const [loadData, setLoadData] = React.useState(0)

  const [loading, setLoading] = React.useState(false);
  const [alert, setAlert] = React.useState(null);

  const [stateDeleteModal, setStateDeleteModal] = React.useState(false)

  const [showBlibliModal, setShowBlibliModal] = React.useState(false)
  const [showBeetstoreModal, setShowBeetstoreModal] = React.useState(false)
  const [showShopeeModal, setShowShopeeModal] = React.useState(false)

  const handleOpenBlibli = () => setShowBlibliModal(true)
  const handleCloseBlibli = () => setShowBlibliModal(false)

  const handleOpenBeetstore = () => setShowBeetstoreModal(true)
  const handleCloseBeetstore = () => setShowBeetstoreModal(false)

  const handleOpenShopee = () => setShowShopeeModal(true)
  const handleCloseShopee = () => setShowShopeeModal(false)
  const enableLoading = () => setLoading(true);
  const disableLoading = () => setLoading(false);

  const handleRefresh = () => setRefresh(refresh + 1);
  const handleLoadData = () => setLoadData(loadData + 1);
  const [selectedBusinessId, setSelectedBusinessId] = React.useState(null);

  const handleSearch = (e) => setSearch(e.target.value);

  // const handleFilter = (e) => {
  //   const { name, value } = e.target;
  //   const filterData = { ...filter };
  //   filterData[name] = value;
  //   setFilter(filterData);
  // };

  const handleFilter = (e) => {
    setSelectedOnlineShopName(e.target.value)
  }

  const closeDeleteModal = () => setStateDeleteModal(false);
  const showDeleteModal = () => setStateDeleteModal(true)

  const getRequest = async (search, filter) => {
    const API_URL = process.env.REACT_APP_API_URL;
    let filterData = "?"
    if(filter !== 'all'){
      filterData += `online_shop_name=${filter}`
    }
    console.log("filterData", filterData)

    try {
      const { data } = await axios.get(
        `${API_URL}/api/v1/request-integration-online-shop${filterData}`
      );
      console.log("semua request integration ", data.data)

      setRequest(data.data);
    } catch (err) {
      setRequest([]);
    }
  };
  React.useEffect(() => {
    getRequest(debouncedSearch, selectedOnlineShopName);
  }, [selectedOnlineShopName, debouncedSearch, refresh]);

  const updateRequest = (data) => {
    console.log("updateRequest", data)
    if(data.online_shop_name === 'beetstore') {
      handleOpenBeetstore()
      setDataBeetstore(data)
    }
    if(data.online_shop_name === 'shopee') {
      handleOpenShopee()
      setDataShopee(data)
    }
    if(data.online_shop_name === 'blibli') {
      handleOpenBlibli()
      setDataBlibli(data)
    }
  }

  const columns = [
    {
      name: "No.",
      selector: "no",
      sortable: true,
      width: "50px"
    },
    {
      name: "Business Name",
      selector: "business_name",
      sortable: true
    },
    {
      name: "Outlet Name",
      selector: "outlet_name",
      sortable: true
    },
    {
      name: `Status`,
      sortable: true,
      cell: (rows) => {
        return (
          <div className={rows.status === 'done' && rows.status_integration_outlet ? 'integratedOnlineShop' : 'pendingIntegrationOnlineShop'}>{rows.status === 'done' && rows.status_integration_outlet ? "Done" : "Pending"}</div>
        );
      }
    },
    {
      name: "Name Online Shop",
      selector: "online_shop_name",
      sortable: true
    },
    {
      name: "Actions",
      cell: (rows) => {
        return (
          <button onClick={() => updateRequest(rows)} style={{padding:"5px 7px"}} className='btn btn-primary'>Update</button>
        );
      }
    }
  ];

  const dataRequestIntegration = request.map((item, index) => {
    return {
      no: index + 1,
      business_id: item.business_id,
      outlet_id: item.outlet_id,
      business_name: item.Business.name,
      outlet_name: item.Outlet.name,
      status: item.status,
      online_shop_name: item.online_shop_name,
      status_integration_outlet: item.online_shop_name === 'beetstore' ? item.Outlet.status_integrate_beetstore : item.online_shop_name === 'shopee' ? item.Outlet.status_integrate_shopee : item.online_shop_name === 'blibli' ? item.Outlet.status_integrate_blibli : null,
    };
  });

  const initialBlibli = {
    business_id: "",
    outlet_id: "",
    blibli_store_id: "",
    blibli_auth: "",
    activation_date_blibli: "",
    update_date_blibli: "",
    status_integrate_blibli: ""
  }

  const formikBlibli = useFormik({
    enableReinitialize: true,
    initialValues: initialBlibli,
    // validationSchema: CreateSubscriptionSchema
    onSubmit: async (values, {resetForm}) => {
      const API_URL = process.env.REACT_APP_API_URL;
      console.log("values", values)
      try {
        enableLoading();
        const {data} = await axios.patch(`${API_URL}/api/v1/outlet/patch-blibli/${values.outlet_id}`, values);
        if(data.statusCode === 200) {
          const dataSend = {
            business_id: values.business_id,
            outlet_id: values.outlet_id,
            online_shop_name: 'blibli',
            status: 'done'
          }
          await axios.post(`${API_URL}/api/v1/request-integration-online-shop`, dataSend)
        }
        toast.success('Update Success', {
          position: "top-right",
          autoClose: 2700,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        handleRefresh();
        setAlert("")
        resetForm()
        disableLoading();
        handleCloseBlibli()
      } catch (err) {
        toast.error("Something wen't wrong, please try again", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log("error formikBlibli", err)
        setAlert(err.response?.data.message || err.message);
        disableLoading();
      }
    }
  });

  const initialBeetstore = {
    business_id: "",
    outlet_id: "",
    status_integrate_blibli: ""
  }

  const formikBeetstore = useFormik({
    enableReinitialize: true,
    initialValues: initialBeetstore,
    // validationSchema: CreateSubscriptionSchema
    onSubmit: async (values, {resetForm}) => {
      const API_URL = process.env.REACT_APP_API_URL;
      console.log("values", values)
      try {
        enableLoading();
        const {data} = await axios.patch(`${API_URL}/api/v1/outlet/patch-beetstore/${values.outlet_id}`, values);
        if(data.statusCode === 200) {
          const dataSend = {
            business_id: values.business_id,
            outlet_id: values.outlet_id,
            online_shop_name: 'beetstore',
            status: 'done'
          }
          await axios.post(`${API_URL}/api/v1/request-integration-online-shop`, dataSend)
        }

        toast.success('Update Success', {
          position: "top-right",
          autoClose: 2700,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        handleRefresh();
        setAlert("")
        resetForm()
        disableLoading();
        handleCloseBeetstore()
      } catch (err) {
        console.log("error formikBeetstore", err)
        toast.error("Something wen't wrong, please try again", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setAlert(err.response?.data.message || err.message);
        disableLoading();
      }
    }
  });

  const initialShopee = {
    business_id: "",
    outlet_id: "",
    shopee_store_id: "",
    shopee_code: "",
    shopee_auth: "",
    shopee_access_token: "",
    shopee_refresh_token: "",
    shopee_expiration: "",
    shopee_user: "",
    shopee_password: "",
    shopee_url: "",
    status_integrate_shopee: ""
  }

  const formikShopee = useFormik({
    enableReinitialize: true,
    initialValues: initialShopee,
    // validationSchema: CreateSubscriptionSchema
    onSubmit: async (values, {resetForm}) => {
      const API_URL = process.env.REACT_APP_API_URL;
      console.log("values", values)
      try {
        enableLoading();
        const {data} = await axios.patch(`${API_URL}/api/v1/outlet/patch-shopee/${values.outlet_id}`, values);
        if(data.statusCode === 200) {
          const dataSend = {
            business_id: values.business_id,
            outlet_id: values.outlet_id,
            online_shop_name: 'shopee',
            status: 'done'
          }
          await axios.post(`${API_URL}/api/v1/request-integration-online-shop`, dataSend)
        }
        toast.success('Update Success', {
          position: "top-right",
          autoClose: 2700,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        handleRefresh();
        setAlert("")
        resetForm()
        disableLoading();
        handleCloseShopee()
      } catch (err) {
        console.log("error formikBeetstore", err)
        toast.error("Something wen't wrong, please try again", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setAlert(err.response?.data.message || err.message);
        disableLoading();
      }
    }
  });

  const setDataBlibli = async (value) => {
    const API_URL = process.env.REACT_APP_API_URL;
    try {
      const { data } = await axios.get(`${API_URL}/api/v1/outlet/${value.outlet_id}`)
      formikBlibli.setValues({
        business_id: value.business_id,
        outlet_id: value.outlet_id,
        blibli_store_id: data.data.blibli_store_id,
        blibli_auth: data.data.blibli_auth,
        activation_date_blibli: dayjs(data.data.activation_date_blibli).format("YYYY-MM-DD"),
        update_date_blibli: dayjs(data.data.update_date_blibli).format("YYYY-MM-DD"),
        status_integrate_blibli: data.data.status_integrate_blibli
      })
    } catch (error) {
      console.log("error setDataBlibli", error)
    }
  }

  const setDataBeetstore = async (value) => {
    const API_URL = process.env.REACT_APP_API_URL;
    try {
      const { data } = await axios.get(`${API_URL}/api/v1/outlet/${value.outlet_id}`)
      formikBeetstore.setValues({
        business_id: value.business_id,
        outlet_id: value.outlet_id,
        status_integrate_beetstore: data.data.status_integrate_beetstore
      })
    } catch (error) {
      console.log("error setDataBeetstore", error)
    }
  }

  const setDataShopee = async (value) => {
    const API_URL = process.env.REACT_APP_API_URL;
    try {
      const { data } = await axios.get(`${API_URL}/api/v1/outlet/${value.outlet_id}`)
      formikShopee.setValues({
        business_id: value.business_id,
        outlet_id: value.outlet_id,
        shopee_store_id: data.data.shopee_store_id,
        shopee_code: data.data.shopee_code,
        shopee_auth: data.data.shopee_auth,
        shopee_access_token: data.data.shopee_access_token,
        shopee_refresh_token: data.data.shopee_refresh_token,
        shopee_expiration: data.data.shopee_expiration ? dayjs(data.data.shopee_expiration).format("YYYY-MM-DD") : "",
        shopee_user: data.data.shopee_user,
        shopee_password: data.data.shopee_password,
        shopee_url: data.data.shopee_url,
        status_integrate_shopee: data.data.status_integrate_shopee
      })
    } catch (error) {
      console.log("error setDataBeetstore", error)
    }
  }

  const ExpandableComponent = ({ data }) => {
    let keys = [];
    data.outlets.forEach((val, idx) => {
      console.log("ExpandableComponent", data)
      // Check yang sudah terintegrasi dengan toko online
      let already_integrated = []
      if(val.status_integrate_blibli) already_integrated.push("Blibli")
      if(val.status_integrate_shopee) already_integrated.push("Shopee")
      if(val.status_integrate_beetstore) already_integrated.push("Beet Store")

      keys.push({
        key: idx,
        value: {
          business_id: data.business_id,
          outlet_id: val.id,
          name: val.name,
          status: val.status,
          already_integrated: already_integrated.join(", ") || "-",
          address: val.address ? val.address : "-",
          location: val.Location ? val.Location.name : "-",
          city: val.Location ? val.Location.City.name : "-",
          province: val.Location ? val.Location.City.Province.name : "-"
        }
      })
    })

    return (
      <>
        <ListGroup style={{ padding: "1rem", marginLeft: "1rem" }}>
          <ListGroup.Item key="head">
            <Row className="lineBottom">
              <Col md={2} style={{ fontWeight: "700" }}>
                Outlet Name
              </Col>
              <Col md={8} style={{ fontWeight: "700" }}>
                Already Integrated
              </Col>
              <Col md={2} style={{ fontWeight: "700" }}>
                Actions
              </Col>
            </Row>
          </ListGroup.Item>
          {keys.map((val, index) => {
            return (
              <ListGroup.Item key={index}>
                <Row>
                  <Col md={2}>{val.value.name}</Col>
                  <Col md={8}>{val.value.already_integrated}</Col>
                  <Col md={2}>
                    <Dropdown>
                      <Dropdown.Toggle variant="light">
                        <MoreHoriz />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          as="button"
                          onClick={() => {
                            handleOpenBlibli()
                            setDataBlibli(val.value)
                          }}
                        >
                          Blibli
                        </Dropdown.Item>
                        <Dropdown.Item
                          as="button"
                          onClick={() => {
                            handleOpenShopee()
                            setDataShopee(val.value)
                          }}
                        >
                          Shopee
                        </Dropdown.Item>
                        <Dropdown.Item
                          as="button"
                          onClick={() => {
                            handleOpenBeetstore()
                            setDataBeetstore(val.value)
                          }}
                        >
                          Beet Store
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </>
    );
  };

  const handleSaveBlibli = () => {
    formikBlibli.submitForm()
  }

  const handleSaveBeetstore = () => {
    formikBeetstore.submitForm()
  }

  const handleSaveShopee = () => {
    formikShopee.submitForm()
  }

  const getDataRequestIntegration = async () => {
    const API_URL = process.env.REACT_APP_API_URL;
    try {
      const { data } = await axios.get(`${API_URL}/api/v1/request-integration-online-shop`)
      console.log("get data request integration", data.data)
    } catch (error) {
      console.log("error getDataRequestIntegration", error)
    }
  }

  React.useEffect(() => {
    getDataRequestIntegration()
  }, [loadData])

  return (
    <>
      <BlibliModal
        state={showBlibliModal}
        alert={alert}
        close={handleCloseBlibli}
        formikBlibli={formikBlibli}
        setAlert={setAlert}
        handleSaveBlibli={handleSaveBlibli}
      />

      <ShopeeModal
        state={showShopeeModal}
        alert={alert}
        close={handleCloseShopee}
        formikShopee={formikShopee}
        setAlert={setAlert}
        handleSaveShopee={handleSaveShopee}
      />

      <BeetstoreModal
        state={showBeetstoreModal}
        alert={alert}
        close={handleCloseBeetstore}
        formikBeetstore={formikBeetstore}
        setAlert={setAlert}
        handleSaveBeetstore={handleSaveBeetstore}
      />
      
      <Row>
        <Col md={12}>
          <Paper elevation={2} style={{ padding: "1rem", height: "100%" }}>
            <div
              className="headerPage lineBottom d-flex"
              style={{ marginBottom: "1rem" }}
            >
              <div className="headerStart ">
                <h3 className="p-3" style={{ width: "fit-content" }}>
                  User Data
                  <small className="pl-3">All Request Integration</small>
                </h3>
              </div>
              <div className="headerEnd">
                <Link
                  to={{
                    pathname: "/integrate-online-shop"
                  }}
                >
                  <Button variant="outline-secondary">Back</Button>
                </Link>
              </div>
            </div>

            <div className="filterSection lineBottom mt-3">
              <Row>
                <Col>
                  <InputGroup className="pb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text style={{ background: "transparent" }}>
                        <Search />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      placeholder="Search..."
                      value={search}
                      onChange={handleSearch}
                    />
                  </InputGroup>
                </Col>

                <Col>
                  <Form.Group as={Row}>
                    <Form.Label
                      style={{ alignSelf: "center", marginBottom: "0" }}
                    >
                      Online Shop Name:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        as="select"
                        name="type"
                        value={selectedOnlineShopName}
                        onChange={handleFilter}
                      >
                        {onlineShopName.map((value, idx) => {
                          if (value)
                            return (
                              <option value={value.key} key={value.key}>
                                {value.label}
                              </option>
                            );
                        })}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <DataTable
              noHeader
              pagination
              columns={columns}
              data={dataRequestIntegration}
              // expandableRows
              // expandableRowsComponent={<ExpandableComponent />}
              style={{ minHeight: "fit-content" }}
            />
          </Paper>
        </Col>
      </Row>
    </>
  );
}
