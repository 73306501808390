import React from "react";
import { Paper, Switch } from "@material-ui/core";
import { Modal, Button } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { InputGroup, FormControl } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import axios from "axios";
import dayjs from "dayjs";
import '../style.css'
import IconDropdown from '../../../images/icons8-down-96.png'
// handleSaveRevenueSharing
const UpdateModal = ({
  state,
  handleCloseUpdate,
  handleSaveUpdateTenantUtilityPayment,
  formikTenantUtilityPaymentUpdate,
  validationPaymentUpdate
}) => {
  
  const handleDateTime = (date) => {
    // formikTenantUtilityPaymentCreate.setFieldValue("periode", date.target.value)
    // setDate(date.target.value)
  }

  const handlePaymentDeadline = (date) => {

  }

  return (
    <Modal
      show={state}
      onHide={() => {
        handleCloseUpdate();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Revenue Sharing: </Modal.Title>
      </Modal.Header>
      <div className="p-4 text-danger">{alert}</div>
      <div className="p-4">
        <Row>
          <Col>
            <Form.Group style={{ width: "100%" }}>
              <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                Electricity Cost:
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Electricity Cost . . ."
                className={validationPaymentUpdate("electricity_cost")}
                {...formikTenantUtilityPaymentUpdate.getFieldProps("electricity_cost")}

              />
            </Form.Group>
            <Form.Group style={{ width: "100%" }}>
              <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                Water Cost:
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Water Cost . . ."
                className={validationPaymentUpdate("water_cost")}
                {...formikTenantUtilityPaymentUpdate.getFieldProps("water_cost")}
              />
            </Form.Group>
            <Form.Group style={{ width: "100%" }}>
              <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                Gas Cost:
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Gas Cost . . ."
                className={validationPaymentUpdate("gas_cost")}
                {...formikTenantUtilityPaymentUpdate.getFieldProps("gas_cost")}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group style={{ width: "100%" }}>
              <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                Electricity Subscription Cost:
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Electricity Subscription Cost . . ."
                className={validationPaymentUpdate("electricity_subscription_cost")}
                {...formikTenantUtilityPaymentUpdate.getFieldProps("electricity_subscription_cost")}
              />
            </Form.Group>
            <Form.Group style={{ width: "100%" }}>
              <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
              Periode:
              </Form.Label>
              <Form.Control
                onChange={(e) => handleDateTime(e)}
                type="month" 
                min="2021-06" 
                {...formikTenantUtilityPaymentUpdate.getFieldProps("periode")}
              />
            </Form.Group>
            <Form.Group style={{ width: "100%" }}>
              <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
              Payment Due Date:
              </Form.Label>
              <Form.Control
                onChange={(e) => handlePaymentDeadline(e)}
                type="date" 
                min="2021-06" 
                {...formikTenantUtilityPaymentUpdate.getFieldProps("payment_due_date")}
              />
            </Form.Group>
          </Col>
        </Row>
        <div style={{ width: "fit-content", right: 0, marginLeft: "auto" }}>
          <Button
            variant="outline-secondary"
            onClick={() => {
              handleCloseUpdate();
            }}
            style={{ width: 100 }}
            className="ml-3 mr-3"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            style={{ width: 100 }}
            onClick={handleSaveUpdateTenantUtilityPayment}
            className="ml-3 mr-3"
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateModal;
