import React, {useEffect, useState} from "react";
import { Paper } from "@material-ui/core";
import { Row, Col, Form, ListGroup, InputGroup } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Search } from "@material-ui/icons";
import axios from "axios";
import dayjs from "dayjs";
import useDebounce from "../../../hooks/useDebounce";
import ExportExcel from "react-html-table-to-excel";
import { Dropdown } from "react-bootstrap";
import { MoreHoriz } from "@material-ui/icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import ModalPaymentGateway from "./ModalPaymentGateway";


const PaymentGateway = () => {
  const [stateAddModal, setStateAddModal] = useState(false);
  const [businessFormData, setBusinessFormData] = useState([]) 
  const [openModalPaymentGateway, setOpenModalPaymentGateway] = useState(false)
  const [dataDetail, setDataDetail] = useState({})
  const [refresh, setRefresh] = useState(0)

  const API_URL = process.env.REACT_APP_API_URL;

  const showModalPaymentGateway = () => setOpenModalPaymentGateway(true)
  const closeModalPaymentGateway = () => setOpenModalPaymentGateway(false)

  const handleRefresh = () => {
    console.log("handleRefresh")
    setRefresh((state) => state + 1);
  }

  const getBusinessFormData = async () => {
    try {
      const {data} = await axios.get(`${API_URL}/api/v1/business-form-data`)
      data.data.map((value) => {
        if(!value.Business){
          console.log("value yang tidak ada bisnisnya", value)
        }
        if(value.date_tracking_1) {
          console.log("value.date_tracking_1", value.date_tracking_1)
          value.date_tracking_1 = dayjs(value.date_tracking_1).format('ddd, MMM DD')
          value.time_tracking_1 = dayjs(value.time_tracking_1).format('hh:mm')
        }
        if(value.date_tracking_2) {
          console.log("value.date_tracking_2", value.date_tracking_2)
          value.date_tracking_2 = dayjs(value.date_tracking_2).format('ddd, MMM DD')
          value.time_tracking_2 = dayjs(value.time_tracking_2).format('hh:mm')
        }
        if(value.date_tracking_3) {
          console.log("value.date_tracking_3", value.date_tracking_3)
          value.date_tracking_3 = dayjs(value.date_tracking_3).format('ddd, MMM DD')
          value.time_tracking_3 = dayjs(value.time_tracking_3).format('hh:mm')
        }
        if(value.date_tracking_4) {
          console.log("value.date_tracking_4", value.date_tracking_4)
          value.date_tracking_4 = dayjs(value.date_tracking_4).format('ddd, MMM DD')
          value.time_tracking_4 = dayjs(value.time_tracking_4).format('hh:mm')
        }
        if (value.payment_gateway_name) {
          const str = value.payment_gateway_name;
          const str2 = str.charAt(0).toUpperCase() + str.slice(1);
          value.payment_gateway_name = str2
        }
        if (value.register_type_cz) {
          if(value.register_type_cz === 'pt') {
            value.register_type_cz = 'PT Registration'
          }
          if(value.register_type_cz === 'individu') {
            value.register_type_cz = 'Individual Registration'
          }
        }
      })
      console.log("getBusinessFormData", data.data)
      setBusinessFormData(data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getBusinessFormData()
  }, [refresh])

  const initialAddPayment = {
    payment_name: "",
    payment_period: "",
    payment_amount: ""
  };

  const schemaAddPayment = Yup.object().shape({
    payment_name: Yup.number().required("Please Input Payment Name"),
    payment_period: Yup.number()
      .required("Please Choose Payment Period"),
    payment_amount: Yup.string()
      .min(1, "Minimum 1 Character")
      .required("Please Input Payment Amount"),
  });

  const formikAddPayment = useFormik({
    initialValues: initialAddPayment,
    validationSchema: schemaAddPayment,
    onSubmit: async (values) => {
      const materialData = {
        payment_name: values.payment_name,
        payment_period: values.payment_period,
        payment_amount: values.payment_amount,
      };
      console.log("ini values formikAddPayment", values)
    }
  });

  const columns = [
    {
      name: "No.",
      selector: "no",
      sortable: true,
      width: "50px"
    },
    {
      name: "Name",
      selector: "name",
      sortable: true,
      wrap: true
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      wrap: true,
      grow: 3
    },
    {
      name: "Register Type",
      selector: "register_type",
      sortable: true,
      wrap: true,
      grow: 2
    },
    {
      name: "Payment Gateway Name",
      selector: "payment_gateway_name",
      sortable: true
    },
    {
      name: "Action",
      selector: "business_id",
      key: "business_id",
      cell: (val) => {
        console.log("data val", val)
        return (
          <Dropdown>
            <Dropdown.Toggle variant="light">
              <MoreHoriz />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                as="button"
                onClick={() => {
                  showModalPaymentGateway()
                  setDataDetail(val)
                }}
              >
                Detail
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      }
    }
  ];

  const closeAddModal = () => {
    setStateAddModal(false);
  };

  const listData = businessFormData.map((value, index) => {
    return {
      no: index + 1,
      id: value.id,
      name: value.merchant_name || "-",
      business_id: value.business_id,
      business_name: value.Business?.name,
      status: value.status,
      register_type: value.register_type_cz,
      tracking_process: value.tracking_process,
      payment_gateway_name: value.payment_gateway_name,
      register_type_cz: value.register_type_cz,
      date_tracking_1: value.date_tracking_1,
      date_tracking_2: value.date_tracking_2,
      date_tracking_3: value.date_tracking_3,
      date_tracking_4: value.date_tracking_4,
      path_form: value.path_form,
      ktp_picture: value.Business?.ktp_picture,
      npwp_picture: value.Business?.npwp_picture,
      product_photo: value.product_photo,
      business_location_photo: value.business_location_photo,
      npwp_pt_photo: value.npwp_pt_photo,
      siup_tdp_nib: value.siup_tdp_nib,
      business_signpost_photo: value.business_signpost_photo,
      change_data: value.change_data 
    }
  })

  console.log("listData =====>", listData)

  return (
    <div>
      <ModalPaymentGateway 
        state={openModalPaymentGateway}
        closeModal={closeModalPaymentGateway}
        title={dataDetail.name}
        dataDetail={dataDetail}
        handleRefresh={handleRefresh}
      />
      <Row>
        <Col md={12}>
          <Paper elevation={2} style={{ padding: "1rem", height: "100%" }}>
            <div
              className="headerPage lineBottom d-flex"
              style={{ marginBottom: "1rem" }}
            >
              <div className="headerStart ">
                <h3 className="p-3" style={{ width: "fit-content" }}>
                  Registration Payment Gateway
                </h3>
              </div>
              <div
                className="headerEnd"
                style={{ right: 0, marginLeft: "auto" }}
              >
                <ExportExcel
                  className="btn btn-primary font-weight-bolder px-5"
                  table="table-business"
                  sheet="Business-List"
                  buttonText="Export"
                />
                <div className="btn btn-outline-primary ml-4" onClick={() => setStateAddModal(true)}>Add Payment</div>
              </div>
            </div>

            <div className="filterSection lineBottom mt-3">
              <Row>
                <Col>
                  <InputGroup className="pb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text style={{ background: "transparent" }}>
                        <Search />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      placeholder="Search..."
                    />
                  </InputGroup>
                </Col>

                <Col>
                  <Form.Group as={Row}>
                    <Form.Label
                      style={{ alignSelf: "center", marginBottom: "0" }}
                    >
                      Status:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        as="select"
                        name="status"
                      >
                        <option>Active</option>
                        <option>Inactive</option>
                        <option>None</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label
                      style={{ alignSelf: "center", marginBottom: "0" }}
                    >
                      Type:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        as="select"
                        name="type"
                      >
                        <option>Basic</option>
                        <option>Standard</option>
                        <option>Complete</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <DataTable
              noHeader
              pagination
              columns={columns}
              data={listData}
              style={{ minHeight: "fit-content" }}
            />
          </Paper>
        </Col>
      </Row>
    </div>
  );
}

export default PaymentGateway;
