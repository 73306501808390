import React, {useState} from 'react';
import { Modal, Button } from "react-bootstrap";
import './style.css'
import axios from 'axios'
import { saveAs } from 'file-saver'
import fileDownload from 'js-file-download'
import dayjs from "dayjs";
import styles from './modalpaymentgateway.module.css'

const ModalPaymentGateway = ({
  closeModal,
  state,
  title,
  dataDetail,
  handleRefresh
}) => {
  const [handleNew, setHandleNew] = useState(true)
  const API_URL = process.env.REACT_APP_API_URL;

  console.log("first render dataDetail", dataDetail)

  const handleTrackingRegister = async (params, status) => {
    if(dataDetail.tracking_process < params ) {
      const date = new Date()
      const sendData = {
        tracking_process: params,
        status
      }
      if(params === 2) sendData.date_tracking_2 = date
      if(params === 3) sendData.date_tracking_3 = date
      if(params === 4) sendData.date_tracking_4 = date

      console.log("sendData", sendData)
      console.log("sebelum dataDetail", dataDetail)
      await axios.patch(`${API_URL}/api/v1/business-form-data/patch-tracking/${dataDetail.id}?business_id=${dataDetail.business_id}`, sendData)
      dataDetail.tracking_process = params
      handleRefresh()
      console.log("sesudah dataDetail", dataDetail)
    }
    // console.log("handleTrackingRegister", params)
  }

  const handlePreviewKtp = async () => {
    if(dataDetail.ktp_picture) {
      const {data} = await axios.get(`${API_URL}/api/v1/business-form-data/get-photo?path_photo=${dataDetail.ktp_picture}`, {
        responseType: "blob"
      })
  
      const date = new Date()
      const formatDate = dayjs(date).format('DD-MM-YYYY_HH_m_ss')
      const fileName = `Preview KTP - ${dataDetail.business_name} - ${dataDetail.payment_gateway_name} - ${dataDetail.register_type_cz} - ${formatDate}.jpg`
      const blob = new Blob([data], { type: 'application/jpg' })
      // saveAs(blob, fileName)
      fileDownload(data, fileName)
    } else {
      console.log('FOTO KTP TIDAK ADA')
    }
  }

  const handlePreviewNpwp = async () => {
    console.log("dataDetail", dataDetail)
    if(dataDetail.npwp_picture) {
      const {data} = await axios.get(`${API_URL}/api/v1/business-form-data/get-photo?path_photo=${dataDetail.npwp_picture}`, {
        responseType: "blob"
      })
  
      const date = new Date()
      const formatDate = dayjs(date).format('DD-MM-YYYY_HH_m_ss')
      const fileName = `Preview NPWP - ${dataDetail.business_name} - ${dataDetail.payment_gateway_name} - ${dataDetail.register_type_cz} - ${formatDate}.jpg`
      const blob = new Blob([data], { type: 'application/jpg' })
      // saveAs(blob, fileName)
      fileDownload(data, fileName)
    } else {
      console.log('FOTO NPWP TIDAK ADA')
    }
  }

  const handlePreviewProduct = async () => {
    if(dataDetail.product_photo) {
      const {data} = await axios.get(`${API_URL}/api/v1/business-form-data/get-photo?path_photo=${dataDetail.product_photo}`, {
        responseType: "blob"
      })
  
      const date = new Date()
      const formatDate = dayjs(date).format('DD-MM-YYYY_HH_m_ss')
      const fileName = `Preview PRODUCT - ${dataDetail.business_name} - ${dataDetail.payment_gateway_name} - ${dataDetail.register_type_cz} - ${formatDate}.jpg`
      const blob = new Blob([data], { type: 'application/jpg' })
      // saveAs(blob, fileName)
      fileDownload(data, fileName)
    } else {
      console.log('FOTO PRODUCT TIDAK ADA')
    }
  }

  const handlePreviewLocationBusiness = async () => {
    if(dataDetail.business_location_photo) {
      const {data} = await axios.get(`${API_URL}/api/v1/business-form-data/get-photo?path_photo=${dataDetail.business_location_photo}`, {
        responseType: "blob"
      })
  
      const date = new Date()
      const formatDate = dayjs(date).format('DD-MM-YYYY_HH_m_ss')
      const fileName = `Preview Business Location - ${dataDetail.business_name} - ${dataDetail.payment_gateway_name} - ${dataDetail.register_type_cz} - ${formatDate}.jpg`
      const blob = new Blob([data], { type: 'application/jpg' })
      // saveAs(blob, fileName)
      fileDownload(data, fileName)
    } else {
      console.log('FOTO BUSINESS LOCATION TIDAK ADA')
    }
  }

  const handlePreviewSignpost = async () => {
    console.log("dataDetail.business_signpost_photo", dataDetail)
    if(dataDetail.business_signpost_photo) {
      const {data} = await axios.get(`${API_URL}/api/v1/business-form-data/get-photo?path_photo=${dataDetail.business_signpost_photo}`, {
        responseType: "blob"
      })
  
      const date = new Date()
      const formatDate = dayjs(date).format('DD-MM-YYYY_HH_m_ss')
      const fileName = `Preview Business Signpost Photo - ${dataDetail.business_name} - ${dataDetail.payment_gateway_name} - ${dataDetail.register_type_cz} - ${formatDate}.jpg`
      const blob = new Blob([data], { type: 'application/jpg' })
      // saveAs(blob, fileName)
      fileDownload(data, fileName)
    } else {
      console.log('FOTO Business Signpost TIDAK ADA')
    }
  }

  const handlePreviewNpwpPt = async () => {
    if(dataDetail.npwp_pt_photo) {
      const {data} = await axios.get(`${API_URL}/api/v1/business-form-data/get-photo?path_photo=${dataDetail.npwp_pt_photo}`, {
        responseType: "blob"
      })
  
      const date = new Date()
      const formatDate = dayjs(date).format('DD-MM-YYYY_HH_m_ss')
      const fileName = `Preview NPWP PT - ${dataDetail.business_name} - ${dataDetail.payment_gateway_name} - ${dataDetail.register_type_cz} - ${formatDate}.jpg`
      const blob = new Blob([data], { type: 'application/jpg' })
      // saveAs(blob, fileName)
      fileDownload(data, fileName)
    } else {
      console.log('FOTO NPWP PT TIDAK ADA')
    }
  }

  const handlePreviewSiup = async () => {
    if(dataDetail.siup_tdp_nib) {
      const {data} = await axios.get(`${API_URL}/api/v1/business-form-data/get-photo?path_photo=${dataDetail.siup_tdp_nib}`, {
        responseType: "blob"
      })
  
      const date = new Date()
      const formatDate = dayjs(date).format('DD-MM-YYYY_HH_m_ss')
      const fileName = `Preview SIUP/TDP/NIB - ${dataDetail.business_name} - ${dataDetail.payment_gateway_name} - ${dataDetail.register_type_cz} - ${formatDate}.jpg`
      const blob = new Blob([data], { type: 'application/jpg' })
      // saveAs(blob, fileName)
      fileDownload(data, fileName)
    } else {
      console.log('FOTO SIUP/TDP/NIB TIDAK ADA')
    }
  }

  const handlePreviewPdf = async () => {
    if(dataDetail.path_form) {
      const {data} = await axios.get(`${API_URL}/api/v1/modify-pdf/${dataDetail.id}`, {
        responseType: "blob"
      })
      
      const date = new Date()
      const formatDate = dayjs(date).format('DD-MM-YYYY_HH_m_ss')
      const fileName = `FORMULIR APLIKASI MERCHANT - ${dataDetail.business_name} - ${dataDetail.payment_gateway_name} - ${dataDetail.register_type_cz} - ${formatDate}.pdf`
      const blob = new Blob([data], { type: 'application/pdf' })
      console.log("blob pdf", blob)
      // saveAs(blob, fileName)
      fileDownload(data, fileName)
    }
  }

  const updateChangeData = async () => {
    try {
      await axios.patch(`${API_URL}/api/v1/business-form-data/patch-change-data/${dataDetail.id}?change_data=0`)
      setHandleNew(false)
    } catch (error) {
      console.log(error)
    }
  }

  const handlePreviewAllFiles = async () => {
    await updateChangeData()
    await handlePreviewKtp()
    await handlePreviewNpwp()
    await handlePreviewProduct()
    await handlePreviewLocationBusiness()
    await handlePreviewSignpost()

    await handlePreviewNpwpPt()
    await handlePreviewSiup()
    
    await handlePreviewPdf()
  }

  return (
    <div>
      <Modal show={state} onHide={closeModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="container">
					  <div className="row">
              <div className="col-md-12 hh-grayBox">
                <div className={styles.wrapperPayment}>
                  <div>
                    <div className={styles.title}>
                      Tracking Process
                    </div>
                    <div className={styles.value}>
                      {dataDetail.status}
                    </div>
                  </div>
                  <div>
                    <div className={styles.title}>
                      Payment Gateway Name
                    </div>
                    <div className={styles.value}>
                      {dataDetail.payment_gateway_name}
                    </div>
                  </div>
                  <div>
                    <div className={styles.title}>
                      Register Type Cashlez
                    </div>
                    <div className={styles.value}>
                      {dataDetail.register_type_cz}
                    </div>
                  </div>
                </div>
                <div className="row justify-content-between">
								<div className={`order-tracking ${dataDetail.tracking_process > 0 && dataDetail.tracking_process < 5 ? 'completed' : ''}`}>
									<span className="is-complete" onClick={() => handleTrackingRegister(1)}></span>
									<p>Sudah diajukan di Beetpos<br /><span>{dataDetail.date_tracking_1 ? dataDetail.date_tracking_1 : ""}</span>
									<br />
									{/* <span>{dataDetail.time_tracking_1 ? dataDetail.time_tracking_1 : ""}</span> */}
									</p>
								</div>
								<div className={`order-tracking ${dataDetail.tracking_process > 1 && dataDetail.tracking_process < 5 ? 'completed' : ''}`}>
									<span className="is-complete" onClick={() => handleTrackingRegister(2, 'Sedang diproses Payment Gateway')}></span>
									<p>Sedang diproses Payment Gateway<br /><span>{dataDetail.date_tracking_2 ? dataDetail.date_tracking_2 : ""}</span>
									<br />
									{/* <span>{dataDetail.time_tracking_2 ? dataDetail.time_tracking_2 : ""}</span> */}
									</p>
								</div>
								<div className={`order-tracking ${dataDetail.tracking_process > 2 && dataDetail.tracking_process < 5 ? 'completed' : ''}`}>
									<span className="is-complete" onClick={() => handleTrackingRegister(3, 'Masa Penerbitan Cashlez')}></span>
									<p>Masa Penerbitan Cashlez<br /><span>{dataDetail.date_tracking_3 ? dataDetail.date_tracking_3 : ""}</span>
									<br />
									{/* <span>{dataDetail.time_tracking_3 ? dataDetail.time_tracking_3 : ""}</span> */}
									</p>
								</div>
								<div className={`order-tracking ${dataDetail.tracking_process > 3 && dataDetail.tracking_process < 5 ? 'completed' : ''}`}>
									<span className="is-complete" onClick={() => handleTrackingRegister(4, 'Status Selesai')}></span>
									<p>Status Selesai<br /><span>{dataDetail.date_tracking_4 ? dataDetail.date_tracking_4 : ""}</span>
									<br />
									{/* <span>{dataDetail.time_tracking_4 ? dataDetail.time_tracking_4 : ""}</span> */}
									</p>
								</div>
							</div>
						</div>
					</div>
          {/* <div className="row justify-content-center align-items-between">
            <div className="col-md-3">
              <div className="btn btn-primary" onClick={handlePreviewKtp}>
                Foto KTP
              </div>
            </div>
            <div className="col-md-3">
              <div className="btn btn-primary" onClick={handlePreviewNpwp}>
                Foto NPWP
              </div>
            </div>
            <div className="col-md-3">
              <div className="btn btn-primary" onClick={handlePreviewProduct}>
                Foto Produk
              </div>
            </div>
            <div className="col-md-3">
              <div className="btn btn-primary" onClick={handlePreviewLocationBusiness}>
                Foto Lokasi Usaha 
              </div>
            </div>
          </div> */}
          {/* <div className="row">
            <div className="col-md-3">
              <div className="btn btn-primary" onClick={handlePreviewNpwpPt}>
                Foto PT NPWP 
              </div>
            </div>
            <div className="col-md-3">
              <div className="btn btn-primary" onClick={handlePreviewSiup}>
                Foto SIUP/TDP/NIB
              </div>
            </div>
          </div> */}
          {/* <div className="row">
            <div className="btn btn-primary" onClick={handlePreviewPdf}>
              Preview PDF
            </div>
          </div> */}
          {/* <div className="row">
            <div className="btn btn-primary" onClick={handlePreviewSignpost}>
              SignPost
            </div>
          </div> */}
          
          <div className="row">
            <div className="btn btn-primary" onClick={handlePreviewAllFiles}>
              Preview All Files
            </div>
            {dataDetail.change_data && handleNew ? (
              <div className="ml-2">
                <div className="blink text-danger">New Data</div>
                <div className="text-muted">(please preview all files)</div>
              </div>
            ) : null }
          </div>
        </div>
        </Modal.Body>

        <Modal.Footer>
          <Button 
            variant="secondary" 
            onClick={closeModal}
          >Close</Button>
          <Button variant="primary">Save changes</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ModalPaymentGateway;
