import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const LOGIN_URL = `${API_URL}/api/v1/subscription-dashboard/auth/login`;
export const REQUEST_PASSWORD_URL = `${API_URL}/api/v1/subscription-dashboard/forget-password`;
export const ME_URL = `${API_URL}/api/v1/subscription-dashboard/lifetech-account`;
export const LOGOUT = `${API_URL}/api/v1/subscription-dashboard/auth/logout`;

export function login(email, password, captcha) {
  return axios.post(LOGIN_URL, {
    email,
    password,
    "g-recaptcha-response": captcha
  });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  const { account_id } = JSON.parse(localStorage.getItem("user_info"));
  return axios.get(`${ME_URL}/${account_id}`);
}

export function logout() {
  return axios.post(LOGOUT);
}
