import React from "react";
import { Paper } from "@material-ui/core";
import { Modal, Button } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { InputGroup, FormControl } from "react-bootstrap";
import { Row } from "react-bootstrap";

const EditTimeLimitModal = ({
  state,
  data,
  loading,
  closeEditPlanModal,
  formikSubscriptionTypeTimeLimitEdit,
  validationSubscriptionTypeEdit,
  closeEditTimeLimitModal,
  handleSaveTimeLimit
}) => {
  const handleSave = () => {
    closeEditPlanModal();
    const saveData = {
      id: formikSubscriptionTypeTimeLimitEdit.getFieldProps("id").value,
      name: formikSubscriptionTypeTimeLimitEdit.getFieldProps("name").value,
      limit: formikSubscriptionTypeTimeLimitEdit.getFieldProps("limit").value,
      price: formikSubscriptionTypeTimeLimitEdit.getFieldProps("price").value,
      trial_limit: formikSubscriptionTypeTimeLimitEdit.getFieldProps(
        "trial_limit"
      ).value,
      expired_tolerance: formikSubscriptionTypeTimeLimitEdit.getFieldProps(
        "expired_tolerance"
      ).value,
      late_payment_limit: formikSubscriptionTypeTimeLimitEdit.getFieldProps(
        "late_payment_limit"
      ).value
    };
    handleSaveTimeLimit(saveData);
  };

  return (
    <Modal show={state} onHide={closeEditPlanModal}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Time Limitation</Modal.Title>
      </Modal.Header>
      <div className="p-4">
        <Form.Group style={{ width: "35%" }}>
          <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
            Free Trial Limit:
          </Form.Label>
          <InputGroup style={{ minWidth: 105 }}>
            <FormControl
              aria-label="Free Trial Limit"
              {...formikSubscriptionTypeTimeLimitEdit.getFieldProps(
                "trial_limit"
              )}
            />
            <InputGroup.Append>
              <InputGroup.Text id="daysappend">Days</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>
        <Form.Group style={{ width: "35%" }}>
          <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
            Expired Tolerance:
          </Form.Label>
          <InputGroup style={{ minWidth: 105 }}>
            <FormControl
              aria-label="Expired Tolerance"
              {...formikSubscriptionTypeTimeLimitEdit.getFieldProps(
                "expired_tolerance"
              )}
            />
            <InputGroup.Append>
              <InputGroup.Text id="daysappend">Days</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>
        <Form.Group style={{ width: "35%" }}>
          <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
            Late Payment Limit:
          </Form.Label>
          <InputGroup style={{ minWidth: 105 }}>
            <FormControl
              aria-label="Late Payment Limit"
              {...formikSubscriptionTypeTimeLimitEdit.getFieldProps(
                "late_payment_limit"
              )}
            />
            <InputGroup.Append>
              <InputGroup.Text id="daysappend">Days</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>
        <div style={{ width: "fit-content", right: 0, marginLeft: "auto" }}>
          <Button
            variant="outline-secondary"
            onClick={closeEditPlanModal}
            style={{ width: 100 }}
            className="ml-3 mr-3"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            style={{ width: 100 }}
            onClick={handleSave}
            className="ml-3 mr-3"
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default EditTimeLimitModal;
