import React from "react";
import { Paper, Switch } from "@material-ui/core";
import { Modal, Button } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { InputGroup, FormControl } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import axios from "axios";
import dayjs from "dayjs";
import '../style.css'
import IconDropdown from '../../../images/icons8-down-96.png'

const CreateSubscriptionModal = ({
  state,
  business_id,
  handleCloseSubsModal,
  formikUpdateSubscription,
  handleUpdateSubscription,
  businessMaster
}) => {

  const [showBtn, setShowBtn] = React.useState({
    cz: true,
    doku: true
  })
  const [selectedData, setSelectedData] = React.useState({});
  const [selected, setSelected] = React.useState("");
  const [selectedSubscriptionType, setSelectedSubscriptionType] = React.useState("");
  const [business, setBusiness] = React.useState({});
  const [subscriptionPartition, setSubscriptionPartition] = React.useState(null)
  const [alert, setAlert] = React.useState(null);
  const [subscriptionName, setSubscriptionName] = React.useState([]);
  const [subscriptionType, setSubscriptionType] = React.useState([
    {
      id: 1,
      name: 'Basic'
    },
    {
      id: 2,
      name: 'Standard'
    },
    {
      id: 3,
      name: 'Completed'
    }
  ]);

  const handleShowPg = (params) => {
    console.log("params", params)
    if(params === 'cz') setShowBtn({cz: !showBtn.cz, doku: showBtn.doku})
    if(params === 'doku') setShowBtn({doku: !showBtn.doku, cz: showBtn.cz})
  }

  console.log("showBtn", showBtn)

  const handleSubscriptionTypeSelect = (e) => {
    formikUpdateSubscription.setFieldValue("subscription_partition", e.target.value);
    setSelectedSubscriptionType(e.target.value)
  }

  const getBusiness = async () => {
    try {
      const data = businessMaster.filter((val) => {
        return val.id === business_id;
      })[0];
      console.log("set business", data)
      setBusiness(data);
      setSelected(data.Subscription.Subscription_Type.id);
      setSelectedData(data.Subscription.Subscription_Type);
      formikUpdateSubscription.setFieldValue(
        "subscription_type_id",
        data.Subscription.Subscription_Type.id
      );
      formikUpdateSubscription.setFieldValue(
        "outlet_limit",
        data.Subscription.outlet_limit
      );
      formikUpdateSubscription.setFieldValue(
        "cz_user",
        data.cz_user
      );
      formikUpdateSubscription.setFieldValue(
        "cz_pin",
        data.cz_pin
      );
      formikUpdateSubscription.setFieldValue(
        "cz_entity_id",
        data.cz_entity_id
      );
      formikUpdateSubscription.setFieldValue(
        "cz_vendor_identifier",
        data.cz_vendor_identifier
      );
      formikUpdateSubscription.setFieldValue(
        "doku_client_id",
        data.doku_client_id
      );
      formikUpdateSubscription.setFieldValue(
        "doku_shared_key",
        data.doku_shared_key
      );
      formikUpdateSubscription.setFieldValue(
        "doku_client_id_qris",
        data.doku_client_id_qris
      );
      formikUpdateSubscription.setFieldValue(
        "doku_shared_key_qris",
        data.doku_shared_key_qris
      );
      formikUpdateSubscription.setFieldValue(
        "doku_client_secret",
        data.doku_client_secret
      );
      let boolStatus;
      if (data.Subscription.status === "active") {
        boolStatus = true;
      } else if (data.Subscription.status === "inactive") {
        boolStatus = false;
      }
      formikUpdateSubscription.setFieldValue("status", boolStatus);
      formikUpdateSubscription.setFieldValue(
        "expired_date",
        dayjs(data.Subscription.expired_date).format("YYYY-MM-DD")
      );
    } catch (err) {
      setBusiness({});
    }
  };

  const initialSubscriptionType = {
    name: "",
    limit: 0,
    price: 0,
    trial_limit: 0,
    expired_tolerance: 0,
    late_payment_limit: 0
  };

  const getSubscriptionPartition = async () => {
    try {
      const API_URL = process.env.REACT_APP_API_URL;
      const { data } = await axios.get(`${API_URL}/api/v1/subscription?business_id=${business_id}`);  
      setSelectedSubscriptionType(data.data[0].subscription_partition_id)
      console.log("data.data.subscription_partition_id", data.data[0])
    } catch (error) {
      setSelectedSubscriptionType(null)
    }
  }

  const getSubscriptionType = async (search, filter) => {
    const API_URL = process.env.REACT_APP_API_URL;
    try {
      const { data } = await axios.get(`${API_URL}/api/v1/subscription-type`);
      setSubscriptionName(data.data);
      if (data.data.length) {
        // if (!selected) {
        //   setSelectedData(data.data[0]);
        //   // setSelected(data.data[0].id);
        //   const expired_date = dayjs(new Date())
        //     .add(data.data[0].limit, "day")
        //     .format("YYYY-MM-DD");
        //   // formikUpdateSubscription.setFieldValue("expired_date", expired_date);
        // } else {
        //   const filt = data.data.filter((item) => {
        //     return item.id == selected;
        //   });
        //   setSelectedData(filt[0]);
        //   // setSelected(data.data[0].id);
        //   const expired_date = dayjs(new Date())
        //     .add(filt[0].limit, "day")
        //     .format("YYYY-MM-DD");
        //   // formikUpdateSubscription.setFieldValue("expired_date", expired_date);
        // }
      } else {
        setSelectedData(initialSubscriptionType);
      }
    } catch (err) {
      setSubscriptionName([]);
    }
  };

  React.useEffect(() => {
    getSubscriptionType();
    getSubscriptionPartition()
    if (business_id) {
      getBusiness();
    }
  }, [business_id, state]);

  const handlePlanSelect = (event) => {
    setSelected(event.target.value);
    const data = subscriptionName.filter((val) => {
      return val.id == event.target.value;
    });
    setSelectedData(...data);
    const expired_date = dayjs(new Date())
      .add(data[0] ? data[0].limit : 0, "day")
      .format("YYYY-MM-DD");
    formikUpdateSubscription.setFieldValue("expired_date", expired_date);
    formikUpdateSubscription.setFieldValue("subscription_type_id", data[0].id);
  };

  const handleSave = async () => {
    if (!business_id) {
      setAlert("Unable to get Business ID");
    }
    if (business) {
      const saveData = {
        business_id,
        subscription_type_id: formikUpdateSubscription.getFieldProps(
          "subscription_type_id"
        ).value,
        subscription_partition_id: parseInt(selectedSubscriptionType), 
        expired_date: formikUpdateSubscription.getFieldProps("expired_date")
          .value,
        outlet_limit: formikUpdateSubscription.getFieldProps("outlet_limit")
          .value,
        status: formikUpdateSubscription.getFieldProps("status").value
          ? "active"
          : "inactive",
        cz_user: formikUpdateSubscription.getFieldProps("cz_user").value,
        cz_pin: formikUpdateSubscription.getFieldProps("cz_pin").value,
        cz_entity_id: formikUpdateSubscription.getFieldProps("cz_entity_id").value,
        cz_vendor_identifier: formikUpdateSubscription.getFieldProps("cz_vendor_identifier").value,
        doku_client_id: formikUpdateSubscription.getFieldProps("doku_client_id").value,
        doku_shared_key: formikUpdateSubscription.getFieldProps("doku_shared_key").value,
        doku_client_id_qris: formikUpdateSubscription.getFieldProps("doku_client_id_qris").value,
        doku_shared_key_qris: formikUpdateSubscription.getFieldProps("doku_shared_key_qris").value,
        doku_client_secret: formikUpdateSubscription.getFieldProps("doku_client_secret").value,
      };
      console.log("saveData", saveData)

      // send update request to API
      await handleUpdateSubscription(business.Subscription.id, saveData);
      handleCloseSubsModal();
    }
  };

  return (
    <Modal
      show={state}
      onHide={() => {
        handleCloseSubsModal();
        setSelected("");
        setSelectedData("");
        setAlert(null);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Create Subscription: </Modal.Title>
      </Modal.Header>
      <div className="p-4 text-danger">{alert}</div>
      <div className="p-4">
        <Form.Group>
          <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
            Subscription Name:
          </Form.Label>
          <InputGroup style={{ minWidth: 105 }}>
            <Form.Control
              as="select"
              value={selected}
              name="subscription_name"
              onChange={(e) => handlePlanSelect(e)}
            >
              {subscriptionName.map((val, idx) => {
                return (
                  <option key={val.id} value={val.id}>
                    {val.name}
                  </option>
                );
              })}
            </Form.Control>
          </InputGroup>
        </Form.Group>
        <Row>
          <Col>
            <Form.Group style={{ width: "80%" }}>
              <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                Expiry Date
              </Form.Label>
              <InputGroup style={{ minWidth: 105 }}>
                <FormControl
                  type="date"
                  aria-label="Expiry Date"
                  {...formikUpdateSubscription.getFieldProps("expired_date")}
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group style={{ width: "100%" }}>
              <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                Limit Outlet
              </Form.Label>
              <Form.Control
                type="number"
                {...formikUpdateSubscription.getFieldProps("outlet_limit")}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <div className="btn btn-info" onClick={() => handleShowPg("cz")}>
              <div>
                Cashlez
              </div>
            </div>
            <div className={showBtn.cz ? 'hidden' : null}>
              <Form.Group style={{ width: "100%" }}>
                <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                  CZ User
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="CZ User"
                  {...formikUpdateSubscription.getFieldProps("cz_user")}
                />
              </Form.Group>
              <Form.Group style={{ width: "100%" }}>
                <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                  CZ PIN
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="CZ PIN"
                  {...formikUpdateSubscription.getFieldProps("cz_pin")}
                />
              </Form.Group>
              <Form.Group style={{ width: "100%" }}>
                <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                  CZ Entity ID
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="CZ Entity ID"
                  {...formikUpdateSubscription.getFieldProps("cz_entity_id")}
                />
              </Form.Group>
              <Form.Group style={{ width: "100%" }}>
                <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                  CZ Vendor Identifier
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="CZ Vendor Identifier"
                  {...formikUpdateSubscription.getFieldProps("cz_vendor_identifier")}
                />
              </Form.Group>
            </div>
          </Col>
          <Col>
            <div className="btn btn-info" onClick={() => handleShowPg("doku")}>
              DOKU
            </div>
            <div className={showBtn.doku ? 'hidden' : null}>
              <Form.Group style={{ width: "100%" }}>
                <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                  DOKU Client ID
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="DOKU Client ID"
                  {...formikUpdateSubscription.getFieldProps("doku_client_id")}
                />
              </Form.Group>
              <Form.Group style={{ width: "100%" }}>
                <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                  DOKU Shared Key
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="DOKU Shared Key"
                  {...formikUpdateSubscription.getFieldProps("doku_shared_key")}
                />
              </Form.Group>
              <Form.Group style={{ width: "100%" }}>
                <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                  DOKU Client Id Qris
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="DOKU Client Id Qris"
                  {...formikUpdateSubscription.getFieldProps("doku_client_id_qris")}
                />
              </Form.Group>
              <Form.Group style={{ width: "100%" }}>
                <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                  DOKU Shared Key Qris
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="DOKU Shared Key Qris"
                  {...formikUpdateSubscription.getFieldProps("doku_shared_key_qris")}
                />
              </Form.Group>
              <Form.Group style={{ width: "100%" }}>
                <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                  DOKU Client Secret
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="DOKU Client Secret"
                  {...formikUpdateSubscription.getFieldProps("doku_client_secret")}
                />
              </Form.Group>
            </div>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <Form.Group style={{ width: "35%" }}>
              <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                Status
              </Form.Label>
              <Form.Check
                type="checkbox"
                label="Active"
                checked={formikUpdateSubscription.getFieldProps("status").value}
                {...formikUpdateSubscription.getFieldProps("status")}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
              Subscription Type:
            </Form.Label>
            <InputGroup style={{ minWidth: 105 }}>
              <Form.Control
                as="select"
                value={selectedSubscriptionType}
                name="subscription_type"
                onChange={(e) => handleSubscriptionTypeSelect(e)}
              >
                {subscriptionType.map((val, idx) => {
                  return (
                    <option key={val.id} value={val.id}>
                      {val.name}
                    </option>
                  );
                })}
              </Form.Control>
            </InputGroup>
          </Col>
        </Row>
        <div style={{ width: "fit-content", right: 0, marginLeft: "auto" }}>
          <Button
            variant="outline-secondary"
            onClick={() => {
              handleCloseSubsModal();
              setAlert(null);
            }}
            style={{ width: 100 }}
            className="ml-3 mr-3"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            style={{ width: 100 }}
            onClick={handleSave}
            className="ml-3 mr-3"
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateSubscriptionModal;
