import React from 'react'
import {
  Row,
  Col,
  Modal,
  Form,
  Button
} from 'react-bootstrap'

export default function GrabfoodModal({
  state,
  alert,
  close,
  formikGrabFood,
  setAlert,
  handleSaveGrabFood
}) {
  return (
    <div>
      <Modal
        show={state}
        onHide={() => {
          close();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>GrabFood Modal: </Modal.Title>
        </Modal.Header>
        <div className="p-4 text-danger">{alert}</div>
        <div className="p-4">
          <Row>
            <Col>
              <Form.Group style={{ width: "100%" }}>
                <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                  Store Name GrabFood
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Store Name GrabFood"
                  {...formikGrabFood.getFieldProps("store_name_grabfood")}
                />
              </Form.Group>
              <Form.Group style={{ width: "100%" }}>
                <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                  GrabFood Id
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="GrabFood Id"
                  {...formikGrabFood.getFieldProps("grabfood_id")}
                />
              </Form.Group>
              {/* <Form.Group style={{ width: "100%" }}>
                <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                  Activation Date Blibli
                </Form.Label>
                <Form.Control
                  type="date"
                  placeholder="DOKU Client Secret"
                  {...formikGrabFood.getFieldProps("activation_date_blibli")}
                />
              </Form.Group>
              <Form.Group style={{ width: "100%" }}>
                <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                  Update Date Blibli
                </Form.Label>
                <Form.Control
                  type="date"
                  placeholder="DOKU Client Secret"
                  {...formikGrabFood.getFieldProps("update_date_blibli")}
                />
              </Form.Group> */}
              <Form.Group style={{ width: "35%" }}>
                <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                  Status Integrate GrabFood
                </Form.Label>
                <Form.Check
                  type="checkbox"
                  label="Active"
                  checked={formikGrabFood.getFieldProps("status_integrate_grabfood").value}
                  {...formikGrabFood.getFieldProps("status_integrate_grabfood")}
                />
              </Form.Group>
              <div style={{ width: "fit-content", right: 0, marginLeft: "auto" }}>
                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    close();
                    setAlert(null);
                  }}
                  style={{ width: 100 }}
                  className="ml-3 mr-3"
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  style={{ width: 100 }}
                  onClick={handleSaveGrabFood}
                  className="ml-3 mr-3"
                >
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  )
}
