import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { Card, CardBody, CardHeader } from "../../../_metronic/_partials/controls";
import { Form, InputGroup, Row, Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Search } from "@material-ui/icons";
import ExportExcel from "react-html-table-to-excel";
import Swal from "sweetalert2";
import Select from "react-select";

const CashlezTransactionReport = () => {
  const [transactions, setTransactions] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [dateStart, setDateStart] = useState(getFormattedDate(new Date(new Date().setDate(new Date().getDate() - 30))));
  const [dateEnd, setDateEnd] = useState(getFormattedDate(new Date()));
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const authToken = useSelector((state) => state.auth.authToken);
  const [totalSummary, setTotalSummary] = useState({
    totalTransactions: 0,
    totalAmount: 0,
    totalFeeMerchant: 0,
    totalTaxPayment: 0,
  });
  const [businesses, setBusinesses] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [outlets, setOutlets] = useState([]);
  const [selectedOutlet, setSelectedOutlet] = useState(null);

  const columns = [
    {
      name: "Tanggal",
      selector: "date",
      sortable: true,
    },
    {
      name: "Total Transaksi",
      selector: "totalTransactions",
      sortable: true,
      right: true,
    },
    {
      name: "Total Amount",
      selector: "totalAmount",
      sortable: true,
      right: true,
      format: (row) => formatCurrency(row.totalAmount),
    },
    {
      name: "Total Fee Merchant",
      selector: "totalFeeMerchant",
      sortable: true,
      right: true,
      format: (row) => formatCurrency(row.totalFeeMerchant),
    },
    {
      name: "Total Tax Payment",
      selector: "totalTaxPayment",
      sortable: true,
      right: true,
      format: (row) => formatCurrency(row.totalTaxPayment),
    },
  ];

  const fetchTransactions = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    
    if (!selectedBusiness) {
      setTransactions([]);
      setReportData([]);
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.get(
        `https://api.beetpos.com/api/v1/cz-transaction`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          },
          params: {
            date_start: dateStart,
            date_end: dateEnd,
            business_id: selectedBusiness.value,
            outlet_id: selectedOutlet?.value || ""
          }
        }
      );

      if (response.data.statusCode === 200) {
        setTransactions(response.data.data || []);
        generateReportData(response.data.data || []);
      } else {
        throw new Error("Gagal mengambil data transaksi");
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
      setError("Terjadi kesalahan saat mengambil data transaksi. Silakan coba lagi.");
      Swal.fire({
        icon: "error",
        title: "Gagal mengambil data",
        text: "Terjadi kesalahan saat mengambil data transaksi. Silakan coba lagi."
      });
    } finally {
      setIsLoading(false);
    }
  }, [authToken, dateStart, dateEnd, selectedBusiness, selectedOutlet]);

  const generateReportData = (transactions) => {
    const reportMap = new Map();
    let totalSummary = {
      totalTransactions: 0,
      totalAmount: 0,
      totalFeeMerchant: 0,
      totalTaxPayment: 0,
    };

    transactions.forEach(transaction => {
      const date = transaction.created_date.split('T')[0];
      if (!reportMap.has(date)) {
        reportMap.set(date, {
          date,
          totalTransactions: 0,
          totalAmount: 0,
          totalFeeMerchant: 0,
          totalTaxPayment: 0
        });
      }

      const report = reportMap.get(date);
      report.totalTransactions += 1;
      report.totalAmount += parseFloat(transaction.transaction_amount) || 0;
      report.totalFeeMerchant += parseFloat(transaction.fee_merchant) || 0;
      report.totalTaxPayment += parseFloat(transaction.tax_payment) || 0;

      totalSummary.totalTransactions += 1;
      totalSummary.totalAmount += parseFloat(transaction.transaction_amount) || 0;
      totalSummary.totalFeeMerchant += parseFloat(transaction.fee_merchant) || 0;
      totalSummary.totalTaxPayment += parseFloat(transaction.tax_payment) || 0;
    });

    const reportArray = Array.from(reportMap.values());
    setReportData(reportArray.sort((a, b) => b.date.localeCompare(a.date)));
    setTotalSummary(totalSummary);
  };

  useEffect(() => {
    fetchBusinesses();
  }, []);

  useEffect(() => {
    if (selectedBusiness) {
      fetchTransactions();
    }
  }, [fetchTransactions, selectedBusiness]);

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === "dateStart") {
      setDateStart(value);
    } else if (name === "dateEnd") {
      setDateEnd(value);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredData = reportData.filter((item) =>
    item.date.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR"
    }).format(amount);
  };

  function getFormattedDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const formatTransactionsForExcel = () => {
    const formattedData = reportData.map((item) => ({
      "Tanggal": item.date,
      "Total Transaksi": item.totalTransactions,
      "Total Amount": formatCurrency(item.totalAmount),
      "Total Fee Merchant": formatCurrency(item.totalFeeMerchant),
      "Total Tax Payment": formatCurrency(item.totalTaxPayment),
    }));

    formattedData.push({
      "Tanggal": "TOTAL",
      "Total Transaksi": totalSummary.totalTransactions,
      "Total Amount": formatCurrency(totalSummary.totalAmount),
      "Total Fee Merchant": formatCurrency(totalSummary.totalFeeMerchant),
      "Total Tax Payment": formatCurrency(totalSummary.totalTaxPayment),
    });

    return formattedData;
  };

  const customStyles = {
    rows: {
      style: {
        minHeight: '72px',
      }
    },
    headCells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
      },
    },
  };

  const fetchBusinesses = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/business`, {
        headers: { Authorization: `Bearer ${authToken}` }
      });
      const businessOptions = response.data.data.map(business => ({
        value: business.id,
        label: business.name
      }));
      setBusinesses(businessOptions);
    } catch (error) {
      console.error("Error fetching businesses:", error);
    }
  };

  const fetchOutlets = async (businessId) => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/business/${businessId}`);
      const outletOptions = data.data.Outlets.map(outlet => ({
        value: outlet.id,
        label: outlet.name
      }));
      setOutlets([{ value: "", label: "Semua Outlet" }, ...outletOptions]);
    } catch (error) {
      console.error("Error fetching outlets:", error);
    }
  };

  const handleBusinessChange = (selectedOption) => {
    setSelectedBusiness(selectedOption);
    setSelectedOutlet(null);
    if (selectedOption) {
      fetchOutlets(selectedOption.value);
    } else {
      setOutlets([]);
    }
  };

  const handleOutletChange = (selectedOption) => {
    setSelectedOutlet(selectedOption);
  };

  return (
    <Card>
      <CardHeader title="Laporan Transaksi Cashlez">
        <div className="card-toolbar">
          <ExportExcel
            id="test-table-xls-button"
            className="btn btn-success font-weight-bolder font-size-sm"
            table="table-to-xls"
            filename="cashlez_transaction_report"
            sheet="report"
            buttonText="Export to Excel"
          />
        </div>
      </CardHeader>
      <CardBody>
        <div className="form-group row mb-6">
          <div className="col-lg-3 mb-lg-0 mb-6">
            <Form.Group>
              <Form.Label>Business:</Form.Label>
              <Select
                value={selectedBusiness}
                onChange={handleBusinessChange}
                options={businesses}
                isClearable
                isSearchable
                placeholder="Select Business"
              />
            </Form.Group>
          </div>
          <div className="col-lg-3 mb-lg-0 mb-6">
            <Form.Group>
              <Form.Label>Outlet:</Form.Label>
              <Select
                value={selectedOutlet}
                onChange={handleOutletChange}
                options={outlets}
                isClearable
                isSearchable
                placeholder="Select Outlet"
                isDisabled={!selectedBusiness}
              />
            </Form.Group>
          </div>
          <div className="col-lg-2 mb-lg-0 mb-6">
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <Search />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                placeholder="Cari berdasarkan tanggal"
                value={searchTerm}
                onChange={handleSearch}
              />
            </InputGroup>
          </div>
          <div className="col-lg-2 mb-lg-0 mb-6">
            <Form.Group>
              <Form.Label>Tanggal Mulai:</Form.Label>
              <Form.Control
                type="date"
                name="dateStart"
                value={dateStart}
                onChange={handleDateChange}
              />
            </Form.Group>
          </div>
          <div className="col-lg-2 mb-lg-0 mb-6">
            <Form.Group>
              <Form.Label>Tanggal Akhir:</Form.Label>
              <Form.Control
                type="date"
                name="dateEnd"
                value={dateEnd}
                onChange={handleDateChange}
              />
            </Form.Group>
          </div>
        </div>
        <table id="table-to-xls" style={{ display: "none" }}>
          <thead>
            <tr>
              <th>Tanggal</th>
              <th>Total Transaksi</th>
              <th>Total Amount</th>
              <th>Total Fee Merchant</th>
              <th>Total Tax Payment</th>
            </tr>
          </thead>
          <tbody>
            {formatTransactionsForExcel().map((item, index) => (
              <tr key={index}>
                <td>{item["Tanggal"]}</td>
                <td>{item["Total Transaksi"]}</td>
                <td>{item["Total Amount"]}</td>
                <td>{item["Total Fee Merchant"]}</td>
                <td>{item["Total Tax Payment"]}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {error && <div className="text-danger mb-5">{error}</div>}

        <DataTable
          columns={columns}
          data={filteredData}
          pagination
          paginationPerPage={10}
          paginationRowsPerPageOptions={[10, 25, 50, 100]}
          progressPending={isLoading}
          progressComponent={<div className="d-flex justify-content-center my-5">
            <div className="spinner spinner-track spinner-primary mr-10"></div>
            <span>Memuat data laporan...</span>
          </div>}
          noDataComponent={<div className="text-center my-5">Tidak ada data yang tersedia</div>}
          customStyles={customStyles}
        />

        <div className="mt-5">
          <table className="table table-bordered" style={{ tableLayout: 'fixed', width: '100%' }}>
            <thead>
              <tr className="bg-light-primary">
                <th style={{ width: '20%', }}>Tanggal</th>
                <th style={{ width: '20%',textAlign: 'right' }}>Total Transaksi</th>
                <th style={{ width: '20%', textAlign: 'right' }}>Total Amount</th>
                <th style={{ width: '20%', textAlign: 'right' }}>Total Fee Merchant</th>
                <th style={{ width: '20%', textAlign: 'right' }}>Total Tax Payment</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{`${dateStart} - ${dateEnd}`}</td>
                <td className="text-right">{totalSummary.totalTransactions}</td>
                <td className="text-right">{formatCurrency(totalSummary.totalAmount)}</td>
                <td className="text-right">{formatCurrency(totalSummary.totalFeeMerchant)}</td>
                <td className="text-right">{formatCurrency(totalSummary.totalTaxPayment)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  );
};

export default CashlezTransactionReport;