import React from "react";
import { Paper } from "@material-ui/core";
import { Modal, Button } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { InputGroup, FormControl } from "react-bootstrap";
import { Row } from "react-bootstrap";

const AddPlanModal = ({
  state,
  data,
  closeAddPlanModal,
  formikAddSubscriptionType,
  handleAddPlan
}) => {
  const [alert, setAlert] = React.useState(null);

  const handleSave = async () => {
    const saveData = {
      id: formikAddSubscriptionType.getFieldProps("id").value,
      name: formikAddSubscriptionType.getFieldProps("name").value,
      limit: formikAddSubscriptionType.getFieldProps("limit").value,
      price: formikAddSubscriptionType.getFieldProps("price").value,
      trial_limit: formikAddSubscriptionType.getFieldProps("trial_limit").value,
      expired_tolerance: formikAddSubscriptionType.getFieldProps(
        "expired_tolerance"
      ).value,
      late_payment_limit: formikAddSubscriptionType.getFieldProps(
        "late_payment_limit"
      ).value
    };
    const status = await handleAddPlan(saveData);
    if (status === 422) {
      setAlert("Unable to process data: Invalid Input");
    } else {
      closeAddPlanModal();
    }
  };

  return (
    <Modal
      show={state}
      onHide={() => {
        closeAddPlanModal();
        setAlert(null);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Subscription Plan</Modal.Title>
      </Modal.Header>
      <div className="p-4 text-danger">{alert}</div>
      <div className="p-4">
        <Form.Group>
          <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
            Subscription Name:
          </Form.Label>
          <InputGroup style={{ minWidth: 105 }}>
            <FormControl
              aria-label="Name"
              name="name"
              // value={nameInput}
              {...formikAddSubscriptionType.getFieldProps("name")}
            />
          </InputGroup>
        </Form.Group>
        <Form.Group style={{ width: "35%" }}>
          <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
            Days Limit:
          </Form.Label>
          <InputGroup style={{ minWidth: 105 }}>
            <FormControl
              aria-label="Days Limit"
              name="limit"
              {...formikAddSubscriptionType.getFieldProps("limit")}
            />
            <InputGroup.Append>
              <InputGroup.Text id="daysappend">Days</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>
        <Form.Group style={{ width: "35%" }}>
          <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
            Price:
          </Form.Label>
          <InputGroup style={{ minWidth: 150 }}>
            <InputGroup.Prepend>
              <InputGroup.Text id="rpappend">Rp. </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              aria-label="Price"
              name="price"
              {...formikAddSubscriptionType.getFieldProps("price")}
            />
          </InputGroup>
        </Form.Group>
        <Form.Group style={{ width: "35%" }}>
          <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
            Free Trial Limit:
          </Form.Label>
          <InputGroup style={{ minWidth: 105 }}>
            <FormControl
              aria-label="Free Trial Limit"
              {...formikAddSubscriptionType.getFieldProps("trial_limit")}
            />
            <InputGroup.Append>
              <InputGroup.Text id="daysappend">Days</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>
        <Form.Group style={{ width: "35%" }}>
          <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
            Expired Tolerance:
          </Form.Label>
          <InputGroup style={{ minWidth: 105 }}>
            <FormControl
              aria-label="Expired Tolerance"
              {...formikAddSubscriptionType.getFieldProps("expired_tolerance")}
            />
            <InputGroup.Append>
              <InputGroup.Text id="daysappend">Days</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>
        <Form.Group style={{ width: "35%" }}>
          <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
            Late Payment Limit:
          </Form.Label>
          <InputGroup style={{ minWidth: 105 }}>
            <FormControl
              aria-label="Late Payment Limit"
              {...formikAddSubscriptionType.getFieldProps("late_payment_limit")}
            />
            <InputGroup.Append>
              <InputGroup.Text id="daysappend">Days</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>
        <div style={{ width: "fit-content", right: 0, marginLeft: "auto" }}>
          <Button
            variant="outline-secondary"
            onClick={() => {
              closeAddPlanModal();
              setAlert(null);
            }}
            style={{ width: 100 }}
            className="ml-3 mr-3"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            style={{ width: 100 }}
            onClick={handleSave}
            className="ml-3 mr-3"
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddPlanModal;
