import React from 'react'
import {
  Row,
  Col,
  Modal,
  Form,
  Button
} from 'react-bootstrap'

export default function ModalAllRequest({
  state,
  close,
  alert, 
  setAlert,
  handleLoadData
}) {
  return (
    <div>
    <Modal
      show={state}
      onHide={() => {
        close();
      }}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>All Request Integration: </Modal.Title>
      </Modal.Header>
      <div className="p-4 text-danger">{alert}</div>
      <div className="p-4">
        <Row>
          <Col>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero maiores explicabo consequuntur blanditiis reprehenderit illo error quis hic ex amet.
            <div className="btn btn-warning" onClick={handleLoadData}>Load Data</div>
          </Col>
        </Row>
      </div>
    </Modal>
    </div>
  )
}
