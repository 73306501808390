import React from "react";
import axios from "axios";
import { Paper } from "@material-ui/core";
import { Form, InputGroup } from "react-bootstrap";
import { Button, FormControl, Modal } from "react-bootstrap";
import { AddOutlined, DeleteOutlined, EditOutlined } from "@material-ui/icons";
import { useFormik } from "formik";
import * as Yup from "yup";

import EditPlanModal from "./EditPlanModal";
import AddPlanModal from "./AddPlanModal";
import EditTimeLimitModal from "./EditTimeLimitModal";

export function LimitSettingPage() {
  const [subscriptionType, setSubscriptionType] = React.useState([]);
  const [selected, setSelected] = React.useState("");
  const [selectedData, setSelectedData] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const [alert, setAlert] = React.useState(null);

  const [addPlanModal, setAddPlanModal] = React.useState(false);
  const [editPlanModal, setEditPlanModal] = React.useState(false);
  const [editTimeLimitModal, setEditTimeLimitModal] = React.useState(false);
  const [deleteConfirm, setDeleteConfirm] = React.useState(false);

  const getSubscriptionType = async (search, filter) => {
    const API_URL = process.env.REACT_APP_API_URL;
    try {
      const { data } = await axios.get(`${API_URL}/api/v1/subscription-type`);
      setSubscriptionType(data.data);
      if (data.data.length) {
        if (!selected) {
          setSelectedData(data.data[0]);
        } else {
          const filt = data.data.filter((item) => {
            return item.name === selected;
          });
          setSelectedData(filt[0]);
        }
      } else {
        setSelectedData(initialSubscriptionType);
      }
    } catch (err) {
      setSubscriptionType([]);
    }
  };

  React.useEffect(() => {
    getSubscriptionType();
  }, [refresh]);

  const enableLoading = () => setLoading(true);
  const disableLoading = () => setLoading(false);
  const handleRefresh = () => setRefresh(refresh + 1);

  const initialSubscriptionType = {
    name: "",
    limit: 0,
    price: 0,
    trial_limit: 0,
    expired_tolerance: 0,
    late_payment_limit: 0
  };

  const AddSubscriptionTypeSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Minimum 3 characters.")
      .max(50, "Maximum 50 characters.")
      .required("Please input a name."),
    limit: Yup.number()
      .integer()
      .min(0)
      .required("Please Input Days Limit"),
    price: Yup.number()
      .integer()
      .min(0)
      .required("Please input a charge."),
    trial_limit: Yup.number()
      .integer()
      .min(0)
      .required("Please input trial limit."),
    expired_tolerance: Yup.number()
      .integer()
      .min(0)
      .required("Please input expired tolerance"),
    late_payment_limit: Yup.number()
      .integer()
      .min(0)
      .required("Please input late payment limit")
  });

  const EditSubscriptionTypeSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Minimum 3 characters.")
      .max(50, "Maximum 50 characters.")
      .required("Please input a name."),
    limit: Yup.number()
      .integer()
      .min(0)
      .required("Please Input Days Limit"),
    price: Yup.number()
      .integer()
      .min(0)
      .required("Please input a charge.")
  });

  const EditTimeLimitSchema = Yup.object().shape({
    trial_limit: Yup.number()
      .integer()
      .min(0)
      .required("Please input trial limit"),
    expired_limit: Yup.number()
      .integer()
      .min(0)
      .required("Please input expired limit"),
    late_payment_limit: Yup.number()
      .integer()
      .min(0)
      .required("Please input late payment limit.")
  });

  const formikAddSubscriptionType = useFormik({
    enableReinitialize: true,
    initialValues: initialSubscriptionType,
    validationSchema: AddSubscriptionTypeSchema,
    onSubmit: async (values) => {
      const subscriptionTypeData = {
        name: values.name,
        limit: values.limit,
        price: values.price,
        trial_limit: values.trial_limit,
        expired_tolerance: values.expired_tolerance,
        late_payment_limit: values.late_payment_limit
      };

      const API_URL = process.env.REACT_APP_API_URL;
      try {
        enableLoading();
        await axios.post(
          `${API_URL}/api/v1/subscription-type/`,
          subscriptionTypeData
        );
        handleRefresh();
        disableLoading();
        closeEditPlanModal();
      } catch (err) {
        setAlert(err.response?.data.message || err.message);
        disableLoading();
      }
    }
  });

  const formikSubscriptionTypeEdit = useFormik({
    enableReinitialize: true,
    initialValues: initialSubscriptionType,
    validationSchema: EditSubscriptionTypeSchema,
    onSubmit: async (values) => {
      const subscriptionTypeData = {
        id: values.id,
        name: values.name,
        limit: values.limit,
        price: values.price,
        trial_limit: values.trial_limit,
        expired_tolerance: values.expired_tolerance,
        late_payment_limit: values.late_payment_limit
      };

      const API_URL = process.env.REACT_APP_API_URL;
      try {
        enableLoading();
        await axios.put(
          `${API_URL}/api/v1/subscription-type/${values.id}`,
          subscriptionTypeData
        );
        handleRefresh();
        disableLoading();
        closeEditPlanModal();
      } catch (err) {
        setAlert(err.response?.data.message || err.message);
        disableLoading();
      }
    }
  });

  const formikSubscriptionTypeTimeLimitEdit = useFormik({
    enableReinitialize: true,
    initialValues: initialSubscriptionType,
    validationSchema: EditTimeLimitSchema,
    onSubmit: async (values) => {
      const subscriptionTypeData = {
        id: values.id,
        name: values.name,
        limit: values.limit,
        price: values.price,
        trial_limit: values.trial_limit,
        expired_tolerance: values.expired_tolerance,
        late_payment_limit: values.late_payment_limit
      };

      const API_URL = process.env.REACT_APP_API_URL;
      try {
        enableLoading();
        await axios.put(
          `${API_URL}/api/v1/subscription-type/${values.id}`,
          subscriptionTypeData
        );
        handleRefresh();
        disableLoading();
        closeEditPlanModal();
      } catch (err) {
        setAlert(err.response?.data.message || err.message);
        disableLoading();
      }
    }
  });

  const validationAddSubscriptionType = (fieldname) => {
    if (
      formikAddSubscriptionType.touched[fieldname] &&
      formikAddSubscriptionType.errors[fieldname]
    ) {
      return "is-invalid";
    }

    if (
      formikAddSubscriptionType.touched[fieldname] &&
      !formikAddSubscriptionType.errors[fieldname]
    ) {
      return "is-valid";
    }

    return "";
  };

  const validationSubscriptionTypeEdit = (fieldname) => {
    if (
      formikSubscriptionTypeEdit.touched[fieldname] &&
      formikSubscriptionTypeEdit.errors[fieldname]
    ) {
      return "is-invalid";
    }

    if (
      formikSubscriptionTypeEdit.touched[fieldname] &&
      !formikSubscriptionTypeEdit.errors[fieldname]
    ) {
      return "is-valid";
    }

    return "";
  };

  const handlePlanSelect = (event) => {
    setSelected(event.target.value);
    console.log(event.target.value);
    // select the corresponding subscription type data

    const data = subscriptionType.filter((val, idx) => {
      return val.id == event.target.value;
    });
    setSelectedData(...data);
  };

  const showEditPlanModal = (data) => {
    formikSubscriptionTypeEdit.setValues({
      id: data.id,
      name: data.name,
      limit: data.limit,
      price: data.price,
      trial_limit: data.trial_limit,
      expired_tolerance: data.expired_tolerance,
      late_payment_limit: data.late_payment_limit
    });
    setEditPlanModal(true);
  };
  const closeEditPlanModal = () => setEditPlanModal(false);

  const showAddPlanModal = () => {
    formikAddSubscriptionType.setValues({
      name: "",
      limit: "",
      price: "",
      trial_limit: "",
      expired_tolerance: "",
      late_payment_limit: ""
    });
    setAddPlanModal(true);
  };
  const closeAddPlanModal = () => setAddPlanModal(false);

  const showEditTimeLimitModal = (data) => {
    formikSubscriptionTypeTimeLimitEdit.setValues({
      id: data.id,
      name: data.name,
      limit: data.limit,
      price: data.price,
      trial_limit: data.trial_limit,
      expired_tolerance: data.expired_tolerance,
      late_payment_limit: data.late_payment_limit
    });
    setEditTimeLimitModal(true);
  };
  const closeEditTimeLimitModal = () => setEditTimeLimitModal(false);

  const handleSavePlan = async (sendData) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const { id } = sendData;
    delete sendData.id;
    try {
      enableLoading();
      await axios.put(`${API_URL}/api/v1/subscription-type/${id}`, sendData);
      const { data } = await axios.get(
        `${API_URL}/api/v1/subscription-type/${id}`
      );
      disableLoading();
      setSelected(data.data.name);
      handleRefresh();
      closeEditPlanModal();
    } catch (err) {}
  };

  const handleAddPlan = async (sendData) => {
    const API_URL = process.env.REACT_APP_API_URL;
    try {
      enableLoading();
      await axios.post(`${API_URL}/api/v1/subscription-type/`, sendData);
      disableLoading();
      setSelected(sendData.name);
      handleRefresh();
      closeEditPlanModal();
    } catch (err) {
      return err.response.status;
    }
  };

  const handleSaveTimeLimit = async (sendData) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const { id } = sendData;
    delete sendData.id;
    try {
      enableLoading();
      await axios.put(`${API_URL}/api/v1/subscription-type/${id}`, sendData);
      const { data } = await axios.get(
        `${API_URL}/api/v1/subscription-type/${id}`
      );
      disableLoading();
      setSelected(data.data.name);
      handleRefresh();
      closeEditPlanModal();
    } catch (err) {}
  };

  const showDeleteConfirm = (data) => {
    setDeleteConfirm(true);
  };
  const closeDeleteConfirm = () => {
    setDeleteConfirm(false);
    setLoading(false);
    setAlert(null);
  };

  const handleDelete = async (data) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const { id } = data;
    try {
      enableLoading();
      await axios.delete(`${API_URL}/api/v1/subscription-type/${id}`);
      setSelected("");
      setSelectedData(initialSubscriptionType);
      disableLoading();
      handleRefresh();
      setDeleteConfirm(false);
    } catch (err) {
      setAlert(err.response?.data.message || err.message);
    }
  };

  return (
    <>
      <EditPlanModal
        state={editPlanModal}
        data={selectedData}
        loading={loading}
        formikSubscriptionTypeEdit={formikSubscriptionTypeEdit}
        validationSubscriptionType={validationSubscriptionTypeEdit}
        closeEditPlanModal={closeEditPlanModal}
        handleSavePlan={handleSavePlan}
      />
      <AddPlanModal
        state={addPlanModal}
        loading={loading}
        formikAddSubscriptionType={formikAddSubscriptionType}
        validationAddSubscriptionType={validationAddSubscriptionType}
        closeAddPlanModal={closeAddPlanModal}
        handleAddPlan={handleAddPlan}
      />
      <EditTimeLimitModal
        state={editTimeLimitModal}
        data={selectedData}
        loading={loading}
        formikSubscriptionTypeTimeLimitEdit={
          formikSubscriptionTypeTimeLimitEdit
        }
        validationSubscriptionType={validationSubscriptionTypeEdit}
        closeEditPlanModal={closeEditTimeLimitModal}
        handleSaveTimeLimit={handleSaveTimeLimit}
      />
      <Modal show={deleteConfirm} onHide={closeDeleteConfirm}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <div className="p-4">Are you sure want to delete this item?</div>
        <div className="p-4 text-red">{alert}</div>
        <div style={{ width: "fit-content", right: 0, marginLeft: "auto" }}>
          <Button
            variant="secondary"
            onClick={closeDeleteConfirm}
            className="m-2"
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => handleDelete(selectedData)}
            disabled={loading}
            className="m-2"
          >
            Delete
          </Button>
        </div>
      </Modal>
      <div className="d-flex w-100 h-50" style={{ margin: "auto auto" }}>
        <Paper
          elevation={2}
          style={{
            marginRight: "1rem",
            padding: "2rem",
            height: "fit-content",
            width: "100%"
          }}
        >
          <div className="border-bottom pb-3 d-flex">
            <h4>Subscription Plan</h4>
            <div style={{ right: 0, marginLeft: "auto" }}>
              <Button
                variant="link"
                className="pl-3 pr-3"
                onClick={() => showAddPlanModal()}
              >
                <AddOutlined />
              </Button>
              <Button
                variant="link"
                className="pl-3 pr-3"
                onClick={() => showEditPlanModal(selectedData)}
                disabled={!subscriptionType.length}
              >
                <EditOutlined />
              </Button>
              <Button
                variant="link"
                className="pl-3 pr-3"
                onClick={() => showDeleteConfirm(selectedData)}
                disabled={!subscriptionType.length}
              >
                <DeleteOutlined />
              </Button>
            </div>

            {/* <DropdownButton
              title="Action"
              style={{ padding: 0, right: 0, marginLeft: "auto" }}
            >
              <DropdownItem>
                <AddOutlined className="mr-3" />
                Add
              </DropdownItem>
              <DropdownItem>
                <EditOutlined
                  className="mr-3"
                  onClick={                />
                Edit
              </DropdownItem>
              <DropdownItem>
                <DeleteOutlined className="mr-3" />
                Delete
              </DropdownItem>
            </DropdownButton> */}
          </div>
          <Form.Group>
            <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
              Select Subscription:
            </Form.Label>
            <Form.Control
              as="select"
              value={selected}
              name="subscription_plan"
              onChange={(e) => handlePlanSelect(e)}
            >
              {subscriptionType.map((val, idx) => {
                return (
                  <option key={val.id} value={val.id}>
                    {val.name}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
              Days Limit:
            </Form.Label>
            <InputGroup style={{ minWidth: 150, width: "50%" }}>
              <FormControl
                aria-label="Days Limit"
                value={selectedData.limit ? selectedData.limit : ""}
                disabled
              />
              <InputGroup.Append>
                <InputGroup.Text id="daysappend">Days</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
          <Form.Group>
            <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
              Price:
            </Form.Label>
            <InputGroup style={{ minWidth: 150, width: "75%" }}>
              <InputGroup.Prepend>
                <InputGroup.Text id="rpPrepend">Rp.</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                aria-label="Price"
                value={selectedData.price ? selectedData.price : ""}
                disabled
              />
            </InputGroup>
          </Form.Group>
        </Paper>
        <Paper
          elevation={2}
          style={{
            padding: "2rem",
            height: "fit-content",
            width: "100%"
          }}
        >
          <div className="border-bottom pb-3 d-flex">
            <h4>Time Limitation</h4>
            <div style={{ right: 0, marginLeft: "auto" }}>
              <Button
                variant="link"
                className="pl-3 pr-3"
                onClick={() => showEditTimeLimitModal(selectedData)}
                disabled={!subscriptionType.length}
              >
                <EditOutlined />
              </Button>
            </div>
          </div>
          <Form.Group>
            <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
              Free Trial Limit:
            </Form.Label>
            <InputGroup style={{ minWidth: 105, width: "35%" }}>
              <FormControl
                aria-label="Free Trial Limit"
                value={selectedData.trial_limit}
                disabled
              />
              <InputGroup.Append>
                <InputGroup.Text id="daysappend">Days</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
          <Form.Group>
            <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
              Expired Tolerance:
            </Form.Label>
            <InputGroup style={{ minWidth: 105, width: "35%" }}>
              <FormControl
                aria-label="Expired Tolerance"
                value={selectedData.expired_tolerance}
                disabled
              />
              <InputGroup.Append>
                <InputGroup.Text id="daysappend">Days</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
          <Form.Group>
            <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
              Late Payment Limit:
            </Form.Label>
            <InputGroup style={{ minWidth: 105, width: "35%" }}>
              <FormControl
                aria-label="Late Payment Limit"
                value={selectedData.late_payment_limit}
                disabled
              />
              <InputGroup.Append>
                <InputGroup.Text id="daysappend">Days</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
        </Paper>
      </div>
    </>
  );
}
