import React from 'react'
import { Modal, Button, Form } from "react-bootstrap";

export default function ModalCreate({action, state, handleCloseModal, alert, formik, validationFormik, setAlert, handleSave}) {
  return (
    <Modal
      show={state}
      onHide={handleCloseModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>{action === 'create' ? 'Add Currency' : 'Update Currency'}</Modal.Title>
      </Modal.Header>
      <div className="p-4 text-danger">{alert}</div>
      <div className="p-4">
        <Form.Group>
          <Form.Label>Prefix <span className="text-muted">(Rp)</span>*</Form.Label>
          <Form.Control
            type="text"
            name="name"
            {...formik.getFieldProps("name")}
            onChange={(e) => {
              formik.setFieldValue("name", e.target.value);
            }}
            className={validationFormik("name")}
            required
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.name}
              </div>
            </div>
          ) : null}
        </Form.Group>

        <Form.Group>
          <Form.Label>Full Name <span className="text-muted">(Indonesian Rupiah)</span>*</Form.Label>
          <Form.Control
            type="text"
            name="full_name"
            {...formik.getFieldProps("full_name")}
            onChange={(e) => {
              let initial = "";
              const initialEvery = e.target.value.split(" ");
              initialEvery.forEach((item) => (initial += item.slice(0, 1)));

              formik.setFieldValue("full_name", e.target.value);
              formik.setFieldValue("sku", initial);
            }}
            className={validationFormik("full_name")}
            required
          />
          {formik.touched.full_name && formik.errors.full_name ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.full_name}
              </div>
            </div>
          ) : null}
        </Form.Group>
        <div style={{ width: "fit-content", right: 0, marginLeft: "auto" }}>
          <Button
            variant="outline-secondary"
            onClick={() => {
              handleCloseModal();
              setAlert(null);
            }}
            style={{ width: 100 }}
            className="ml-3 mr-3"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            style={{ width: 100 }}
            onClick={handleSave}
            className="ml-3 mr-3"
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  )
}
