import React from "react";
import { Paper } from "@material-ui/core";
import { Row, Col, Form, ListGroup, InputGroup } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Search } from "@material-ui/icons";
import axios from "axios";
import dayjs from "dayjs";
import useDebounce from "../../../hooks/useDebounce";
import ExportExcel from "react-html-table-to-excel";
import { Dropdown } from "react-bootstrap";
import { MoreHoriz } from "@material-ui/icons";
import { useFormik } from "formik";
import * as Yup from "yup";

import AddPayment from "./AddPayment"

const PaymentList = () => {
  const [stateAddModal, setStateAddModal] = React.useState(false);

  const initialAddPayment = {
    payment_name: "",
    payment_period: "",
    payment_amount: ""
  };

  const schemaAddPayment = Yup.object().shape({
    payment_name: Yup.number().required("Please Input Payment Name"),
    payment_period: Yup.number()
      .required("Please Choose Payment Period"),
    payment_amount: Yup.string()
      .min(1, "Minimum 1 Character")
      .required("Please Input Payment Amount"),
  });

  const formikAddPayment = useFormik({
    initialValues: initialAddPayment,
    validationSchema: schemaAddPayment,
    onSubmit: async (values) => {
      const materialData = {
        payment_name: values.payment_name,
        payment_period: values.payment_period,
        payment_amount: values.payment_amount,
      };
      console.log("ini values formikAddPayment", values)
    }
  });

  const columns = [
    {
      name: "Payment Name",
      selector: "payment_name",
      sortable: true
    },
    {
      name: "Payment Period",
      selector: "payment_period",
      sortable: true
    },
    {
      name: "Amount",
      selector: "amount",
      sortable: true
    },
    {
      name: "Action",
      selector: "payment",
      sortable: true
    },
    {
      name: "Action",
      selector: "business_id",
      key: "business_id",
      cell: (val) => {
        return (
          <Dropdown>
            <Dropdown.Toggle variant="light">
              <MoreHoriz />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                as="button"
              >
                Nothing
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      }
    }
  ];
  const closeAddModal = () => {
    setStateAddModal(false);
  };
  const dataUser = [
    {
      payment_name: "Rent",
      payment_period: "Anually",
      amount: "25.000.000",
    },
    {
      payment_name: "Water",
      payment_period: "Monthly",
      amount: "1.000.000",
    },
    {
      payment_name: "Electricity ",
      payment_period: "Monthly",
      amount: "1.000.000",
    }
  ]
  return (
    <div>
      <AddPayment 
        stateModal={stateAddModal}
        cancelModal={closeAddModal}
        title="Add Payment"
        formik={formikAddPayment}
      />
      <Row>
        <Col md={12}>
          <Paper elevation={2} style={{ padding: "1rem", height: "100%" }}>
            <div
              className="headerPage lineBottom d-flex"
              style={{ marginBottom: "1rem" }}
            >
              <div className="headerStart ">
                <h3 className="p-3" style={{ width: "fit-content" }}>
                  Payment
                </h3>
              </div>
              <div
                className="headerEnd"
                style={{ right: 0, marginLeft: "auto" }}
              >
                <ExportExcel
                  className="btn btn-primary font-weight-bolder px-5"
                  table="table-business"
                  sheet="Business-List"
                  buttonText="Export"
                />
                <div className="btn btn-outline-primary ml-4" onClick={() => setStateAddModal(true)}>Add Payment</div>
              </div>
            </div>

            <div className="filterSection lineBottom mt-3">
              <Row>
                <Col>
                  <InputGroup className="pb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text style={{ background: "transparent" }}>
                        <Search />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      placeholder="Search..."
                    />
                  </InputGroup>
                </Col>

                <Col>
                  <Form.Group as={Row}>
                    <Form.Label
                      style={{ alignSelf: "center", marginBottom: "0" }}
                    >
                      Status:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        as="select"
                        name="status"
                      >
                        <option>Active</option>
                        <option>Inactive</option>
                        <option>None</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label
                      style={{ alignSelf: "center", marginBottom: "0" }}
                    >
                      Type:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        as="select"
                        name="type"
                      >
                        <option>Basic</option>
                        <option>Standard</option>
                        <option>Complete</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <DataTable
              noHeader
              pagination
              columns={columns}
              data={dataUser}
              expandableRows
              style={{ minHeight: "fit-content" }}
            />
          </Paper>
        </Col>
      </Row>
    </div>
  );
}

export default PaymentList;
