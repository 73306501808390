import React from "react";
import { Paper, Switch } from "@material-ui/core";
import { Modal, Button } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { InputGroup, FormControl } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import axios from "axios";
import dayjs from "dayjs";
import '../style.css'
import IconDropdown from '../../../images/icons8-down-96.png'

const RevenueSharingModal = ({
  state,
  handleCloseRevenueSharing,
  handleSaveRevenueSharing,
  formikRevenueSharing
}) => {
  return (
    <Modal
      show={state}
      onHide={() => {
        handleCloseRevenueSharing();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Revenue Sharing: </Modal.Title>
      </Modal.Header>
      <div className="p-4 text-danger">{alert}</div>
      <div className="p-4">
        <Row>
          <Col>
            <Form.Group style={{ width: "100%" }}>
              <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                Electricity Cost:
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Electricity Cost . . ."
                {...formikRevenueSharing.getFieldProps("electricity_cost")}

              />
            </Form.Group>
            <Form.Group style={{ width: "100%" }}>
              <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                Water Cost:
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Water Cost . . ."
                {...formikRevenueSharing.getFieldProps("water_cost")}
              />
            </Form.Group>
            <Form.Group style={{ width: "100%" }}>
              <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                Gas Cost:
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Gas Cost . . ."
                {...formikRevenueSharing.getFieldProps("gas_cost")}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group style={{ width: "100%" }}>
              <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
              Electricity Subscription Cost:
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Electricity Subscription Cost . . ."
                {...formikRevenueSharing.getFieldProps("electricity_subscription_cost")}
              />
            </Form.Group>
            <Form.Group style={{ width: "100%" }}>
              <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                Manager Percent Share:
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Manager Percent Share . . ."
                {...formikRevenueSharing.getFieldProps("manager_percent_share")}
              />
            </Form.Group>
            <Form.Group style={{ width: "100%" }}>
              <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                Business Percent Share:
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Business Percent Share . . ."
                {...formikRevenueSharing.getFieldProps("business_percent_share")}
              />
            </Form.Group>
          </Col>
        </Row>
        <div style={{ width: "fit-content", right: 0, marginLeft: "auto" }}>
          <Button
            variant="outline-secondary"
            onClick={() => {
              handleCloseRevenueSharing();
            }}
            style={{ width: 100 }}
            className="ml-3 mr-3"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            style={{ width: 100 }}
            onClick={handleSaveRevenueSharing}
            className="ml-3 mr-3"
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default RevenueSharingModal;
