import React from "react";
import { Paper, Switch } from "@material-ui/core";
import { Modal, Button } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { InputGroup, FormControl } from "react-bootstrap";
import { Row } from "react-bootstrap";
import axios from "axios";
import dayjs from "dayjs";

const CreateSubscriptionModal = ({
  state,
  business_id,
  handleCloseSubsModal,
  formikCreateSubscription,
  handleAddSubscription
}) => {
  const [alert, setAlert] = React.useState(null);
  const [subscriptionType, setSubscriptionType] = React.useState([]);
  const [selectedData, setSelectedData] = React.useState({});
  const [selected, setSelected] = React.useState("");
  const initialSubscriptionType = {
    name: "",
    limit: 0,
    price: 0,
    trial_limit: 0,
    expired_tolerance: 0,
    late_payment_limit: 0
  };
  const getSubscriptionType = async (search, filter) => {
    const API_URL = process.env.REACT_APP_API_URL;
    try {
      const { data } = await axios.get(`${API_URL}/api/v1/subscription-type`);
      setSubscriptionType(data.data);
      if (data.data.length) {
        if (!selected) {
          setSelectedData(data.data[0]);
          setSelected(data.data[0].id);
          const expired_date = dayjs(new Date())
            .add(data.data[0].limit, "day")
            .format("YYYY-MM-DD");
          formikCreateSubscription.setFieldValue("expired_date", expired_date);
        } else {
          const filt = data.data.filter((item) => {
            return item.name === selected;
          });
          setSelectedData(filt[0]);
          setSelected(data.data[0].id);
          const expired_date = dayjs(new Date())
            .add(filt[0].limit, "day")
            .format("YYYY-MM-DD");
          formikCreateSubscription.setFieldValue("expired_date", expired_date);
        }
      } else {
        setSelectedData(initialSubscriptionType);
      }
    } catch (err) {
      setSubscriptionType([]);
    }
  };

  React.useEffect(() => {
    getSubscriptionType();
  }, []);

  const handlePlanSelect = (event) => {
    setSelected(event.target.value);
    const data = subscriptionType.filter((val) => {
      return val.id == event.target.value;
    });
    setSelectedData(...data);
    const expired_date = dayjs(new Date())
      .add(data[0] ? data[0].limit : 0, "day")
      .format("YYYY-MM-DD");
    formikCreateSubscription.setFieldValue("expired_date", expired_date);
    formikCreateSubscription.setFieldValue("subscription_type_id", data[0].id);
  };

  const handleSave = async () => {
    if (!business_id) {
      setAlert("Unable to get Business ID");
    }
    const saveData = {
      business_id,
      subscription_type_id: formikCreateSubscription.getFieldProps(
        "subscription_type_id"
      ).value,
      expired_date: formikCreateSubscription.getFieldProps("expired_date")
        .value,
      status: "inactive"
    };
    // send create request to API
    await handleAddSubscription(saveData);
    handleCloseSubsModal();
  };

  return (
    <Modal
      show={state}
      onHide={() => {
        handleCloseSubsModal();
        setSelected(1);
        setSelectedData(subscriptionType[0]);
        setAlert(null);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Create Subscription: </Modal.Title>
      </Modal.Header>
      <div className="p-4 text-danger">{alert}</div>
      <div className="p-4">
        <Form.Group>
          <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
            Subscription Name:
          </Form.Label>
          <InputGroup style={{ minWidth: 105 }}>
            <Form.Control
              as="select"
              value={selected}
              name="subscription_type"
              onChange={(e) => handlePlanSelect(e)}
            >
              {subscriptionType.map((val, idx) => {
                return (
                  <option key={val.id} value={val.id}>
                    {val.name}
                  </option>
                );
              })}
            </Form.Control>
          </InputGroup>
        </Form.Group>
        <Form.Group style={{ width: "35%" }}>
          <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
            Expiry Date
          </Form.Label>
          <InputGroup style={{ minWidth: 105 }}>
            <FormControl
              type="date"
              aria-label="Expiry Date"
              {...formikCreateSubscription.getFieldProps("expired_date")}
            />
          </InputGroup>
        </Form.Group>
        <div style={{ width: "fit-content", right: 0, marginLeft: "auto" }}>
          <Button
            variant="outline-secondary"
            onClick={() => {
              handleCloseSubsModal();
              setAlert(null);
            }}
            style={{ width: 100 }}
            className="ml-3 mr-3"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            style={{ width: 100 }}
            onClick={handleSave}
            className="ml-3 mr-3"
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateSubscriptionModal;
