import React, { useState, useEffect, useCallback } from "react";
import { Paper } from "@material-ui/core";
import { Row, Col, Form, InputGroup, Button, ListGroup } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Search } from "@material-ui/icons";
import ExportExcel from "react-html-table-to-excel";
import "../style.css";
import axios from "axios";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import Select from "react-select";

const CashlezTransactions = () => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const formatDateForInput = (date) => {
    return date.toISOString().split("T")[0];
  };

  const [transactions, setTransactions] = useState([]);
  const [dateStart, setDateStart] = useState(formatDateForInput(yesterday));
  const [dateEnd, setDateEnd] = useState(formatDateForInput(today));
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [isSyncing, setIsSyncing] = useState(false);
  const authToken = useSelector((state) => state.auth.authToken);
  const [lastSyncTime, setLastSyncTime] = useState(() => {
    const savedTime = localStorage.getItem("lastSyncTime");
    return savedTime ? new Date(savedTime) : null;
  });
  const [businesses, setBusinesses] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [outlets, setOutlets] = useState([]);
  const [selectedOutlet, setSelectedOutlet] = useState(null);

  const columns = [
    {
      name: "Transaction Code",
      selector: "merchant_txid",
      sortable: true
    },
    {
      name: "Amount",
      selector: "transaction_amount",
      sortable: true,
      cell: (row) => formatCurrency(row.transaction_amount)
    },
    {
      name: "Merchant Payment",
      selector: "merchant_payment",
      sortable: true,
      cell: (row) => formatCurrency(row.merchant_payment)
    },
    {
      name: "Fee Merchant",
      selector: "fee_merchant",
      sortable: true,
      cell: (row) => formatCurrency(row.fee_merchant)
    },
    {
      name: "Tax Payment",
      selector: "tax_payment",
      sortable: true,
      cell: (row) => formatCurrency(row.tax_payment)
    },
    {
      name: "Status",
      selector: "trans_status",
      sortable: true
    },
    {
      name: "Type",
      selector: "transaction_type",
      sortable: true,
      width: "120px"
    },
    {
      name: "Created Date",
      selector: "created_date",
      sortable: true,
      width: "150px",
      cell: (row) => formatDate(row.created_date)
    },
    {
      name: "Settlement Date",
      selector: "settlement_date",
      sortable: true,
      width: "150px",
      cell: (row) =>
        row.settlement_date ? formatDate(row.settlement_date) : "N/A"
    }
  ];

  const ExpandableComponent = ({ data }) => {
    const [activeTab, setActiveTab] = useState("receipt");

    const tabContainerStyle = {
      display: "flex",
      gap: "10px",
      marginBottom: "10px"
    };

    const tabStyle = {
      cursor: "pointer",
      padding: "10px 20px",
      border: "1px solid #dee2e6",
      borderBottom: "none",
      backgroundColor: "#f8f9fa",
      borderRadius: "5px 5px 0 0",
      transition: "all 0.3s ease"
    };

    const activeTabStyle = {
      ...tabStyle,
      backgroundColor: "#fff",
      borderBottom: "1px solid #fff"
    };

    const contentStyle = {
      border: "1px solid #dee2e6",
      padding: "15px",
      backgroundColor: "#fff",
      borderRadius: "0 5px 5px 5px"
    };

    const tableStyle = {
      width: "100%",
      borderCollapse: "collapse"
    };

    const cellStyle = {
      border: "1px solid #dee2e6",
      padding: "8px",
      width: "25%"
    };

    const renderReceiptDetail = () => (
      <table style={{ ...tableStyle, ...contentStyle }}>
        <tbody>
          <tr>
            <td style={cellStyle}>
              <strong>Bank:</strong> {data.bank_name}
            </td>
            <td style={cellStyle}>
              <strong>DBA:</strong> {data.company_name}
            </td>
            <td style={cellStyle}>
              <strong>Merchant:</strong> {data.merchant_name}
            </td>
            <td style={cellStyle}>
              <strong>Retrieval Number:</strong>{" "}
              {data.retrieval_reference_number}
            </td>
          </tr>
          <tr>
            <td style={cellStyle}>
              <strong>Merchant Reference Code:</strong>{" "}
              {data.merchant_reference}
            </td>
            <td style={cellStyle}>
              <strong>Transaction ID:</strong> {data.merchant_txid}
            </td>
            <td style={cellStyle}>
              <strong>Va Number:</strong> {data.number_va || "-"}
            </td>
            <td style={cellStyle}>
              <strong>Transaction Type:</strong> {data.transaction_type}
            </td>
          </tr>
          <tr>
            <td style={cellStyle} colSpan="2">
              <strong>Description:</strong> {data.item_desc}
            </td>
            <td style={cellStyle}>
              <strong>Merchant Transaction ID:</strong>{" "}
              {data.transaction_authorized_id}
            </td>
            <td style={cellStyle}>
              <strong>Total Transaction Amount:</strong>{" "}
              {formatCurrency(data.transaction_amount)}
            </td>
          </tr>
          <tr>
            <td style={cellStyle}>
              <strong>Merchant Payment:</strong>{" "}
              {formatCurrency(data.merchant_payment)}
            </td>
            <td style={cellStyle}>
              <strong>Tax Payment:</strong> {formatCurrency(data.tax_payment)}
            </td>
            <td style={cellStyle}>
              <strong>Status:</strong> {data.trans_status}
            </td>
            <td style={cellStyle}>
              <strong>Transaction Date:</strong> {formatDate(data.created_date)}
            </td>
          </tr>
          <tr>
            <td style={cellStyle}>
              <strong>Settlement Date:</strong>{" "}
              {data.settlement_date ? formatDate(data.settlement_date) : "-"}
            </td>
            <td style={cellStyle}>
              <strong>Transaction Via Cashlez Link:</strong>{" "}
              {data.transaction_from_cashlez_link}
            </td>
            <td style={cellStyle}>
              <strong>City:</strong> {data.city}
            </td>
            <td style={cellStyle}>
              <strong>State:</strong> {data.state}
            </td>
          </tr>
          <tr>
            <td style={cellStyle}>
              <strong>Latitude:</strong> {data.latitude}
            </td>
            <td style={cellStyle}>
              <strong>Longitude:</strong> {data.longitude}
            </td>
            <td style={cellStyle} colSpan="2"></td>
          </tr>
        </tbody>
      </table>
    );

    const renderCustomer = () => (
      <table style={{ ...tableStyle, ...contentStyle }}>
        <tbody>
          <tr>
            <td style={cellStyle}>
              <strong>Card Holder Name:</strong> {data.card_holder_name || "-"}
            </td>
            <td style={cellStyle}>
              <strong>Card Type:</strong> {data.card_type}
            </td>
            <td style={cellStyle}>
              <strong>Application Label:</strong> {data.application_label}
            </td>
            <td style={cellStyle}>
              <strong>Primary Account No. (PAN):</strong> {data.pan || "-"}
            </td>
          </tr>
        </tbody>
      </table>
    );

    return (
      <div style={{ padding: "1rem", marginLeft: "1rem" }}>
        <div style={tabContainerStyle}>
          <button
            style={activeTab === "receipt" ? activeTabStyle : tabStyle}
            onClick={() => setActiveTab("receipt")}
            className="tab-button"
          >
            RECEIPT DETAIL
          </button>
          <button
            style={activeTab === "customer" ? activeTabStyle : tabStyle}
            onClick={() => setActiveTab("customer")}
            className="tab-button"
          >
            CUSTOMER
          </button>
        </div>
        {activeTab === "receipt" && renderReceiptDetail()}
        {activeTab === "customer" && renderCustomer()}
      </div>
    );
  };

  const formatTransactionsForExcel = () => {
    return transactions.map((transaction) => ({
      "Transaction Code": transaction.merchant_txid,
      Amount: formatCurrency(transaction.transaction_amount),
      "Tax Payment": formatCurrency(transaction.tax_payment),
      "Fee Merchant": formatCurrency(transaction.fee_merchant),
      "Merchant Payment": formatCurrency(transaction.merchant_payment),
      Status: transaction.trans_status,
      Type: transaction.transaction_type,
      "Created Date": formatDate(transaction.created_date),
      "Settlement Date": transaction.settlement_date
        ? formatDate(transaction.settlement_date)
        : "N/A"
    }));
  };

  const fetchTransactions = useCallback(async (page, rowsPerPage, search) => {
    setIsLoading(true);
    
    if (!selectedBusiness || !dateStart || !dateEnd) {
      setTransactions([]);
      setTotalRows(0);
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.get(
        `https://api.beetpos.com/api/v1/cz-transaction`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          },
          params: {
            per_page: rowsPerPage,
            page: page,
            search: search,
            business_id: selectedBusiness.value,
            outlet_id: selectedOutlet?.value || "",
            dateStart: dateStart,
            dateEnd: dateEnd
          }
        }
      );

      if (response.data && response.data.data && response.data.data.length > 0) {
        const startDate = new Date(dateStart);
        const endDate = new Date(dateEnd);
        endDate.setHours(23, 59, 59, 999);

        const filteredData = response.data.data.filter(item => {
          const itemDate = new Date(item.created_date);
          const matchesSearch = search
            ? item.merchant_txid.toLowerCase().includes(search.toLowerCase())
            : true;
          return (
            item.business_id === selectedBusiness.value &&
            (selectedOutlet?.value ? item.outlet_id === selectedOutlet.value : true) &&
            itemDate >= startDate && itemDate <= endDate &&
            matchesSearch
          );
        });
        
        setTransactions(filteredData);
        setTotalRows(filteredData.length);
      } else {
        setTransactions([]);
        setTotalRows(0);
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
      setTransactions([]);
      setTotalRows(0);
    } finally {
      setIsLoading(false);
    }
  }, [selectedBusiness, selectedOutlet, dateStart, dateEnd, authToken]);

  useEffect(() => {
    fetchBusinesses();
  }, []);

  useEffect(() => {
    if (selectedBusiness) {
      fetchTransactions(currentPage, perPage, searchTerm);
    }
  }, [fetchTransactions, currentPage, perPage, searchTerm, selectedBusiness]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === "dateStart") {
      setDateStart(value);
    } else if (name === "dateEnd") {
      setDateEnd(value);
    }
    setCurrentPage(1);
  };

  const syncData = useCallback(async () => {
    if (!authToken || !selectedBusiness) {
      console.error("Sinkronisasi gagal: Informasi bisnis atau token tidak tersedia");
      Swal.fire({
        icon: "error",
        title: "Sinkronisasi Gagal",
        text: "Silakan pilih bisnis terlebih dahulu."
      });
      return;
    }

    setIsSyncing(true);
    try {
      const response = await axios.post(
        "https://api.beetpos.com/api/v1/cz-transaction/get-data",
        {
          order: "transaction_authorized.id",
          sort: "desc",
          limit: 100,
          page: 1,
          status: "settled",
          created_date_from: dateStart,
          created_date_to: dateEnd,
          business_id: selectedBusiness.value,
          outlet_id: selectedOutlet?.value || ""
        },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );

      if (response.data.statusCode === 200) {
        Swal.fire({
          icon: "success",
          title: "Sinkronisasi Berhasil",
          text: "Data telah berhasil disinkronkan.",
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false
        });

        await fetchTransactions(1, perPage, "");
      } else {
        throw new Error(`Respons API tidak sukses: ${response.data.statusCode}`);
      }
    } catch (error) {
      console.error("Error saat sinkronisasi data:", error);
      Swal.fire({
        icon: "error",
        title: "Sinkronisasi Gagal",
        text: `Terjadi kesalahan saat menyinkronkan data: ${error.message}`
      });
    } finally {
      setIsSyncing(false);
    }
  }, [authToken, dateStart, dateEnd, selectedBusiness, selectedOutlet, perPage]);

  const handleManualSync = () => {
    syncData();
  };

  const handleOutletChange = (selectedOption) => {
    setSelectedOutlet(selectedOption);
    setCurrentPage(1);
  };

  const fetchOutlets = async (businessId) => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/business/${businessId}`);
      const outletOptions = data.data.Outlets.map(outlet => ({
        value: outlet.id,
        label: outlet.name
      }));
      setOutlets([{ value: "", label: "Semua Outlet" }, ...outletOptions]);
    } catch (error) {
      console.error("Error fetching outlets:", error);
    }
  };

  const handleBusinessChange = (selectedOption) => {
    setSelectedBusiness(selectedOption);
    setSelectedOutlet(null);
    if (selectedOption) {
      fetchOutlets(selectedOption.value);
    } else {
      setOutlets([]);
    }
  };

  const fetchBusinesses = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/business`, {
        headers: { Authorization: `Bearer ${authToken}` }
      });
      const businessOptions = response.data.data.map(business => ({
        value: business.id,
        label: business.name
      }));
      setBusinesses(businessOptions);
    } catch (error) {
      console.error("Error fetching businesses:", error);
    }
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR"
    }).format(amount);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date
      .getDate()
      .toString()
      .padStart(2, "0");
    const month = getIndonesianMonth(date.getMonth());
    const year = date.getFullYear();
    const time = date.toLocaleTimeString("id-ID", {
      hour: "2-digit",
      minute: "2-digit"
    });

    return `${day} ${month} ${year}, ${time}`;
  };

  const getIndonesianMonth = (monthIndex) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Mei",
      "Jun",
      "Jul",
      "Agu",
      "Sep",
      "Okt",
      "Nov",
      "Des"
    ];
    return months[monthIndex];
  };

  return (
    <div>
      <Row>
        <Col md={12}>
          <Paper elevation={2} style={{ padding: "1rem", height: "100%" }}>
            <div
              className="headerPage lineBottom d-flex"
              style={{ marginBottom: "1rem" }}
            >
              <div className="headerStart">
                <h3 className="p-3" style={{ width: "fit-content" }}>
                  Cashlez Transaction Dashboard
                </h3>
              </div>
              <div
                className="headerEnd"
                style={{ marginLeft: "auto", width: "15%", maxWidth: "200px" }}
              >
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <Button
                    variant="outline-primary"
                    size="md"
                    onClick={handleManualSync}
                    disabled={isSyncing}
                  >
                    {isSyncing ? "Wait..." : "Sinkron"}
                  </Button>
                  <ExportExcel
                    id="test-table-xls-button"
                    className="btn btn-success"
                    table="table-to-xls"
                    filename="cashlez_transactions"
                    sheet="transactions"
                    buttonText="Export"
                  />
                </div>
              </div>
            </div>

            <div className="filterSection lineBottom mt-3">
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Business:</Form.Label>
                    <Select
                      value={selectedBusiness}
                      onChange={handleBusinessChange}
                      options={businesses}
                      isClearable
                      isSearchable
                      placeholder="Select Business"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Outlet:</Form.Label>
                    <Select
                      value={selectedOutlet}
                      onChange={handleOutletChange}
                      options={outlets}
                      isClearable
                      isSearchable
                      placeholder="Select Outlet"
                      isDisabled={!selectedBusiness}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Search by Transaction Code:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter transaction code"
                      value={searchTerm}
                      onChange={handleSearch}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label
                      style={{ alignSelf: "center", marginBottom: "0" }}
                    >
                      Start Date:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="date"
                        name="dateStart"
                        value={dateStart}
                        onChange={handleDateChange}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label
                      style={{ alignSelf: "center", marginBottom: "0" }}
                    >
                      End Date:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="date"
                        name="dateEnd"
                        value={dateEnd}
                        onChange={handleDateChange}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <table id="table-to-xls" style={{ display: "none" }}>
              <thead>
                <tr>
                  <th>Transaction Code</th>
                  <th>Amount</th>
                  <th>Tax Payment</th>
                  <th>Fee Merchant</th>
                  <th>Merchant Payment</th>
                  <th>Status</th>
                  <th>Type</th>
                  <th>Created Date</th>
                  <th>Settlement Date</th>
                </tr>
              </thead>
              <tbody>
                {formatTransactionsForExcel().map((transaction, index) => (
                  <tr key={index}>
                    <td>{transaction["Transaction Code"]}</td>
                    <td>{transaction["Amount"]}</td>
                    <td>{transaction["Tax Payment"]}</td>
                    <td>{transaction["Fee Merchant"]}</td>
                    <td>{transaction["Merchant Payment"]}</td>
                    <td>{transaction["Status"]}</td>
                    <td>{transaction["Type"]}</td>
                    <td>{transaction["Created Date"]}</td>
                    <td>{transaction["Settlement Date"]}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            {isLoading ? (
              <div className="loading">Loading transactions...</div>
            ) : transactions.length > 0 ? (
              <DataTable
                noHeader
                pagination
                paginationServer
                columns={columns}
                data={transactions}
                progressPending={isLoading}
                progressComponent={
                  <div className="loading">Loading transactions...</div>
                }
                paginationTotalRows={totalRows}
                paginationPerPage={perPage}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                expandableRows
                expandableRowsComponent={<ExpandableComponent/>}
                style={{ minHeight: "fit-content" }}
              />
            ) : (
              <div>
                Tidak ada data transaksi yang ditampilkan. Klik 'Sinkron Manual'
                untuk mengambil data.
              </div>
            )}

            <div className="d-flex justify-content-start">
              <Button
                variant="outline-primary"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1 || isLoading}
                style={{ marginRight: "10px" }}
              >
                Previous
              </Button>
              <Button
                variant="outline-primary"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === 100 || isLoading}
              >
                Next
              </Button>
            </div>
          </Paper>
        </Col>
      </Row>
    </div>
  );
};

export default CashlezTransactions;