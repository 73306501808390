import React from "react";
import { Paper, Switch } from "@material-ui/core";
import { Modal, Button } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { InputGroup, FormControl } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import axios from "axios";
import dayjs from "dayjs";
import '../style.css'
import IconDropdown from '../../../images/icons8-down-96.png'

const UpdateRevenueModal = ({
  state,
  handleCloseRevenueSharing,
  handleSaveRevenueSharing,
  formikRevenueSharing,
  alert,
  handlePercentSharing,
  percentSharing
}) => {
  return (
    <Modal
      show={state}
      onHide={() => {
        handleCloseRevenueSharing();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Revenue Sharing: </Modal.Title>
      </Modal.Header>
      <div className="p-4 text-danger">{alert}</div>
      <div className="p-4">
        <Row>
          <Col>
            <Form.Group style={{ width: "100%" }}>
              <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                Management Percent Share:
              </Form.Label>
              <Form.Control
                type="number"
                name="management"
                placeholder="Management Percent Share . . ."
                // {...formikRevenueSharing.getFieldProps("manager_percent_share")}
                value={percentSharing.management}
                onChange={(e) => handlePercentSharing(e)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group style={{ width: "100%" }}>
              <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                Tenant Percent Share:
              </Form.Label>
              <Form.Control
                type="number"
                name="tenant"
                placeholder="Tenant Percent Share . . ."
                // {...formikRevenueSharing.getFieldProps("business_percent_share")}
                value={percentSharing.tenant}
                onChange={(e) => handlePercentSharing(e)}
              />
            </Form.Group>
          </Col> 
        </Row>
        <div style={{ width: "fit-content", right: 0, marginLeft: "auto" }}>
          <Button
            variant="outline-secondary"
            onClick={() => {
              handleCloseRevenueSharing();
            }}
            style={{ width: 100 }}
            className="ml-3 mr-3"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            style={{ width: 100 }}
            onClick={handleSaveRevenueSharing}
            className="ml-3 mr-3"
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateRevenueModal;
