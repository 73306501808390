import React from 'react'
import {
  Row,
  Col,
  Modal,
  Form,
  Button
} from 'react-bootstrap'
export default function BeetstoreModal({
  state,
  alert,
  close,
  formikBeetstore,
  setAlert,
  handleSaveBeetstore
}) {
  return (
    <div>
      <Modal
        show={state}
        onHide={() => {
          close();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Beetstore Modal: </Modal.Title>
        </Modal.Header>
        <div className="p-4 text-danger">{alert}</div>
        <div className="p-4">
          <Row>
            <Col>
              <Form.Group style={{ width: "35%" }}>
                <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                  Status Integrate BeetStore
                </Form.Label>
                <Form.Check
                  type="checkbox"
                  label="Active"
                  checked={formikBeetstore.getFieldProps("status_integrate_beetstore").value}
                  {...formikBeetstore.getFieldProps("status_integrate_beetstore")}
                />
              </Form.Group>
              <div style={{ width: "fit-content", right: 0, marginLeft: "auto" }}>
                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    close();
                    setAlert(null);
                  }}
                  style={{ width: 100 }}
                  className="ml-3 mr-3"
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  style={{ width: 100 }}
                  onClick={handleSaveBeetstore}
                  className="ml-3 mr-3"
                >
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  )
}
