import React from "react";
import { Paper } from "@material-ui/core";
import { Row, Col, Form, ListGroup, InputGroup } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Search } from "@material-ui/icons";
import axios from "axios";
import dayjs from "dayjs";
import useDebounce from "../../hooks/useDebounce";
import ExportExcel from "react-html-table-to-excel";
import { Dropdown } from "react-bootstrap";
import { MoreHoriz } from "@material-ui/icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import ConfirmModal from '../../components/ConfirmModal'
import '../style.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalConfirm from '../../components/ConfirmModal'
import Modal from './ModalCreate'

toast.configure()

export function CurrencyPage() {
  const [search, setSearch] = React.useState("");
  const debouncedSearch = useDebounce(search, 1000);
  const [filter, setFilter] = React.useState({
    status: "all",
    type: "all"
  });
  const [users, setUsers] = React.useState([]);
  const [availableFilter, setAvailableFilter] = React.useState({
    status: ["All"],
    type: ["All"]
  });
  const [dateLastGetData, setDateLastGetData] = React.useState(null);
  const [refresh, setRefresh] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [alert, setAlert] = React.useState(null);
  const [showCreateCurrencyModal, setShowCreateCurrencyModal] = React.useState(false);
  const [showUpdateCurrencyModal, setShowUpdateCurrencyModal] = React.useState(false);
  const [stateDeleteModal, setStateDeleteModal] = React.useState(false)
  const [selectCurrency, setSelectCurrency] = React.useState({})

  const [currency, setCurency] = React.useState([])

  const enableLoading = () => setLoading(true);
  const disableLoading = () => setLoading(false);

  const handleRefresh = () => setRefresh(refresh + 1);
  const [selectedBusinessId, setSelectedBusinessId] = React.useState(null);

  const handleOpenCreateModal = () => setShowCreateCurrencyModal(true);
  const handleCloseCreateModal = () => setShowCreateCurrencyModal(false);

  const handleOpenUpdateModal = () => setShowUpdateCurrencyModal(true);
  const handleCloseUpdateModal = () => setShowUpdateCurrencyModal(false);

  const handleSearch = (e) => setSearch(e.target.value);
  const handleFilter = (e) => {
    const { name, value } = e.target;
    const filterData = { ...filter };
    filterData[name] = value;
    setFilter(filterData);
  };

  const closeDeleteModal = () => setStateDeleteModal(false);
  const showDeleteModal = () => setStateDeleteModal(true)

  const getUsers = async (search, filter) => {
    const API_URL = process.env.REACT_APP_API_URL;
    let filterUser = "?";
    if (search) filterUser += `name=${search}`;
    // if (filter.status !== "all") filterUser += `&status=${filter.status}`;
    if (filter.type !== "all") filterUser += `&type=${filter.type}`;
    filterUser = filterUser === "?" ? "" : filterUser;
    try {
      const { data } = await axios.get(
        `${API_URL}/api/v1/business${filterUser}`
      );
      console.log("semua business", data.data)
      // filter user by Currency status
      let filtered;
      if (filter.status.toLowerCase() === "none") {
        filtered = data.data.filter((item) => item.Currency === null);
      } else if (filter.status !== "all") {
        filtered = data.data.filter(
          (item) =>
            item.Currency?.status.toLowerCase() ===
            filter.status.toLowerCase()
        );
      } else {
        filtered = data.data;
      }
      setUsers(filtered);
    } catch (err) {
      setUsers([]);
    }
  };

  const getCurrency = async () => {
    const API_URL = process.env.REACT_APP_API_URL;
    try {
      const {data} = await axios.get(`${API_URL}/api/v1/currency`)
      setCurency(data.data)
    } catch (error) {
      console.log(error)
    }
  }

  React.useEffect(() => {
    getCurrency(debouncedSearch, filter)
    getUsers(debouncedSearch, filter);
    setDateLastGetData(new Date());
  }, [filter, debouncedSearch, refresh]);


  const initialCurrency = {
    name: "",
    full_name: ""
  };

  const handleUpdateCurrency = (data) => {
    formikUpdateCurrency.setValues({
      id: data.id,
      name: data.name,
      full_name:data.full_name
    });
    handleOpenUpdateModal()
  }

  const initialUpdateCurrency = {
    name: "",
    full_name: ""
  };

  const CreateCurrencySchema = Yup.object().shape({
    name: Yup.string()
      .required(),
    full_name: Yup.string()
      .required()
  });

  const UpdateCurrencySchema = Yup.object().shape({
    name: Yup.string()
      .required(),
    full_name: Yup.string()
      .required()
  });

  const formikCreateCurrency = useFormik({
    enableReinitialize: true,
    initialValues: initialCurrency,
    validationSchema: CreateCurrencySchema,
    onSubmit: async (values, {resetForm}) => {
      const CurrencyData = {
        name: values.name,
        full_name: values.full_name
      };

      console.log("CurrencyData", CurrencyData)

      const API_URL = process.env.REACT_APP_API_URL;
      try {
        enableLoading();
        await axios.post(`${API_URL}/api/v1/currency/`, CurrencyData);
        resetForm()
        handleRefresh();
        disableLoading();
        handleCloseCreateModal();
      } catch (err) {
        setAlert(err.response?.data.message || err.message);
        disableLoading();
      }
    }
  });

  const formikUpdateCurrency = useFormik({
    enableReinitialize: true,
    initialValues: initialUpdateCurrency,
    validationSchema: UpdateCurrencySchema,
    onSubmit: async (values, {resetForm}) => {
      const CurrencyData = {
        name: values.name,
        full_name: values.full_name
      };

      const API_URL = process.env.REACT_APP_API_URL;
      try {
        enableLoading();
        await axios.patch(`${API_URL}/api/v1/currency/${values.id}`, CurrencyData);
        resetForm()
        handleRefresh();
        disableLoading();
        handleCloseUpdateModal();
      } catch (err) {
        setAlert(err.response?.data.message || err.message);
        disableLoading();
      }
    }
  });

  const validationCreateCurrency = (fieldname) => {
    if (
      formikCreateCurrency.touched[fieldname] &&
      formikCreateCurrency.errors[fieldname]
    ) {
      return "is-invalid";
    }

    if (
      formikCreateCurrency.touched[fieldname] &&
      !formikCreateCurrency.errors[fieldname]
    ) {
      return "is-valid";
    }

    return "";
  };

  const validationUpdateCurrency = (fieldname) => {
    if (
      formikCreateCurrency.touched[fieldname] &&
      formikCreateCurrency.errors[fieldname]
    ) {
      return "is-invalid";
    }

    if (
      formikCreateCurrency.touched[fieldname] &&
      !formikCreateCurrency.errors[fieldname]
    ) {
      return "is-valid";
    }

    return "";
  };

  const columns = [
    {
      name: "No.",
      selector: "no",
      sortable: true,
      width: "50px"
    },
    {
      name: "Name",
      selector: "name",
      sortable: true
    },
    {
      name: "Fullname",
      selector: "full_name",
      sortable: true
    },
    {
      name: "Created At",
      selector: "created_at",
      sortable: true
    },
    {
      name: "Updated At",
      selector: "updated_at",
      sortable: true
    },
    {
      name: "Action",
      selector: "business_id",
      key: "business_id",
      cell: (val) => {
        return (
          <Dropdown>
            <Dropdown.Toggle variant="light">
              <MoreHoriz />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                as="button"
                onClick={() => {
                  handleUpdateCurrency(val)
                }}
              >
                Update
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                onClick={() => {
                  showDeleteModal()
                  setSelectCurrency(val)
                }}
              >
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      }
    }
  ];

  const dataCurrency = currency.map((item, index) => {
    console.log("item.updated_at", item)
    const created_at = dayjs(item.createdAt).format("DD/MM/YYYY");
    const updated_at = item.updatedAt
      ? dayjs(item.updated_at).format("DD/MM/YYYY")
      : "-";

    return {
      id: item.id,
      no: index + 1,
      name: item.name,
      created_at,
      updated_at,
      full_name: item.full_name
    }
  })

  const handleDeleteCurrency = async () => {
    const API_URL = process.env.REACT_APP_API_URL;
    try {
      await axios.delete(`${API_URL}/api/v1/currency/${selectCurrency.id}`)
      toast.success("Delete Currency Successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      handleRefresh();
      closeDeleteModal()
    } catch (error) {
      toast.error(error.response?.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log("Error Message", error.response?.data.message)
      setAlert(error.response?.data.message || error.message);
    }
  }

  const handleSave = async () => await formikCreateCurrency.submitForm()
  const handleUpdate = async () => await formikUpdateCurrency.submitForm()

  return (
    <>
      <ModalConfirm
        title={`Delete Currency - ${selectCurrency.full_name}`}
        body="Are you sure delete this Currency"
        buttonColor="danger"
        state={stateDeleteModal}
        closeModal={closeDeleteModal}
        handleClick={() => handleDeleteCurrency()}
        loading={loading}
      />

      <Modal
        action="create"
        state={showCreateCurrencyModal}
        handleCloseModal={handleCloseCreateModal}
        alert={alert}
        formik={formikCreateCurrency}
        validationFormik={validationCreateCurrency}
        setAlert={setAlert}
        handleSave={handleSave}
      />

      <Modal
        action="update"
        state={showUpdateCurrencyModal}
        handleCloseModal={handleCloseUpdateModal}
        alert={alert}
        formik={formikUpdateCurrency}
        validationFormik={validationUpdateCurrency}
        setAlert={setAlert}
        handleSave={handleUpdate}
      />

      {/* Add Modal here */}
      <Row>
        <Col md={12}>
          <Paper elevation={2} style={{ padding: "1rem", height: "100%" }}>
            <div
              className="headerPage lineBottom d-flex"
              style={{ marginBottom: "1rem" }}
            >
              <div className="headerStart ">
                <h3 className="p-3" style={{ width: "fit-content" }}>
                  User Data
                  <small className="pl-3">BeetPOS Customer</small>
                </h3>
              </div>
              <div
                className="headerEnd"
                style={{ right: 0, marginLeft: "auto" }}
              >
                <div className="btn btn-primary" onClick={handleOpenCreateModal}>Add Currency</div>
              </div>
            </div>

            <div className="filterSection lineBottom mt-3">
              <Row>
                <Col>
                  <InputGroup className="pb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text style={{ background: "transparent" }}>
                        <Search />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      placeholder="Search..."
                      value={search}
                      onChange={handleSearch}
                    />
                  </InputGroup>
                </Col>

                <Col>
                  <Form.Group as={Row}>
                    <Form.Label
                      style={{ alignSelf: "center", marginBottom: "0" }}
                    >
                      Status:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        as="select"
                        name="status"
                        value={filter.status}
                        onChange={handleFilter}
                      >
                        {availableFilter.status.map((value, idx) => {
                          if (value)
                            return (
                              <option value={value.toLowerCase()} key={value}>
                                {value.charAt(0).toUpperCase() + value.slice(1)}
                              </option>
                            );
                        })}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label
                      style={{ alignSelf: "center", marginBottom: "0" }}
                    >
                      Type:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        as="select"
                        name="type"
                        value={filter.type}
                        onChange={handleFilter}
                      >
                        {availableFilter.type.map((value, idx) => {
                          if (value)
                            return (
                              <option value={value.toLowerCase()} key={value}>
                                {value.charAt(0).toUpperCase() + value.slice(1)}
                              </option>
                            );
                        })}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <DataTable
              noHeader
              pagination
              columns={columns}
              data={dataCurrency}
              style={{ minHeight: "fit-content" }}
            />
          </Paper>
        </Col>
      </Row>
    </>
  );
}
