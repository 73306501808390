import React from "react";
import { Paper } from "@material-ui/core";
import { Row, Col, Form, ListGroup, InputGroup } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Search } from "@material-ui/icons";
import axios from "axios";
import dayjs from "dayjs";
import useDebounce from "../../hooks/useDebounce";
import convertRupiah from "rupiah-format";

export function TransactionPage() {
  const [search, setSearch] = React.useState("");
  const debouncedSearch = useDebounce(search, 1000);
  const [filter, setFilter] = React.useState({
    status: "all",
    type: "all"
  });
  const [transactions, setTransactions] = React.useState([]);
  const [availableFilter, setAvailableFilter] = React.useState({
    status: ["All"],
    type: ["All"]
  });

  const handleSearch = (e) => setSearch(e.target.value);
  const handleFilter = (e) => {
    const { name, value } = e.target;
    const filterData = { ...filter };
    filterData[name] = value;
    setFilter(filterData);
  };

  const getTransactions = async (search, filter) => {
    const API_URL = process.env.REACT_APP_API_URL;
    let filterTrans = "?";
    if (search) filterTrans += `name=${search}`;
    if (filter.status !== "all")
      filterTrans += `&status=${filter.status.toLowerCase()}`;
    if (filter.type !== "all")
      filterTrans += `&type=${filter.type.toLowerCase()}`;
    filterTrans = filterTrans === "?" ? "" : filterTrans;
    try {
      const { data } = await axios.get(
        `${API_URL}/api/v1/subscription-payment${filterTrans}`
      );
      setTransactions(data.data);
    } catch (err) {
      setTransactions([]);
    }
  };
  React.useEffect(() => {
    getTransactions(debouncedSearch, filter);
  }, [filter, debouncedSearch]);

  const columns = [
    {
      name: "No.",
      selector: "no",
      sortable: true,
      width: "50px"
    },
    {
      name: "Invoice ID",
      selector: "invoice",
      sortable: true
    },
    {
      name: "Username",
      selector: "username",
      sortable: true
    },
    {
      name: "Expired Date",
      selector: "expired_date",
      sortable: true
    },
    {
      name: "Amount",
      selector: "amount",
      sortable: true
    },
    {
      name: "Subscription",
      selector: "subscription_type",
      sortable: true
    }
  ];
  let dataTransaction = transactions.map((item, index) => {
    if (!availableFilter.status.includes(item.Subscription.status)) {
      availableFilter.status.push(item.Subscription.status);
    }
    if (
      !availableFilter.type.includes(item.Subscription.Subscription_Type.name)
    ) {
      availableFilter.type.push(item.Subscription.Subscription_Type.name);
    }

    return {
      no: index + 1,
      invoice: item.invoice,
      username: item.Subscription.Business.name,
      expired_date: dayjs(item.Subscription.expired_date).format("DD/MM/YYYY"),
      amount: convertRupiah.convert(item.amount),
      subscription_type: item.Subscription.Subscription_Type.name,
      outlets: item.Subscription.Business.Outlets
    };
  });

  const ExpandableComponent = ({ data }) => {
    let keys = [];
    data.outlets.forEach((val, idx) => {
      keys.push({
        key: idx,
        value: {
          name: val.name,
          location: val.Location.name
        }
      });
    });
    return (
      <>
        <ListGroup style={{ padding: "1rem", marginLeft: "1rem" }}>
          <ListGroup.Item key="head">
            <Row className="lineBottom">
              <Col md={3} style={{ fontWeight: "700" }}>
                Outlet Name
              </Col>
              <Col md={3} style={{ fontWeight: "700" }}>
                Location
              </Col>
            </Row>
          </ListGroup.Item>
          {keys.map((val, index) => {
            return (
              <ListGroup.Item key={index}>
                <Row>
                  <Col md={3}>{val.value.name || "-"}</Col>
                  <Col md={3}>{val.value.location || "-"}</Col>
                </Row>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </>
    );
  };

  return (
    <>
      <Row>
        <Col md={12}>
          <Paper elevation={2} style={{ padding: "1rem", height: "100%" }}>
            <div
              className="headerPage lineBottom d-flex"
              style={{ marginBottom: "1rem" }}
            >
              <div className="headerStart ">
                <h3 className="p-3" style={{ width: "fit-content" }}>
                  Subscription Payment - Report
                </h3>
              </div>
              <div
                className="headerEnd"
                style={{ right: 0, marginLeft: "auto" }}
              ></div>
            </div>

            <div className="filterSection lineBottom mt-3">
              <Row>
                <Col>
                  <InputGroup className="pb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text style={{ background: "transparent" }}>
                        <Search />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      placeholder="Search..."
                      value={search}
                      onChange={handleSearch}
                    />
                  </InputGroup>
                </Col>

                <Col>
                  <Form.Group as={Row}>
                    <Form.Label
                      style={{ alignSelf: "center", marginBottom: "0" }}
                    >
                      Status:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        as="select"
                        name="status"
                        value={filter.status}
                        onChange={handleFilter}
                      >
                        {availableFilter.status.map((value, idx) => {
                          return (
                            <option value={value.toLowerCase()} key={value}>
                              {value.charAt(0).toUpperCase() + value.slice(1)}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label
                      style={{ alignSelf: "center", marginBottom: "0" }}
                    >
                      Type:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        as="select"
                        name="type"
                        value={filter.type}
                        onChange={handleFilter}
                      >
                        {availableFilter.type.map((value, idx) => {
                          return (
                            <option value={value.toLowerCase()} key={value}>
                              {value.charAt(0).toUpperCase() + value.slice(1)}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <DataTable
              noHeader
              pagination
              columns={columns}
              data={dataTransaction}
              expandableRows
              expandableRowsComponent={<ExpandableComponent />}
              style={{ minHeight: "fit-content" }}
            />
          </Paper>
        </Col>
      </Row>
    </>
  );
}
