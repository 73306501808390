import React from 'react'
import { 
  Switch,
  FormGroup,
  FormControl,
  FormControlLabel,
  Paper 
} from "@material-ui/core";
import { Row, Col, Form, ListGroup, InputGroup } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Search } from "@material-ui/icons";
import axios from "axios";
import dayjs from "dayjs";
import useDebounce from "../../hooks/useDebounce";
import ExportExcel from "react-html-table-to-excel";
import { Dropdown } from "react-bootstrap";
import { MoreHoriz } from "@material-ui/icons";
import { useFormik } from "formik";
import * as Yup from "yup";
// import RevenueSharing from './RevenueSharingModal'
import ConfirmModal from '../../components/ConfirmModal'
import '../style.css'
import { Link } from 'react-router-dom'
import fileDownload from 'js-file-download'

import NumberFormat from 'react-number-format'

import UpdateModal from './UpdateModal';
import CreateModal from './CreateModal'
import UpdateRevenueModal from './UpdateRevenueModal'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure()

export default function RevenueSharingPage() {
  const [stateUpdateStatus, setStateUpdateStatus] = React.useState(false)
  const [stateDeleteUtilityPayment, setStateDeleteUtilityPayment] = React.useState(false)
  
  const [dataStatus, setDataStatus] = React.useState({
    id: null,
    status: ""
  })

  const [dataUpdateRevenueSharing, setDataUpdateRevenueSharing] = React.useState({})
  const [tenantUtilityPaymentId, setTenantUtilityPaymentId] = React.useState(null)

  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const debouncedSearch = useDebounce(search, 1000);
  const [filter, setFilter] = React.useState({
    status: "all",
    type: "all"
  });
  const [users, setUsers] = React.useState([]);
  const [availableFilter, setAvailableFilter] = React.useState({
    status: ["All"],
    type: ["All"]
  });
  const [selectedBusinessId, setSelectedBusinessId] = React.useState(null);
  const [alert, setAlert] = React.useState(null);
  const [refresh, setRefresh] = React.useState(0);

  const [showOpenCreate, setShowOpenCreate] = React.useState(false)
  const [showOpenUpdate, setShowOpenUpdate] = React.useState(false)
  const [showOpenDelete, setShowOpenDelete] = React.useState(false)
  const [showRevenueSharing, setShowRevenueSharing] = React.useState(false)
  const [revenueSharingId, setRevenueSharingId] = React.useState(false)

  const [showUpdateRevenueSharing, setShowUpdateRevenueSharing] = React.useState(false)
  const [percentSharing, setPercentSharing] = React.useState({
    management: 0,
    tenant: 0
  })

  const handlePercentSharing = (e) => {
    const { value, name } = e.target
    // setPercentSharing({
    //   ...percentSharing,
    //   [name]: value
    // })
    if (name === 'management') {
      if (parseInt(value) > 100 || parseInt(value) < 0) {
        setAlert("Invalid Number")
      } else {
        setAlert()
        const temp_tenant = 100 - parseInt(value)
        setPercentSharing({
          ...percentSharing,
          tenant: temp_tenant,
          management: parseInt(value)
        })
      }
    }
    if (name === 'tenant') {
      if (parseInt(value) > 100 || parseInt(value) < 0) {
        setAlert("Invalid Number")
      } else {
        setAlert()
        const temp_management = 100 - parseInt(value)
        setPercentSharing({
          ...percentSharing,
          tenant: parseInt(value),
          management: temp_management
        })
      }
    }
  }

  const handleOpenRevenueSharing = () => setShowRevenueSharing(true)
  const handleRefresh = () => setRefresh(refresh + 1);

  const initialValueTenantUtilityPayment = {
    business_id: 0,
    electricity_cost: 0,
    water_cost: 0,
    gas_cost: 0,
    electricity_subscription_cost: 0,
    periode: "",
    payment_due_date: ""
  };

  const initialValueRevenueSharing = {
    manager_percent_share: 0,
    business_percent_share: 0,
  };

  const TenantUtilityPaymentSchema = Yup.object().shape({
    electricity_cost: Yup.number()
      .required('Please input electricity cost')
      .min(1, 'Minimum 1 Character'),
    water_cost: Yup.number()
      .required('Please input water cost')
      .min(1, 'Minimum 1 Character'),
    gas_cost: Yup.number()
      .required('Please input gas cost')
      .min(1, 'Minimum 1 Character'),
    electricity_subscription_cost: Yup.number()
      .required('Please input electricity subscription cost')
      .min(1, 'Minimum 1 Character')
  });

  const formikRevenueSharing = useFormik({
    initialValues: initialValueRevenueSharing,
    onSubmit: async (values, {resetForm}) => {
      const API_URL = process.env.REACT_APP_API_URL;
      try {
        if(parseInt(percentSharing.management) + parseInt(percentSharing.tenant) !== 100) {
          toast.warning("Total Management Percent Share & Tenant Percent Share must be 100 percent", {
            position: "top-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return
        }

        const data = {
          business_id: selectedBusinessId,
          manager_percent_share: percentSharing.management,
          business_percent_share: percentSharing.tenant
        }

        enableLoading();
        await axios.post(`${API_URL}/api/v1/business-revenue-sharing/create-or-update`, data)
        toast.success('Success insert data', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        resetForm()
        handleRefresh()
        disableLoading()
        handleCloseUpdateRevenueSharing()
      } catch (err) {
        console.log("error", err)
        toast.error("Woahhh!! Something wen't wrong", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setAlert(err.response.data.message);
        disableLoading();
        handleCloseUpdate()
      }
    }
  });

  const formikTenantUtilityPaymentCreate = useFormik({
    initialValues: initialValueTenantUtilityPayment,
    validationSchema: TenantUtilityPaymentSchema,
    onSubmit: async (values, {resetForm}) => {
      const API_URL = process.env.REACT_APP_API_URL;
      try {
        enableLoading()
        const dataCreate = {
          business_id: selectedBusinessId,
          electricity_cost: values.electricity_cost,
          water_cost: values.water_cost,
          gas_cost: values.gas_cost,
          electricity_subscription_cost: values.electricity_subscription_cost,
          periode: values.periode,
          payment_due_date: values.payment_due_date,
          biaya_administrasi: 0,
          biaya_materai: 0,
          total_tagihan: parseInt(values.electricity_cost) +  parseInt(values.water_cost) + parseInt(values.gas_cost) + parseInt(values.electricity_subscription_cost),
        }
        const {data} = await axios.post(`${API_URL}/api/v1/business-tenant-utility-payment`, dataCreate)

        dataCreate.no_invoice = data.data.no_invoice
        console.log("dataCreate", dataCreate)
        await axios.post(`${API_URL}/api/v1/business-tenant-utility-payment/send-invoice`, dataCreate)

        toast.success('Success insert data', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        resetForm()
        handleRefresh()
        disableLoading();
        handleCloseCreate()
      } catch (err) {
        console.log("error", err)
        toast.error("Woahhh!! Something wen't wrong", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setAlert(err.response.data.message);
        disableLoading();
        handleCloseUpdate()
      }
    }
  });

  const validationPaymentCreate = (fieldname) => {
    if (formikTenantUtilityPaymentCreate.touched[fieldname] && formikTenantUtilityPaymentCreate.errors[fieldname]) {
      return "is-invalid";
    }

    if (formikTenantUtilityPaymentCreate.touched[fieldname] && !formikTenantUtilityPaymentCreate.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formikTenantUtilityPaymentUpdate = useFormik({
    initialValues: initialValueTenantUtilityPayment,
    validationSchema: TenantUtilityPaymentSchema,
    onSubmit: async (values) => {
      const API_URL = process.env.REACT_APP_API_URL;
      try {
        const data = {
          business_id: selectedBusinessId,
          electricity_cost: values.electricity_cost,
          water_cost: values.water_cost,
          gas_cost: values.gas_cost,
          electricity_subscription_cost: values.electricity_subscription_cost,
          manager_percent_share: values.manager_percent_share,
          business_percent_share: values.business_percent_share,
          periode: values.periode,
          payment_due_date: values.payment_due_date
        }
        enableLoading();
        await axios.put(`${API_URL}/api/v1/business-tenant-utility-payment/${tenantUtilityPaymentId}`, data)
        toast.success('Success update data', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        handleRefresh()
        disableLoading();
        handleCloseUpdate()
      } catch (err) {
        console.log("error", err)
        toast.error("Woahhh!! Something wen't wrong", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setAlert(err.response.data.message);
        disableLoading();
        handleCloseUpdate()
      }
    }
  });

  const validationPaymentUpdate = (fieldname) => {
    if (formikTenantUtilityPaymentUpdate.touched[fieldname] && formikTenantUtilityPaymentUpdate.errors[fieldname]) {
      return "is-invalid";
    }

    if (formikTenantUtilityPaymentUpdate.touched[fieldname] && !formikTenantUtilityPaymentUpdate.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const getUsers = async (search, filter) => {
    const API_URL = process.env.REACT_APP_API_URL;
    let filterUser = "?";
    if (search) filterUser += `name=${search}`;
    // if (filter.status !== "all") filterUser += `&status=${filter.status}`;
    if (filter.type !== "all") filterUser += `&type=${filter.type}`;
    filterUser = filterUser === "?" ? "" : filterUser;
    try {
      const { data } = await axios.get(
        `${API_URL}/api/v1/business${filterUser}`
      );
      // filter user by Subscription status
      let filtered;
      if (filter.status.toLowerCase() === "none") {
        filtered = data.data.filter((item) => item.Subscription === null);
      } else if (filter.status !== "all") {
        filtered = data.data.filter(
          (item) =>
            item.Subscription?.status.toLowerCase() ===
            filter.status.toLowerCase()
        );
      } else {
        filtered = data.data;
      }
      setUsers(filtered);
    } catch (err) {
      setUsers([]);
    }
  };
  React.useEffect(() => {
    getUsers(debouncedSearch, filter);
  }, [filter, debouncedSearch, refresh]);

  const closeUpdateStatusModal = () => setStateUpdateStatus(false);
  const showUpdateStatusModal = () => setStateUpdateStatus(true)

  const closeDeleteUtilityPayment = () => setStateDeleteUtilityPayment(false);
  const showDeleteUtilityPayment = () => setStateDeleteUtilityPayment(true)
  
  
  const enableLoading = () => setLoading(true);
  const disableLoading = () => setLoading(false);

  const handleSearch = (e) => setSearch(e.target.value);
  const handleFilter = (e) => {
    const { name, value } = e.target;
    const filterData = { ...filter };
    filterData[name] = value;
    setFilter(filterData);
  };

  const dataUser = users.map((item, index) => {
    if (!availableFilter.status.includes(item.Subscription?.status)) {
      availableFilter.status.push(item.Subscription?.status);
    }
    if (!availableFilter.type.includes(item.subscription_type)) {
      availableFilter.type.push(item.subscription_type);
    }
    if (!item.Subscription && !availableFilter.status.includes("None")) {
      availableFilter.status.push("None");
    }
    const start_date = dayjs(item.createdAt).format("DD/MM/YYYY");
    const end_date = item.Subscription
      ? dayjs(item.Subscription.expired_date).format("DD/MM/YYYY")
      : "-";
    return {
      no: index + 1,
      business_id: item.id,
      name: item.name,
      outlets: item.Outlets,
      revenue_sharing: item.Business_Revenue_Sharings ? item.Business_Revenue_Sharings : null,
      tenant_utility_payment: item.Business_Tenant_Utility_Payments ? item.Business_Tenant_Utility_Payments : null,
      location: item.Location
        ? item.Location.City.name
        : "Location data not found",
      start_date,
      end_date,
      status: item.Subscription ? item.Subscription.status : "None", //klau belum ada Subcription otomatis dianggap NONE subscriptionnya
      type: item.subscription_type,
      subscription_type: item.Subscription
        ? item.Subscription.Subscription_Type.name
        : "Trial"
    };
  });

  const handleGenerateInvoice = async (data_invoice, business_id) => {
    const API_URL = process.env.REACT_APP_API_URL;
    try {
      const dataGeneratePdf = {
        business_id,
        no_invoice: data_invoice.no_invoice || "-",
        periode: data_invoice.periode,
        batas_waktu_pelunasan: data_invoice.payment_due_date,
        biaya_air: data_invoice.water_cost, 
        biaya_gas: data_invoice.gas_cost,
        biaya_listrik: data_invoice.electricity_cost,
        biaya_abodemen_listrik: data_invoice.electricity_subscription_cost,
        biaya_administrasi: 0,
        biaya_materai: 0,
        total_tagihan: data_invoice.water_cost + data_invoice.gas_cost + data_invoice.electricity_cost + data_invoice.electricity_subscription_cost
      }

      const {data} = await axios.post(`${API_URL}/api/v1/modify-pdf/generate-invoice-tenant-utility-payment`, dataGeneratePdf, {
        responseType: "blob"
      });

      const fileName = `Tagihan Tenant Utility Payment - ${data_invoice.periode_filename}.pdf`

      const blob = new Blob([data], { type: 'application/pdf' })
      // saveAs(blob, fileName)
      fileDownload(data, fileName)
      setTimeout(() => {
        disableLoading();
      }, 3000);

    } catch (error) {
      console.log("error", error)
    }
  }

  const handleOpenCreate = () => setShowOpenCreate(true)
  const handleCloseCreate = () => setShowOpenCreate(false)

  const handleOpenUpdate = () => setShowOpenUpdate(true)
  const handleCloseUpdate = () => {
    formikTenantUtilityPaymentUpdate.resetForm()
    setShowOpenUpdate(false)
  }
  const handleOpenDelete = () => setShowOpenDelete(false)
  
  const handleOpenUpdateRevenueSharing = () => setShowUpdateRevenueSharing(true)
  const handleCloseUpdateRevenueSharing = () => {
    formikRevenueSharing.resetForm()
    setShowUpdateRevenueSharing(false)
    setAlert()
    setPercentSharing({
      management: 0,
      tenant: 0
    })
  }

  const handleEmitDatabase = async () => {
    const API_URL = process.env.REACT_APP_API_URL;
    try {
      await axios.get(`${API_URL}/api/v1/dev/emit-database`)
    } catch (error) {
      console.log(error)
    }
  }

  const getTenantUtilityPayment = async (id) => {
    const API_URL = process.env.REACT_APP_API_URL;
    try {
      const {data} = await axios.get(`${API_URL}/api/v1/business-tenant-utility-payment/${id}`)
      setRevenueSharingId(data.data.id)

      const date = new Date(data.data.periode)
      const date_payment_deadline = dayjs(data.data.payment_due_date).format("YYYY-MM-DD")
      const format_periode = dayjs(date).format('YYYY-MM')
      
      formikTenantUtilityPaymentUpdate.setValues({
        electricity_cost: data.data.electricity_cost,
        water_cost: data.data.water_cost,
        gas_cost: data.data.gas_cost,
        electricity_subscription_cost: data.data.electricity_subscription_cost,
        periode: format_periode,
        payment_due_date: date_payment_deadline
      });
    } catch (error) {
      console.log("error getRevenueSharing", error)
    }
  }

  const getRevenueSharing = async (business_id) => {
    const API_URL = process.env.REACT_APP_API_URL;
    try {
      const {data} = await axios.get(`${API_URL}/api/v1/business-revenue-sharing/business-id/${business_id}`)
      setRevenueSharingId(data.data.id)
      // Menggunakan Formik
      formikRevenueSharing.setValues({
        manager_percent_share: data.data.manager_percent_share,
        business_percent_share: data.data.business_percent_share
      });

      // Menggunakan State Old
      setDataUpdateRevenueSharing({
        manager_percent_share: data.data.manager_percent_share,
        business_percent_share: data.data.business_percent_share
      })

      // Menggunakan State New
      setPercentSharing({
        management: data.data.manager_percent_share,
        tenant: data.data.business_percent_share
      })
    } catch (error) {
      console.log("error getRevenueSharing", error)
    }
  }

  const columns = [
    {
      name: "No.",
      selector: "no",
      sortable: true,
      width: "50px"
    },
    {
      name: "Name",
      selector: "name",
      sortable: true
    },
    {
      name: "Location",
      selector: "location",
      sortable: true
    },
    {
      name: "Start Date",
      selector: "start_date",
      sortable: true
    },
    {
      name: "End Date",
      selector: "end_date",
      sortable: true
    },
    {
      name: "Subscription Status",
      selector: "status",
      sortable: true
    },
    {
      name: "Type",
      selector: "subscription_type",
      sortable: true
    },
    {
      name: "Action",
      selector: "business_id",
      key: "business_id",
      cell: (val) => {
        return (
          <Dropdown>
            <Dropdown.Toggle variant="light">
              <MoreHoriz />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                as="button"
                onClick={() => {
                  setSelectedBusinessId(val.business_id);
                  handleOpenCreate();
                }}
              >
                Create Tenant Utility Payment
              </Dropdown.Item>
              {/* <Dropdown.Item
                as="button"
                onClick={() => {
                  getTenantUtilityPayment(val.business_id)
                  setSelectedBusinessId(val.business_id);
                  handleOpenUpdate();
                }}
              >
                Update Tenant Utility Payment
              </Dropdown.Item> */}
              {/* <Dropdown.Item
                as="button"
                onClick={() => {
                  setSelectedBusinessId(val.business_id);
                  // showUpdateStatus()
                  handleOpenDelete();
                }}
              >
                Delete Tenant Utility Payment
              </Dropdown.Item> */}
              <Dropdown.Item
                as="button"
                onClick={() => {
                  getRevenueSharing(val.business_id)
                  setSelectedBusinessId(val.business_id);
                  handleOpenUpdateRevenueSharing();
                }}
              >
                Update Revenue Sharing
              </Dropdown.Item>
              <Link
                to={{
                  pathname: `/revenue-sharing/detail/${val.business_id}`
                }}
              >
                <Dropdown.Item
                  as="button"
                  onClick={() => {
                    getRevenueSharing(val.business_id)
                    setSelectedBusinessId(val.business_id);
                  }}
                >
                  Detail Utility Payment
                </Dropdown.Item>
              </Link>
            </Dropdown.Menu>
          </Dropdown>
        );
      }
    }
  ];

  const handleChangeStatus = async () => {
    const API_URL = process.env.REACT_APP_API_URL;
    try {
      await axios.patch(`${API_URL}/api/v1/business-tenant-utility-payment/${dataStatus.id}`, {status: dataStatus.status}) 
      handleRefresh()
      closeUpdateStatusModal()
    } catch (error) {
      console.log("error", error)
    }
  }

  const handleSetDataStatus = (id, status) => setDataStatus({id, status})

  const ExpandableComponent = ({ data }) => {
    let keys = [];
    data.tenant_utility_payment.forEach((val, idx) => {
      const periode = new Date(val.periode)
      const payment_due_date = new Date(val.payment_due_date)

      keys.push({
        key: idx,
        value: {
          id: val.id,
          periode: val.periode ? `${dayjs(periode).format('DD/MM/YYYY')}` : "-",
          periode_filename: val.periode ? `${dayjs(periode).format('MMM YY')}` : "-",
          payment_due_date: val.payment_due_date ? `${dayjs(payment_due_date).format('DD/MM/YYYY')}` : "-",
          electricity_cost: val.electricity_cost,
          water_cost: val.water_cost,
          gas_cost: val.gas_cost,
          electricity_subscription_cost: val.electricity_subscription_cost,
          manager_percent_share: val.manager_percent_share,
          business_percent_share: val.business_percent_share,
          status: val.status,
          no_invoice: val.no_invoice
        }
      });
    });
    return (
      <>
        <ListGroup style={{ padding: "1rem", marginLeft: "1rem" }}>
          <ListGroup.Item key="head">
            <Row className="lineBottom">
              <Col style={{ fontWeight: "700", width: "30%" }}>
                Periode
              </Col>
              <Col style={{ fontWeight: "700", width: "30%" }}>
                Payment Due Date
              </Col>
              <Col style={{ fontWeight: "700" }}>
                Electrical
              </Col>
              <Col style={{ fontWeight: "700" }}>
                Water
              </Col>
              <Col style={{ fontWeight: "700" }}>
                Gas
              </Col>
              <Col style={{ fontWeight: "700" }}>
                Abodemen
              </Col>
              <Col style={{ fontWeight: "700" }}>
                Status
              </Col>
              <Col style={{ fontWeight: "700" }}>
                Actions
              </Col>
            </Row>
          </ListGroup.Item>
          {keys.map((val, index) => {
            return (
              <ListGroup.Item key={index}>
                <Row>
                  <Col>{val.value.periode}</Col>
                  <Col>{val.value.payment_due_date}</Col>
                  <Col><NumberFormat value={val.value.electricity_cost} displayType={'text'} thousandSeparator={true} prefix="Rp." /></Col>
                  <Col><NumberFormat value={val.value.water_cost} displayType={'text'} thousandSeparator={true} prefix="Rp." /></Col>
                  <Col><NumberFormat value={val.value.gas_cost} displayType={'text'} thousandSeparator={true} prefix="Rp." /></Col>
                  <Col><NumberFormat value={val.value.electricity_subscription_cost} displayType={'text'} thousandSeparator={true} prefix="Rp." /></Col>
                  <Col>
                    <div className="d-flex align-items-center">
                      <FormControl component="fieldset">
                        <FormGroup aria-label="position" row>
                          <FormControlLabel
                            value={val.value.status}
                            control={
                              <Switch
                                color="primary"
                                checked={val.value.status === "paid" ? true : false}
                                onChange={(e) => {
                                    showUpdateStatusModal()
                                    if(e.target.value === 'paid') {
                                    handleSetDataStatus(val.value.id, 'unpaid')
                                  } else {
                                    handleSetDataStatus(val.value.id, 'paid')
                                  }
                                }}
                                name=""
                              />
                            }
                          />
                        </FormGroup>
                      </FormControl>
                      <div style={{fontSize: "1rem"}} className="text-muted mr-3">{val.value.status}</div>
                    </div>
                  </Col>
                  <Col>
                    <Dropdown>
                      <Dropdown.Toggle variant="light">
                        <MoreHoriz />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          as="button"
                          onClick={() => {
                            getTenantUtilityPayment(val.value.id)
                            setTenantUtilityPaymentId(val.value.id)
                            handleOpenUpdate();
                          }}
                        >
                          Update Utility Payment
                        </Dropdown.Item>
                        <Dropdown.Item
                          as="button"
                          onClick={() => {
                            handleGenerateInvoice(val.value, data.business_id)
                          }}
                        >
                          Generate Invoice (PDF)
                        </Dropdown.Item>
                        <Dropdown.Item
                          as="button"
                          onClick={() => {
                            handleEmitDatabase()
                          }}
                        >
                          Handle Emmit Database
                        </Dropdown.Item>
                        <Dropdown.Item
                          as="button"
                          onClick={() => {
                            showDeleteUtilityPayment()
                            setTenantUtilityPaymentId(val.value.id)
                          }}
                        >
                          Delete Utility Payment
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </>
    );
  };

  const handleSaveUpdateTenantUtilityPayment = () => {
    try {
      formikTenantUtilityPaymentUpdate.submitForm()
    } catch (error) {
      console.log("error save revenue sharing", error)
    }
  }

  const handleSaveCreateTenantUtilityPayment = () => {
    try {
      formikTenantUtilityPaymentCreate.submitForm()
    } catch (error) {
      console.log("error save revenue sharing", error)
    }
  }

  const handleSaveRevenueSharing = () => {
    try {
      formikRevenueSharing.submitForm()
    } catch (error) {
      console.log("error save revenue sharing", error)
    }
  }

  const handleDeleteUtilityPayment = async () => {
    const API_URL = process.env.REACT_APP_API_URL;
    try {
      await axios.delete(`${API_URL}/api/v1/business-tenant-utility-payment/${tenantUtilityPaymentId}`)
      handleRefresh()
      closeDeleteUtilityPayment()
      toast.success('Success delete data', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log("error delete utility payment", error)
      toast.error("Woahhh!! Something wen't wrong", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }
  
  return (
    <div>
      <ConfirmModal
        title="Update Status Utility Payment"
        body="Are you sure this utility payment status update?"
        buttonColor="primary"
        state={stateUpdateStatus}
        closeModal={closeUpdateStatusModal}
        handleClick={() => handleChangeStatus()}
        loading={loading}
      />
      <ConfirmModal
        title="Delete Status Utility Payment"
        body="Are you sure delete this utility payment?"
        buttonColor="danger"
        state={stateDeleteUtilityPayment}
        closeModal={closeDeleteUtilityPayment}
        handleClick={() => handleDeleteUtilityPayment()}
        loading={loading}
      />
      <CreateModal
        state={showOpenCreate}
        handleCloseCreate={handleCloseCreate}
        handleSaveCreateTenantUtilityPayment={handleSaveCreateTenantUtilityPayment}
        formikTenantUtilityPaymentCreate={formikTenantUtilityPaymentCreate}
        loading={loading}
        validationPaymentCreate={validationPaymentCreate}
      />
      <UpdateModal
        state={showOpenUpdate}
        handleCloseUpdate={handleCloseUpdate}
        handleSaveUpdateTenantUtilityPayment={handleSaveUpdateTenantUtilityPayment}
        formikTenantUtilityPaymentUpdate={formikTenantUtilityPaymentUpdate}
        validationPaymentUpdate={validationPaymentUpdate}
      />
      <UpdateRevenueModal
        percentSharing={percentSharing}
        handlePercentSharing={handlePercentSharing}
        state={showUpdateRevenueSharing}
        handleCloseRevenueSharing={handleCloseUpdateRevenueSharing}
        handleSaveRevenueSharing={handleSaveRevenueSharing}
        formikRevenueSharing={formikRevenueSharing}
        dataUpdateRevenueSharing={dataUpdateRevenueSharing}
        alert={alert}
      />
      <Row>
        <Col md={12}>
          <Paper elevation={2} style={{ padding: "1rem", height: "100%" }}>
            <div
              className="headerPage lineBottom d-flex"
              style={{ marginBottom: "1rem" }}
            >
              <div className="headerStart ">
                <h3 className="p-3" style={{ width: "fit-content" }}>
                    Tenant Utility Payment
                  <small className="pl-3">BeetPOS Customer</small>
                </h3>
              </div>
            </div>

            <div className="filterSection lineBottom mt-3">
              <Row>
                <Col>
                  <InputGroup className="pb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text style={{ background: "transparent" }}>
                        <Search />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      placeholder="Search..."
                      value={search}
                      onChange={handleSearch}
                    />
                  </InputGroup>
                </Col>

                <Col>
                  <Form.Group as={Row}>
                    <Form.Label
                      style={{ alignSelf: "center", marginBottom: "0" }}
                    >
                      Status:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        as="select"
                        name="status"
                        value={filter.status}
                        onChange={handleFilter}
                      >
                        {availableFilter.status.map((value, idx) => {
                          if (value)
                            return (
                              <option value={value.toLowerCase()} key={value}>
                                {value.charAt(0).toUpperCase() + value.slice(1)}
                              </option>
                            );
                        })}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label
                      style={{ alignSelf: "center", marginBottom: "0" }}
                    >
                      Type:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        as="select"
                        name="type"
                        value={filter.type}
                        onChange={handleFilter}
                      >
                        {availableFilter.type.map((value, idx) => {
                          if (value)
                            return (
                              <option value={value.toLowerCase()} key={value}>
                                {value.charAt(0).toUpperCase() + value.slice(1)}
                              </option>
                            );
                        })}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <DataTable
              noHeader
              pagination
              columns={columns}
              data={dataUser}
              expandableRows
              expandableRowsComponent={<ExpandableComponent />}
              style={{ minHeight: "fit-content" }}
            />
          </Paper>
        </Col>
      </Row>
    </div>
  )
}
