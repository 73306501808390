import React from 'react';
import axios from "axios";

import { Tabs, Tab } from "react-bootstrap";

import PaymentGateway from "./PaymentGatewayTab/PaymentGateway";

const PaymentPage = () => {
  const [tabs, setTabs] = React.useState("payment-gateway");
  const [refresh, setRefresh] = React.useState(0);
  
  return (
    <>
      <hr/>
      <Tabs activeKey={tabs} onSelect={(v) => setTabs(v)}>

        <Tab eventKey="payment-gateway" title="Tenant List">
          <PaymentGateway/>
        </Tab>

      </Tabs>
    </>
  );
}

export default PaymentPage;
