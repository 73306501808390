import React from "react";
import { Paper } from "@material-ui/core";
import { Modal, Button } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { InputGroup, FormControl } from "react-bootstrap";
import { Row } from "react-bootstrap";

const EditPlanModal = ({
  state,
  data,
  closeEditPlanModal,
  formikSubscriptionTypeEdit,
  handleSavePlan
}) => {
  const handleSave = () => {
    closeEditPlanModal();
    const saveData = {
      id: formikSubscriptionTypeEdit.getFieldProps("id").value,
      name: formikSubscriptionTypeEdit.getFieldProps("name").value,
      limit: formikSubscriptionTypeEdit.getFieldProps("limit").value,
      price: formikSubscriptionTypeEdit.getFieldProps("price").value,
      trial_limit: formikSubscriptionTypeEdit.getFieldProps("trial_limit")
        .value,
      expired_tolerance: formikSubscriptionTypeEdit.getFieldProps(
        "expired_tolerance"
      ).value,
      late_payment_limit: formikSubscriptionTypeEdit.getFieldProps(
        "late_payment_limit"
      ).value
    };
    handleSavePlan(saveData);
  };

  return (
    <Modal show={state} onHide={closeEditPlanModal}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Subscription Plan</Modal.Title>
      </Modal.Header>
      <div className="p-4">
        <Form.Group>
          <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
            Subscription Name:
          </Form.Label>
          <InputGroup style={{ minWidth: 105 }}>
            <FormControl
              aria-label="Name"
              name="name"
              // value={nameInput}
              {...formikSubscriptionTypeEdit.getFieldProps("name")}
            />
          </InputGroup>
        </Form.Group>
        <Form.Group style={{ width: "35%" }}>
          <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
            Days Limit:
          </Form.Label>
          <InputGroup style={{ minWidth: 105 }}>
            <FormControl
              aria-label="Days Limit"
              name="limit"
              {...formikSubscriptionTypeEdit.getFieldProps("limit")}
            />
            <InputGroup.Append>
              <InputGroup.Text id="daysappend">Days</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>
        <Form.Group style={{ width: "35%" }}>
          <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
            Price:
          </Form.Label>
          <InputGroup style={{ minWidth: 150 }}>
            <InputGroup.Prepend>
              <InputGroup.Text id="rpappend">Rp. </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              aria-label="Price"
              name="price"
              {...formikSubscriptionTypeEdit.getFieldProps("price")}
            />
          </InputGroup>
        </Form.Group>
        <div style={{ width: "fit-content", right: 0, marginLeft: "auto" }}>
          <Button
            variant="outline-secondary"
            onClick={closeEditPlanModal}
            style={{ width: 100 }}
            className="ml-3 mr-3"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            style={{ width: 100 }}
            onClick={handleSave}
            className="ml-3 mr-3"
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default EditPlanModal;
