import React from 'react'
import {
  Row,
  Col,
  Modal,
  Form,
  Button
} from 'react-bootstrap'
export default function ShopeeModal({
  state,
  alert,
  close,
  formikShopee,
  setAlert,
  handleSaveShopee
}) {
  return (
    <div>
      <Modal
        show={state}
        onHide={() => {
          close();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Beetstore Modal: </Modal.Title>
        </Modal.Header>
        <div className="p-4 text-danger">{alert}</div>
        <div className="p-4">
          <Row>
            <Col>
              <Form.Group style={{ width: "100%" }}>
                <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                  Shopee Store Id
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="shopee_store_id"
                  {...formikShopee.getFieldProps("shopee_store_id")}
                />
              </Form.Group>
              <Form.Group style={{ width: "100%" }}>
                <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                  Shopee Code
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="shopee_code"
                  {...formikShopee.getFieldProps("shopee_code")}
                />
              </Form.Group>
            
              <Form.Group style={{ width: "100%" }}>
                <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                  Shopee Auth
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="shopee_auth"
                  {...formikShopee.getFieldProps("shopee_auth")}
                />
              </Form.Group>
              <Form.Group style={{ width: "100%" }}>
                <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                  Shopee Access Token
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="shopee_access_token"
                  {...formikShopee.getFieldProps("shopee_access_token")}
                />
              </Form.Group>
              <Form.Group style={{ width: "100%" }}>
                <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                  Shopee Refresh Token
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="shopee_refresh_token"
                  {...formikShopee.getFieldProps("shopee_refresh_token")}
                />
              </Form.Group>
              <Form.Group style={{ width: "100%" }}>
                <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                  Shopee Expiration
                </Form.Label>
                <Form.Control
                  type="date"
                  placeholder="shopee_expiration"
                  {...formikShopee.getFieldProps("shopee_expiration")}
                />
              </Form.Group>
              <Form.Group style={{ width: "100%" }}>
                <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                  Shopee User
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="shopee_user"
                  {...formikShopee.getFieldProps("shopee_user")}
                />
              </Form.Group>
              <Form.Group style={{ width: "100%" }}>
                <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                  Shopee Password
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder="shopee_password"
                  {...formikShopee.getFieldProps("shopee_password")}
                />
              </Form.Group>
              <Form.Group style={{ width: "100%" }}>
                <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                  Shopee Url 
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="shopee_url"
                  {...formikShopee.getFieldProps("shopee_url")}
                />
              </Form.Group>

              <Form.Group style={{ width: "35%" }}>
                <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                  Status Integrate Shopee
                </Form.Label>
                <Form.Check
                  type="checkbox"
                  label="Active"
                  checked={formikShopee.getFieldProps("status_integrate_shopee").value}
                  {...formikShopee.getFieldProps("status_integrate_shopee")}
                />
              </Form.Group>
              <div style={{ width: "fit-content", right: 0, marginLeft: "auto" }}>
                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    close();
                    setAlert(null);
                  }}
                  style={{ width: 100 }}
                  className="ml-3 mr-3"
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  style={{ width: 100 }}
                  onClick={handleSaveShopee}
                  className="ml-3 mr-3"
                >
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  )
}
