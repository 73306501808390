import React, {useEffect, useState} from 'react'
import { 
  Switch,
  FormGroup,
  FormControl,
  FormControlLabel,
  Paper 
} from "@material-ui/core";
import CustomDateRange from "../../components/CustomDateRange";

import axios from 'axios'
import dayjs from "dayjs";
import NumberFormat from 'react-number-format'
import { CalendarToday, TodayOutlined, Schedule } from "@material-ui/icons";

import {
  Row,
  Col,
  Form, 
  Button,
  Table,
  InputGroup
} from 'react-bootstrap'

import { 
  Link,
  useParams 
} from 'react-router-dom'; 

export default function Detail() {
  const { id } = useParams();
  const API_URL = process.env.REACT_APP_API_URL;

  const [businessData, setBusinessData] = useState({})
  const [allPaymentMethods, setAllPaymentMethods] = React.useState([]);
  const [allOutlets, setAllOutlets] = React.useState([]);
  const [endRange, setEndRange] = React.useState(new Date());
  const [stateCustom, setStateCustom] = React.useState(false);
  const [startRange, setStartRange] = React.useState(new Date());
  const [endDateFilename, setEndDateFilename] = React.useState("");
  const [selectedShowData, setSelectedShowData] = React.useState({
    value: "",
    label: ""
  })
  const [showDatePicker, setShowDatePicker] = React.useState(false)
  const [showByDate, setShowByDate] = React.useState(false)

  const [selectedOutlet, setSelectedOutlet] = React.useState({
    id: "",
    name: "All Outlet"
  })
  const [startDate, setStartDate] = React.useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(dayjs().format("YYYY-MM-DD"));
  const [dataRevenue, setDataRevenue] = React.useState({})
  const [refresh, setRefresh] = React.useState(0)
  const [totalUtilityPayment, setTotalUtilityPayment] = React.useState(0)

  const getBusinessData = async () => {
    try {
      const {data} = await axios.get(`${API_URL}/api/v1/business/${id}`)
      const sub_total = []

      if(data.data.Business_Tenant_Utility_Payments) {
        data.data.Business_Tenant_Utility_Payments.map(value => {
          const result_sub_total = value.electricity_cost + value.water_cost + value.gas_cost + value.electricity_subscription_cost
          value.sub_total = result_sub_total
          sub_total.push(result_sub_total)
        })
      }
      const total = sub_total.reduce((init, curr) => {
        return init + curr 
      }, 0)
      setTotalUtilityPayment(total)
      setBusinessData(data.data)
    } catch (error) {
      console.log("error", error)
    }
  }

  const getOutlets = async () => {
    const API_URL = process.env.REACT_APP_API_URL;

    try {
      const { data } = await axios.get(`${API_URL}/api/v1/outlet/by-business/${id}`);
      setAllOutlets(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpenCustom = () => setStateCustom(true);

  const handleSelectOutlet = (e) => {
    const { value } = e.target;
    let outlet;
    if (value) {
      outlet = allOutlets.find((item) => item.id === parseInt(value));
    }

    setSelectedOutlet({
      id: value,
      name: outlet ? outlet.Location?.name : "All Outlet"
    })
  };

  const getPaymentMethod = async (outet_id, start_range, end_range, show_by_date) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const outlet_id = outet_id ? `?outlet_id=${outet_id}&` : "?";

    if (start_range === end_range) {
      end_range = dayjs(end_range)
        .add(1, "day")
        .format("YYYY-MM-DD");
    }

    if (new Date(start_range) > new Date(end_range)) {
      start_range = dayjs(start_range)
        .subtract(1, "day")
        .format("YYYY-MM-DD");
      end_range = dayjs(end_range)
        .add(1, "day")
        .format("YYYY-MM-DD");
    }

    console.log("id", id)
    console.log("start_range", start_range)
    console.log("end_range", end_range)

    try {
      const { data } = await axios.get(
        `${API_URL}/api/v1/transaction/payment-method/by-business/${id}${outlet_id}date_start=${start_range}&date_end=${end_range}&mdr=true&show_by_date=${show_by_date}`
      );
      
      setAllPaymentMethods(data.data);
      console.log("getPaymentMethod", data.data)
    } catch (err) {
      if (err.response.status === 404) {
        setAllPaymentMethods([]);
      }
      console.log(err);
    }
  };

  const paymentMethodData = () => {
    const data = [];

    allPaymentMethods.forEach((item) => {
      const allPayments = item.Payments.filter(
        (item) => item.status === "done"
      );

      const totalCollected = allPayments.reduce(
        (init, curr) => (init += curr.payment_total),
        0
      );

      data.push({
        method: item.name,
        transaction: allPayments.length,
        total: totalCollected
      });
    });

    data.sort((a, b) => b.transaction - a.transaction);

    const totalTransactions = data.reduce(
      (init, curr) => (init += curr.transaction),
      0
    );
    const totalAmount = data.reduce((init, curr) => (init += curr.total), 0);

    // Total Collected dan total Transaction
    // data.push({
    //   method: "",
    //   transaction: totalTransactions,
    //   total: totalAmount
    // });

    // Calculate Revenue Sharing
    const percentage_manager = dataRevenue.manager_percent_share / 100
    const temp_kali_manager = totalAmount * percentage_manager

    const percentage_business = dataRevenue.business_percent_share / 100
    const temp_kali_business = totalAmount * percentage_business

    const result_revenue_manager =  Math.round(temp_kali_manager)
    const result_revenue_business =  Math.round(temp_kali_business)
    
    // End Calculate Revenue Sharing

    return {
      data,
      totalCollected: {
          transaction: totalTransactions,
          total: totalAmount
      },
      resultRevenueBusiness: {
        result_revenue_manager,
        result_revenue_business
      }
    };
  };

  useEffect(() => {
    getBusinessData()
    getOutlets()
  }, [])

  const calculateRevenue = async () => {
    const API_URL = process.env.REACT_APP_API_URL;
   try {
     const {data} = await axios.get(`${API_URL}/api/v1/business-revenue-sharing/business-id/${id}`)
     console.log("calculateRevenue", data.data)
     setDataRevenue(data.data)
   } catch (err) {
    console.log(err);
   }
 }

  const displayDate = () => {
    const start = dayjs(startDate).format("DD-MM-YYYY");
    const end = dayjs(endRange).format("DD-MM-YYYY");

    if (start === end) {
      return start;
    } else {
      return `${start} - ${end}`;
    }
  };

  const handleStartRange = ({ selection }) => {
    const { startDate: start, endDate: end } = selection;
    setStartRange(start);
    setEndRange(end);
  };

  const handleSaveCustom = () => {
    setStartDate(dayjs(startRange).format("YYYY-MM-DD"))
    setEndDate(dayjs(endRange).add(1, "days").format("YYYY-MM-DD"))

    setStartDate(dayjs(startRange).format("YYYY-MM-DD"));
    setEndDate(
      dayjs(endRange)
        .add(1, "days")
        .format("YYYY-MM-DD")
    );
    setEndDateFilename(
      dayjs(endRange)
      .format("YYYY-MM-DD")
    )
    setStateCustom(false);
  };
  const handleCloseCustom = () => {
    setStartRange(new Date(startDate));
    setEndRange(new Date(endDate));
    setStateCustom(false);
  };
  useEffect(() => {
    getPaymentMethod(selectedOutlet.id, startDate, endDate, showByDate);
    calculateRevenue()
  }, [selectedOutlet, startDate, endDate, refresh, showByDate]);

  const selectShowDate = [
    {
      value: 1,
      label: "Show All Data"
    },
    {
      value: 2,
      label: "Show by Date"
    }
  ]

  const handleSelectShowData = (e) => {
    const {value} = e.target
    setSelectedShowData({
      value,
      label: "label"
    })
    let temp_boolean
    if( value == "1")  temp_boolean = false
    if( value == "2")  temp_boolean = true
    setShowDatePicker(temp_boolean)
    setShowByDate(temp_boolean)
  }

  return (
    <>
      <CustomDateRange
        show={stateCustom}
        handleClose={handleCloseCustom}
        handleSave={handleSaveCustom}
        startRange={startRange}
        endRange={endRange}
        handleStartRange={handleStartRange}
      />
      <Row>
        <Col md={12}>
          <Paper elevation={2} style={{ padding: "1rem", height: "100%" }}>
            <div
              className="headerPage lineBottom d-flex"
              style={{ marginBottom: "1rem" }}
            >
              <div className="headerStart ">
                <h3 className="p-3" style={{ width: "fit-content" }}>
                    Detail Tenant Utility Payment
                  {/* <small className="pl-3">BeetPOS Customer</small> */}
                </h3>
              </div>
              <div className="headerEnd ">
                <Link to={{
                    pathname: `/revenue-sharing`
                  }}
                >
                  <div className="btn btn-primary">
                    Back
                  </div>
                </Link>
              </div>
            </div>
            <div>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Business Name:</Form.Label>
                    <Form.Control
                      type="text"
                      value={businessData.name}
                      disabled
                    />
                  </Form.Group>
                  
                  <Form.Group>
                    <Form.Label>Phone Number:</Form.Label>
                    <Form.Control
                      type="text"
                      value={businessData.phone_number}
                      disabled
                    />
                  </Form.Group>
                  
                  <Form.Group>
                    <Form.Label>Address:</Form.Label>
                    <Form.Control
                      type="text"
                      value={businessData.address}
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Start Date:</Form.Label>
                    <Form.Control
                      type="text"
                      value={dayjs(businessData.createdAt).format("DD/MM/YYYY")}
                      disabled
                    />
                  </Form.Group>
                  
                  <Form.Group>
                    <Form.Label>End Date:</Form.Label>
                    <Form.Control
                      type="text"
                      value={businessData.Subscription ? dayjs(businessData.Subscription.expired_date).format("DD/MM/YYYY") : ""}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                {businessData.Business_Tenant_Utility_Payments ? (
                  <Table id="table-payment" striped>
                    <thead>
                      <tr>
                        <th className="text-center">Periode</th>
                        <th className="text-center">Electricity Cost</th>
                        <th className="text-center">Water Cost</th>
                        <th className="text-center">Gas Cost</th>
                        <th className="text-center">Electricity Subscription Cost</th>
                        <th className="text-center">Sub Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {businessData.Business_Tenant_Utility_Payments.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td className="text-center">{item.periode ? dayjs(item.periode).format('DD/MM/YYYY') : "-"}</td>
                            <td className="text-center"><NumberFormat value={item.electricity_cost} displayType={'text'} thousandSeparator={true} prefix="Rp." /></td>
                            <td className="text-center"><NumberFormat value={item.water_cost} displayType={'text'} thousandSeparator={true} prefix="Rp." /></td>
                            <td className="text-center"><NumberFormat value={item.gas_cost} displayType={'text'} thousandSeparator={true} prefix="Rp." /></td>
                            <td className="text-center"><NumberFormat value={item.electricity_subscription_cost} displayType={'text'} thousandSeparator={true} prefix="Rp." /></td>
                            <td className="text-center"><NumberFormat value={item.sub_total} displayType={'text'} thousandSeparator={true} prefix="Rp." /></td>
                          </tr>
                        )
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td className="text-center">Total</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="text-center"><NumberFormat value={totalUtilityPayment} displayType={'text'} thousandSeparator={true} prefix={"Rp."} /></td>
                      </tr>
                    </tfoot>
                  </Table>
                ) : null }
                </Col>
              </Row>
              <hr />
              <div className="filterSection lineBottom">
                <Row>
                  <Col>
                    <Form.Group as={Row}>
                      <Form.Label
                        style={{ alignSelf: "center", marginBottom: "0" }}
                      >
                        Outlet:
                      </Form.Label>
                      <Col>
                        <Form.Control
                          as="select"
                          name="outlet_id"
                          value={selectedOutlet.id}
                          onChange={handleSelectOutlet}
                        >
                          <option value="">All</option>
                          {allOutlets.map((item) => {
                            return (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group as={Row}>
                      <Col>
                        <Form.Control
                          as="select"
                          name="show_data"
                          value={selectedShowData.value}
                          onChange={handleSelectShowData}
                        >
                          {selectShowDate.map((item) => {
                            return (
                              <option key={item.value} value={item.value}>
                                {item.label}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col>
                  {showDatePicker ? (
                    <Form.Group as={Row}>
                      <Form.Label
                        style={{ alignSelf: "center", marginBottom: "0" }}
                      >
                        Date:
                      </Form.Label>
                      <Col>
                        <InputGroup>
                          <Form.Control
                            type="text"
                            value={displayDate()}
                            onClick={handleOpenCustom}
                            style={{
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0
                            }}
                            readOnly
                          />

                          <InputGroup.Append>
                            <InputGroup.Text>
                              <CalendarToday />
                            </InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </Col>
                    </Form.Group>
                  ) : null}
                  </Col>
                </Row>
              </div>
              <div><i style={{fontSize: "1rem"}}>*including mdr</i></div>
              <hr />
              <Row>
                <Col>
                  <Table id="table-payment" striped>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Payment Method</th>
                        <th>Transaction Total</th>
                        <th>Total Collected</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paymentMethodData().data.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td></td>
                            <td>{item.method}</td>
                            <td>{item.transaction}</td>
                            <td><NumberFormat value={item.total} displayType={'text'} thousandSeparator={true} prefix={"Rp."} /></td>
                          </tr>
                      )})}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>Total Collected</td>
                        <td>{paymentMethodData().totalCollected.transaction}</td>
                        <td><NumberFormat value={paymentMethodData().totalCollected.total} displayType={'text'} thousandSeparator={true} prefix={"Rp."} /></td>
                      </tr>
                      <tr>
                        <td></td>
                        <td className="text-danger">Profit Sharing ({dataRevenue.manager_percent_share}%)</td>
                        <td></td>
                        <td className="text-danger">- <NumberFormat value={paymentMethodData().resultRevenueBusiness.result_revenue_manager} displayType={'text'} thousandSeparator={true} prefix={"Rp."} /></td>
                      </tr>
                      <tr>
                        <td></td>
                        <td className="text-success">Net ({dataRevenue.business_percent_share}%)</td>
                        <td></td>
                        <td className="text-success"><NumberFormat value={paymentMethodData().resultRevenueBusiness.result_revenue_business} displayType={'text'} thousandSeparator={true} prefix={"Rp."} /></td>
                      </tr>
                    </tfoot>
                  </Table>
                </Col>
              </Row>
            </div>
          </Paper>
        </Col>
      </Row>
    </>
  )
}
